import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VacationRequestLocal, VacationRequestOverview } from '@shared/factories';

@Component({
  selector:      'vacations-overview-item',
  templateUrl: './vacations-overview-item.component.html',
  host: { class: 'height-min-fit-content bg-white overflow b-radius', '[class.shadow-header]': '!extended' }
})
export class VacationsOverviewItemComponent {
  @Input() vacation: VacationRequestOverview | VacationRequestLocal;
  @Input() extended: boolean;
  @Output() close = new EventEmitter<void>;
  constructor() {}

  get localVacation():    VacationRequestLocal    { return this.vacation as VacationRequestLocal;    }
  get overviewVacation(): VacationRequestOverview { return this.vacation as VacationRequestOverview; }

  getStateColor(): string {
    if ( this.overviewVacation.internalReview === 'approved')      return 'green';
    if ( this.overviewVacation.internalReview === 'rejected' ||
         this.overviewVacation.archivedBySystem)                   return 'red';
    if (!this.overviewVacation.internalReview && this.vacation.id) return 'orange';
    return 'blue';
  }

  getStateIcon(): string {
    if ( this.overviewVacation.internalReview === 'approved')      return 'circle-tick';
    if ( this.overviewVacation.internalReview === 'rejected' ||
         this.overviewVacation.archivedBySystem)                   return 'circle-cross';
    if (!this.overviewVacation.internalReview && this.vacation.id) return 'circle-more';
    return 'info';
  }

}
