<li *ngFor="let day of week; let i = index"
     class="du-flex-column du-flex-align du-flex-justify du-flex-1 gap-xxs pl-xs pr-xs pt-xxs pb-xxs hover-active z-1 animate-color"
    (click)="daySelectCallback.emit(i)">
  <ng-container *ngIf="useCase !== 't'">
    <span *ngIf="!isPlaceholder(day)" class="animate-color-bg du-flex-center circle-m font-m" [ngClass]="getIconStyles(day, i)"></span>
    <span *ngIf=" isPlaceholder(day)" class="animate-color height-m font-s"                   [ngClass]="'color-' + getTextColor(day, i)">{{ day | date: 'EEE' }}</span>
    <span                             class="animate-color roboto font-400"                   [ngClass]="'color-' + getTextColor(day, i)">{{ day | date: 'dd.MM' }}</span>
  </ng-container>

  <ng-container *ngIf="useCase === 't'">
    <span class="animate-color height-m font-s"                   [ngClass]="'color-' + getTextColor(day, i)">{{ day | date: 'EEE' }}</span>
    <span class="animate-color-bg du-flex-center circle-m font-m" [ngClass]="getIconStyles(day, i)"></span>
  </ng-container>
</li>

<div id="active-day" #activeDay class="du-flex-center b-radius absolute top--xxs bottom--xxs translate-left animate-left animate-bg"
    [style.left.px]="nodeWidth*activeEntryIndex + nodeWidth/2"
    [ngStyle]="{ 'width.px': activeDay.offsetHeight }"
    [ngClass]="'bg-' + getActiveStateColor()">
</div>
