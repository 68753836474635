import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone:     false,
  selector:      'basic-details-header',
  templateUrl: './basic-details-header.component.html',
  host: { class: 'du-flex-column gap-s'}
})
export class BasicDetailsHeaderComponent {
  @Input() startDate:       Date;
  @Input() endDate:         Date;
  @Input() assignmentTitle: string;

  @Input() showNav:         boolean;
  @Input() preselect:       boolean;

  @Input() disablePrev:     boolean;
  @Input() disableNext:     boolean;

  @Output() goPrev         = new EventEmitter<void>;
  @Output() goNext         = new EventEmitter<void>;

  @Output() editCallback   = new EventEmitter<void>;
  @Output() deleteCallback = new EventEmitter<void>;

  optionsOpen: boolean;
  constructor( ) {}

  editItem(e: Event): void {
    e.stopPropagation();
    this.optionsOpen = null;
    this.editCallback.emit();
  }

  deleteItem(e: Event): void {
    e.stopPropagation();
    this.optionsOpen = null;
    this.deleteCallback.emit();
  }

}
