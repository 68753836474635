<div class="du-flex-column du-flex-1 overflow-auto" *ngIf="ebs">
  <div class="du-flex-column gap-m p-m shadow-header bg-white">
    <div class="du-flex-column gap-xs">
      <span class="font-s" translate="externalEmployee"></span>
      <span class="font-500">{{ ebs.persvorname }} {{ ebs.persname }}</span>
    </div>
    <div class="du-flex-column gap-xs">
      <span class="font-s" translate="assignment"></span>
      <span class="font-500 lh-l">{{ ebs.title }}</span>
    </div>
    <div class="du-flex-center gap-s p-s b-radius color-blue bg-grey font-500" (click)="call(ebs.filtelefon1)">
      <span class="du-flex-center font-xl du-icon-phone"></span>
      <span class="font-16">Niederlassung</span>
    </div>
  </div>

  <div class="du-flex-column du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align bb-grey pl-m pr-m pt-s pb-s" (click)="collapseBlockOne = !collapseBlockOne">
      <span class="font-500">Einsatzdaten</span>
      <span class="circle-s bg-orange mla mr-m" *ngIf="sectionUpdated('Einsatzdaten')"></span>
      <span class="du-flex-center font-xl color-blue du-icon-arrow-drop-down"></span>
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockOne">
      <div class="du-flex-column gap-s overflow">
        <div class="du-flex-column gap-s">
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Einsatzbeginn',                 field: 'aufueberlbeginn', value: ebs.aufueberlbeginn | date: 'dd.MM.yyyy'                    }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Melduhrzeit',                   field: 'aufmuhrzeit',     value: ebs.aufmuhrzeit    || '-'                                   }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Arbeitszeit pro Woche',         field: 'aufarbzeitwoche', value: ebs.aufarbzeitwoche | distanceFormat                        }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Meldeansprechpartner',          field: 'aufansprech1',    value: ebs.aufansprech1   || '-',                                  }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Meldeadresse',                  field: 'aufadresse1',     value: ebs.aufadresse1    || '-', onclick: this.openMap.bind(this) }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Meldeansprechpartner Telefon',  field: 'anspr_telefon',   value: ebs.anspr_telefon  || '-', onclick: this.call.bind(this)    }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Meldetelefon',                  field: 'auftelefon1',     value: ebs.auftelefon1    || '-', onclick: this.call.bind(this)    }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Betriebsstätte',                field: 'aufbetrstaette',  value: ebs.aufbetrstaette || '-'                                   }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Einsatzberuf',                  field: 'aufberuf',        value: ebs.aufberuf       || '-'                                   }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Sonstige Bemerkungen',          field: 'auftextmarken4',  value: ebs.auftextmarken4 || '-'                                   }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Einsatztätigkeitsbeschreibung', field: 'aufpostaet1',     value: ebs.aufpostaet1    || '-'                                   }"></ng-container>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-m pr-m pt-s pb-s" (click)="collapseBlockTwo = !collapseBlockTwo">
      <span class="font-500">Sicherheits- und Schutzausrüstung</span>
      <span class="circle-s bg-orange mla mr-m" *ngIf="sectionUpdated('Sicherheits')"></span>
      <span class="du-flex-center font-xl color-blue du-icon-arrow-drop-down"></span>
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockTwo">
      <div class="du-flex-column gap-m overflow">
        <div class="du-flex-column gap-s" [class.color-orange]="dataUpdated('aufarbs')">
          <span class="font-s">Persönliche Schutzausrüstung</span>
          <ul class="du-flex-column gap-xs ml-xl list-disc" *ngIf="ebs.aufarbs">
            <li class="font-500" *ngFor="let item of ebs.aufarbs">{{item}}</li>
          </ul>
        </div>

        <ng-container *ngTemplateOutlet="ebsColumn; context: { label: 'Gefährliche Arbeit', field: 'aufgefahrarbeit', value: ebs.aufgefahrarbeit || '-' }"></ng-container>
        <div class="bb-grey"></div>

        <ng-container *ngTemplateOutlet="ebsColumn; context: { label: 'Untersuchungen',     field: 'aufuntersuchidx', value: ebs.aufuntersuchidx || '-' }"></ng-container>
        <div class="bb-grey"></div>

        <div class="du-flex-column gap-xs">
          <span class="font-s">Sicherheits- und Gesundheitsschutz</span>
          <span class="font-500 lh-l">Die Sicherheitsunterweisung des Mitarbeiters deckt auch die o. a. Tätigkeit ab. Der Mitarbeiter wird vor Antritt seiner Tätigkeit vom Entleiher eine Belehrung über die Sicherheitsvorschriften am Arbeitsplatz erhalten. Er verpflichtet sich, die für diesen Einsatz notwendige Schutzausrüstung an seinem Arbeitsplatz zu tragen.</span>
          <span class="font-500 lh-l">Arbeitsmedizinische Vorsorge für diesen Einsatz wurde dem Mitarbeiter angeboten.</span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-m pr-m pt-s pb-s" (click)="collapseBlockThree = !collapseBlockThree">
      <span class="font-500">Aufwandsentschädigung / Tag</span>
      <span class="circle-s bg-orange mla mr-m" *ngIf="sectionUpdated('Aufwandsentschädigung')"></span>
      <span class="du-flex-center font-xl color-blue du-icon-arrow-drop-down"></span>
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockThree">
      <div class="du-flex-column gap-m overflow">
        <div class="du-flex-column gap-s">
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'KM Entfernung',       field: 'aufueentfernung', value: (ebs.aufueentfernung | distanceFormat) +' Km' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Fahrbetrag',          field: 'auffahrbetrag',   value: (ebs.auffahrbetrag   | payFormat     ) +' €'  }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'VMA',                 field: 'aufvmabetrag',    value: (ebs.aufvmabetrag    | payFormat     ) +' €'  }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Übernachtungsbetrag', field: 'aufuebernbetrag', value: (ebs.aufuebernbetrag | payFormat     ) +' €'  }"></ng-container>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-s">
          <span class="font-500">Zulagen(Brutto)</span>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Entsendezulage',   field: 'pel705preis',        value: (ebs.pel705preis        | payFormat) +' €' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine; context: { label: 'Sonstige Zulagen', field: 'txt_sonstigeZulage', value: (ebs.txt_sonstigeZulage | payFormat) +' €' }"></ng-container>
        </div>

        <div class="du-flex-column gap-xs">
          <span class="font-s">Mehrarbeit</span>
          <span class="font-500 lh-l">Der Mitarbeiter verpflichtet sich, im gesetzlich zulässigen Rahmen Mehrarbeit zu leisten.</span>
        </div>

        <div class="bb-grey"></div>

        <div class="du-flex-column gap-s">
          <span class="font-500">Tarifliches</span>
          <ng-container *ngTemplateOutlet="ebsLine;   context: { label: 'Entgeltgruppe',                 field: 'auftarifgruppe',     value: ebs.auftarifgruppe     || '-' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine;   context: { label: 'Entgelttarif',                  field: 'auftarifmagruppe',   value: ebs.auftarifmagruppe   || '-' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine;   context: { label: 'Decklungsbetrag',               field: 'aufdeckelbetragakt', value: ebs.aufdeckelbetragakt || '-' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsLine;   context: { label: 'Branchenzuschlagstarifvertrag', field: 'auftarifbranche',    value: ebs.auftarifbranche    || '-' }"></ng-container>
          <ng-container *ngTemplateOutlet="ebsColumn; context: { label: 'Tarifvertrag',                  field: 'auftarifvertrag',    value: ebs.auftarifvertrag    || '-' }"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column bb-grey du-flex-shrink-0">
    <div class="du-flex-justify du-flex-align pl-m pr-m pt-s pb-s" (click)="collapseBlockFour = !collapseBlockFour">
      <span class="font-500">Kunde</span>
      <span class="du-flex-center font-xl color-blue du-icon-arrow-drop-down"></span>
    </div>
    <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseBlockFour">
      <span class="du-flex-column gap-m overflow">
        <span class="du-flex-column gap-s">
          <span class="font-s">Anmerkungen (Kunden)</span>
          <span class="font-500 lh-l">Der Mitarbeiter ist verpflichtet, Tempton unverzüglich zu informieren, wenn er mit dem oben genannten Kunden oder einem Unternehmen, das mitdiesem Kunden einen Konzern gemäß §18 AktG bildet, in den letzten sechs Monaten vor Beginn der Überlassung in einem Arbeitsverhältnis gestanden hat oder in diesem Zeitraum -egal für welchen Arbeitgeber - bereits einmal in dem nachfolgend genannten Einsatzbetrieb eingesetzt war. Bei Arbeitsverhinderung verpflichtet sich der Mitarbeiter / die Mitarbeiterin vor Arbeitsbeginn den Kunden und seine / ihre Tempton-Niederlassung (erreichbar unter der Telefonnummer: {{ ebs.filtelefon1 }}) bis spätestens 8:30 Uhr unter Angabe der Gründe zu verständigen. Die Meldepflicht besteht auch, wenn aus irgendwelchen Gründen, die von Tempton mit dem Kunden vereinbarte Einsatzzeit nicht oder nicht voll abgeleistet werden kann, z.B. wenn der Kunde keinen oder nur einen kürzeren Einsatz ermöglicht.</span>
        </span>
        <span class="du-flex-column gap-s">
          <span class="font-s">Hinweis</span>
          <span class="font-500 lh-l">Wir informieren Sie ferner gem. §11 Abs. 2 AÜG darüber, dass Sie im o. g. Einsatz als Leiharbeitnehmer (m/w/d) tätig werden.</span>
        </span>
      </span>
    </div>
  </div>
</div>

<div class="p-m mta">
  <button class="width-full btn-primary" (click)="confirm()" [disabled]="!ebs || ebs.confirmedAt">{{ !ebs || ebs.confirmedAt ? 'Bestätigt' : 'Bestätigen' }}</button>
</div>

<ng-template #ebsLine let-label="label" let-value="value" let-field="field" let-onclick="onclick">
  <span class="du-flex-align-start" [class.color-orange]="dataUpdated(field)" (click)="onclick && onclick(value)">
    <span class="pt-xxs font-s width-200">{{ label }}</span>
    <span class="du-flex-1 font-500 lh-l">{{ value }}</span>
  </span>
</ng-template>

<ng-template #ebsColumn let-label="label" let-value="value" let-field="field">
  <span class="du-flex-column gap-s" [class.color-orange]="dataUpdated(field)">
    <span class="font-s">{{ label }}</span>
    <span class="font-500 lh-l">{{ value }}</span>
  </span>
</ng-template>
