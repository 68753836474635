<ng-container *ngIf="device">
  <div class="du-flex-column gap-m">
    <div class="du-flex-column gap-xxs">
      <span class="bold" translate="phoneSystem"></span>
      <span class="font-s lh-l" translate="supportDesc"></span>
    </div>
    <div class="du-flex gap-xl">
      <div class="du-flex-column width-80 gap-xxs">
        <span class="font-s" translate="appVer"></span>
        <span class="font-500">{{ appVer }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xxs">
        <span class="font-s" translate="model"></span>
        <span class="font-500">{{ device.model }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xxs">
        <span class="font-s" translate="osVersion"></span>
        <span class="font-500">{{ device.osVersion }}</span>
      </div>
    </div>
    <div class="du-flex gap-xl">
      <div class="du-flex-column width-80 gap-xxs">
        <span class="font-s" translate="manufacturer"></span>
        <span class="font-500">{{ device.manufacturer }}</span>
      </div>
      <div class="du-flex-column width-80 gap-xxs">
        <span class="font-s" translate="platform"></span>
        <span class="font-500">{{ device.platform }}</span>
      </div>
    </div>
  </div>
  <div class="du-flex-column gap-xs">
    <span class="bold" translate="comment"></span>
    <textarea class="b-grey p-xs height-120" placeholder="{{ 'whatNotWorking' | translate }}" [(ngModel)]="note"></textarea>
  </div>
</ng-container>

<button class="width-full btn-primary mta mb-xs" translate="send" [disabled]="!note" (click)="sendSupportRequest()"></button>
