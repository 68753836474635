import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { from, map, switchMap, take, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { DBService, MileageReportsService, NotificationService, SessionStorageService } from '@shared/services';
import { MileageReportLocal, StandaloneMileageReportOverview } from '@shared/factories';
import { collapse } from '@shared/animations';

@Component({
  standalone:     false,
  templateUrl: './mileage-details.component.html',
  host: { class: 'height-full du-flex-column overflow bg-white' },
  animations: [ collapse ]
})
export class MileageReportDetailsComponent implements OnInit {
  cachedReports: StandaloneMileageReportOverview[] = [];
  mileage:       StandaloneMileageReportOverview | MileageReportLocal;
  mileageIndex:  number = 0;
  useCase:       string;

  private MM_DB_TABLE: string = 'localMileages';
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private mileageReportsService: MileageReportsService,
    private translateService:      TranslateService,
    private dbService:             DBService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.sessionStorageService.setHeaderTitle('mileageMoney');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });

    if      (this.useCase === 'mm')   this.prepareOverviewMileage();
    else if (this.useCase === 'mm-c') this.prepareLocalMileage();
  }

  private prepareOverviewMileage(): void {
    this.notificationService.wait();
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.cachedEntries.pipe(
      take(1),
      switchMap(mileages => {
        this.cachedReports = mileages || [];
        return this.route.params;
      })
    ).subscribe(
      params => {
        this.renderReport(this.cachedReports.findIndex(r => r.id === +params['id']));
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  private prepareLocalMileage(): void {
    this.sessionStorageService.setProgressBar(4, 4);
    let report = this.sessionStorageService.temporaryMileageReport;

    if (this.useCase === 'mm-c' && !report || !report.assignment || !report.startDate || !report.workDays?.length) this.router.navigateByUrl('/time-tracking/home');
    else {
      this.mileage = new MileageReportLocal(report.toJSON());
      this.mileage.workDays = this.mileage.activeWorkDays;
    }
  }

  private renderReport(mileageIndex: number): void {
    if (mileageIndex !== -1) {
      this.mileageIndex = mileageIndex;
      this.mileage = this.cachedReports[mileageIndex];
    } else this.goBack();
  }

  goBack(): void {
    if (this.useCase === 'mm-c') this.router.navigateByUrl('/time-tracking/constructor-mm');
    else                         this.router.navigateByUrl('/time-tracking/mileage-overview');
  }

  goPrevReport(): void {
    this.renderReport(this.mileageIndex-1);
  }

  goNextReport(): void {
    this.renderReport(this.mileageIndex+1);
  }

  submitMileage(duplicate: boolean = null): void {
    this.notificationService.wait();
    this.mileageLocal.duplicate = duplicate;
    this.mileageReportsService.submitMileageReport(this.mileageLocal).subscribe(
      mileage => this.notificationService.close(),
      err     => {
        if (err[0]?.code === 'duplicates_mileage_report_within_type' ||
            err[0]?.code === 'duplicates_both_mileage_report_types') this.confirmDuplicate(err[0].title);
        else this.notificationService.alert(err);
      }
    );
  }

  saveMileage(): void {
    this.notificationService.wait();
    from(this.dbService.saveOneToDB(this.MM_DB_TABLE, this.mileageLocal)).pipe(
      tap(() => this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 'mm' }}))
    ).subscribe(
      ()  => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private confirmDuplicate(errorString: string): void {
    this.translateService.get('confirmDuplicate').pipe(take(1)).subscribe((res: string) => {
      this.notificationService.confirm({
        title: 'confirm',
        desc: `${errorString} \n ${res}`,
        rightFn: () => this.submitMileage(true)
      });
    });
  }

 get mileageLocal():    MileageReportLocal              { return this.mileage as MileageReportLocal;              }
 get mileageOverview(): StandaloneMileageReportOverview { return this.mileage as StandaloneMileageReportOverview; }

}
