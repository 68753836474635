<basic-overview-item [startDate]="mileage.startDate"
                     [endDate]="mileage.endDate"
                     [extended]="extended"

                     [stateColor]="getStateColor()"
                     [stateIcon]="getStateIcon()"

                     [assignmentTitle]="mileage.assignment && mileage.assignment.title"
                     [rightLabel]="'distance'"
                     [rightValue]="(mileage.totalKm | distanceFormat) + 'km'"
                     (close)="close.emit()">
</basic-overview-item>
