<div class="du-flex-align" *ngIf="left">
  <div *ngFor="let el of left" class="p-m color-blue" [ngClass]="el.class" (click)="el.callback && el.callback()">
    <img  *ngIf="el.svg"  [src]="'assets/svg/' + el.svg + '.svg'">
    <span *ngIf="el.icon" class="du-flex-center font-xl" [ngClass]="'du-icon-' + el.icon"></span>
    <span *ngIf="el.label">{{ el.label | translate }}</span>
  </div>
  <span class="du-flex-center color-grey bold">{{ title | translate }}</span>
</div>

<div class="du-flex-align gap-m pr-m" *ngIf="right">
  <div *ngFor="let el of right" [ngClass]="el.class" (click)="el.callback && el.callback()">
    <img  *ngIf="el.svg"  [src]="'assets/svg/' + el.svg + '.svg'">
    <span *ngIf="el.icon" class="du-flex-center font-xl" [ngClass]="'du-icon-' + el.icon">
      <img *ngIf="el.img" [src]="el.img">
    </span>
    <span *ngIf="el.label">{{ el.label | translate }}</span>
  </div>
</div>
