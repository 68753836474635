<div class="du-flex-column gap-m">
  <span class="font-l bold" translate="selectCalendarWeek"></span>
  <ul class="du-flex-column gap-m">
    <li *ngFor="let week of weeks" (click)="selectWeek(week)"
         class="du-flex-justify du-flex-align du-flex-max-content lh-l b-radius pl-m pr-m pt-s pb-s font-400 hover-active shadow-header"
        [class.bg-white]="!activeWeek || activeWeek.weekStart.getTime() !== week.weekStart.getTime()"
        [class.bg-blue]="activeWeek && activeWeek.weekStart.getTime() === week.weekStart.getTime()"
        [class.color-white]="activeWeek && activeWeek.weekStart.getTime() === week.weekStart.getTime()">
      <span class="du-flex-align gap-xxs">
        <span translate="cw"></span>
        <span>{{  week.weekStart | date: 'ww' }}</span>
        <span>({{ week.weekStart | date: 'dd' }}.{{ week.weekStart | date: 'MM' }} - {{ week.weekEnd | date: 'dd' }}.{{ week.weekEnd | date: 'MM' }})</span>
      </span>
      <div class="du-flex-align gap-s" *ngIf="useCase !== 'mm'">
        <span class="width-35 text-center bg-orange b-radius color-white pl-s pr-s pt-xxs pb-xxs" *ngIf="week.localDailies">{{ week.localDailies }}</span>
        <img class="width-20" src="assets/svg/mileage.svg"       *ngIf="week.mileage && (!activeWeek || activeWeek.weekStart.getTime() !== week.weekStart.getTime())">
        <img class="width-20" src="assets/svg/mileage-white.svg" *ngIf="week.mileage &&   activeWeek && activeWeek.weekStart.getTime() === week.weekStart.getTime()">
      </div>
    </li>
  </ul>
</div>

<div class="du-flex-column du-flex-justify-end gap-l">
  <ng-container *ngIf="useCase !== 'mm'">
    <div class="du-flex-column bg-light-blue b-radius p-xs" *ngIf="!closeMileageTip && activeWeek && activeWeek.mileage && activeWeek.mileage.start"
        [@collapse]="{ value: ':enter', params: { offsetTop: '-20px' }}"
        [@.disabled]="!animate">
      <div class="du-flex-align-start du-flex-justify gap-xs overflow">
        <img  class="width-20" src="assets/svg/mileage.svg">
        <span class="font-s lh-l du-flex-1" translate="millageAppliedToAssignment"></span>
        <img src="assets/svg/close.svg" (click)="animate = true; closeMileageTip = true">
      </div>
    </div>
  </ng-container>

  <div class="du-flex-align gap-s pb-xs">
    <button class="du-flex-align gap-xs width-full btn-secondary" (click)="goBack()">
      <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
      <span translate="back"></span>
    </button>
    <button class="width-full btn-primary" translate="next" (click)="confirmWeek()" [disabled]="!activeWeek"></button>
  </div>
</div>
