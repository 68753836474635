<div class="du-flex-align du-flex-justify pl-m pr-m pt-s pb-s bb-grey relative">
  <span class="font-500">{{ template.name }}</span>
  <more-box (editCallback)="editTemplate()" (deleteCallback)="deleteTemplatePrompt()"></more-box>
</div>

<div class="du-flex-justify du-flex-align gap-m p-s" (click)="useTemplate()">
  <div class="du-flex-column gap-xs">
    <span class="font-s" translate="days"></span>
    <span class="du-flex-align gap-xxs bold">
      <span *ngFor="let day of template.dailies; let i = index">{{ day.startsAt | date: 'EEE' }}{{ i+1 < template.dailies.length ? ',' : '' }}</span>
    </span>
  </div>
  <span class="du-flex-center font-xl color-blue du-icon-arrow-big-right"></span>
</div>
