<div class="du-flex-column gap-xxs">
  <span class="font-20 font-500" translate="yourDevices"></span>
  <span translate="manageConnectedDevices"></span>
</div>

<div class="du-flex-column du-flex-1 gap-s overflow-auto">
  <div class="du-flex-column gap-xs p-s b-radius shadow-header bg-white" *ngFor="let device of devices">
    <div class="du-flex-justify du-flex-align-start gap-m">
      <div class="du-flex-align gap-xs">
        <span class="circle-48 du-flex-center bg-grey color-blue font-xl" [ngClass]="getDeviceType(device)"></span>
        <span class="du-flex-column gap-xs">
          <span>{{ device.model || '-' }}</span>
          <span>
            <span translate="lastActive"></span>
            <span>: {{ device.lastConnectedAt | date: 'HH:mm' }}</span>
          </span>
        </span>
      </div>
      <span class="du-flex-center b-radius font-500 font-xl p-xxs" (click)="toggleDevice(device)"
           [ngClass]="'du-icon-' + (device.lockedByUserAt ? 'lock bg-light-red color-red' : 'lock-open bg-light-green')"></span>
    </div>
    <span class="du-flex-align gap-xxs">
      <span class="du-flex-center font-xl du-icon-marker"></span>
    </span>
  </div>
</div>
