<div class="du-flex-align gap-m">
  <span class="du-flex-center font-xl du-icon-arrow-big-left" (click)="close()"></span>
  <span class="font-16 font-500" translate="customerSignature"></span>
</div>

<div class="du-flex-column gap-s">
  <span class="bold" translate="signHere"></span>
  <div class="du-flex-align gap-xs pl-m pr-m pt-s pb-s b-grey" (click)="openSignaturePad()" *ngIf="!signature">
    <span class="du-flex-center font-xl du-icon-signature"></span>
    <span translate="customerSignature"></span>
  </div>
  <img class="width-full height-200 b-grey object-contain" [src]="signature" *ngIf="signature"  (click)="openSignaturePad()">
</div>

<div class="du-flex-column gap-s pb-xs pt-xs">
  <span class="bold" translate="customerName"></span>
  <input type="text" [(ngModel)]="signer">
</div>

<button class="btn-primary" translate="done" (click)="submit()" [disabled]="!signature || !signer"></button>
