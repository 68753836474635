<tabs-box (updateData)="loadEntries(true)" [useCase]="useCase" *ngIf="useCase !== 'sr' && useCase !== 'n' && useCase !== 'r'"></tabs-box>

<div class="du-flex-column du-flex-1 overflow-auto gap-s p-m bg-grey">
  <!-- <pull-to-refresh [topSpacer]="'m'" [gapSpacer]="'xs'" [fullHide]="useCase === 'sr' || useCase === 'n' || useCase === 'r'" (callback)="loadEntries(true)"></pull-to-refresh> -->
  <div class="du-flex-justify">
    <span class="font-s font-600" *ngIf="paging">{{ paging.total_count }} Ergebnisse</span>
    <sort-box class="mla" *ngIf="useCase !== 'sr' && useCase !== 'n' && useCase !== 'r'" (updateData)="loadEntries(true)" [useCase]="useCase"></sort-box>
  </div>

  <div class="du-flex-1 height-full du-flex-column gap-m overflow">
    <reports-overview   id="pull-to-refresh-container" *ngIf="useCase === 'ar'" (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></reports-overview>
    <documents-overview id="pull-to-refresh-container" *ngIf="useCase === 'pd'" (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></documents-overview>
    <vacations-overview id="pull-to-refresh-container" *ngIf="useCase === 'vr'" (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></vacations-overview>
    <mileage-overview   id="pull-to-refresh-container" *ngIf="useCase === 'mm'" (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></mileage-overview>
    <salary-overview    id="pull-to-refresh-container" *ngIf="useCase === 'sr'" (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></salary-overview>
    <notifications      id="pull-to-refresh-container" *ngIf="useCase === 'n'"  (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></notifications>
    <releases           id="pull-to-refresh-container" *ngIf="useCase === 'r'"  (loadEntries)="loadHandler($event)" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="150" [scrollWindow]="false" (scrolled)="loadEntriesHandler()"></releases>

    <span *ngIf="paging && paging.total_pages === 0" class="du-flex-center p-m color-disabled">No results were found.</span>
  </div>
</div>

<pagination (loadNextPage)="loadEntriesHandler()" (loadPage)="loadPage($event)"></pagination>
