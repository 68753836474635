import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, map, of, switchMap } from 'rxjs';

import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';
import { ActivityReportsService, DBService, NotificationService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'reports-overview',
  templateUrl: './reports-overview.component.html',
  host: { class: 'height-full du-flex-column gap-m overflow-auto' }
})
export class ReportsOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  reports: (ActivityReportOverview | ActivityReportLocal)[] = [];
  constructor(
    private dbService:              DBService,
    private activityReportsService: ActivityReportsService,
    private notificationService:    NotificationService,
    private sessionStorageService:  SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadReports.bind(this));
    this.loadReports();
  }

  loadReports(reload: boolean = null, page: number = null): void {
    this.notificationService.wait();
    this.activityReportsService.loadReports(page).pipe(
      switchMap(reports => {
        if (page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'ar' })).pipe(
          map(pendingAr => pendingAr.map(vr => new ActivityReportLocal(vr))),
          map(pendingAr => [...pendingAr, ...reports])
        );
        else return of(reports);
      })
    ).subscribe(
      reports => {
        if (reload) this.reports = [...reports];
        else this.reports = [...this.reports, ...reports];
        this.sessionStorageService.setCachedEntries(this.reports);
        this.notificationService.close();
      },
      err => this.notificationService.alert(err),
      () => this.sessionStorageService.setPulltoRefreshState(true)
    );
  }

}
