<div class="du-flex-column gap-m p-m" *ngIf="entry">
  <span class="font-l bold" translate="releaseOptions"></span>
  <div class="du-flex-column gap-s">
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" 
          [ngClass]="entry.signedByCustomer ? 'bg-blue color-white' : 'bg-white'"
          (click)="selectLifeState(1)"
          translate="approvalWithSignature">
    </span>
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" *ngIf="entry && entry.assignment.canReleaseLater"
          [ngClass]="entry.waitingCustomerApproval ? 'bg-blue color-white' : 'bg-white'"
          (click)="selectLifeState(2)"
          translate="willApproveLater">
    </span>
    <span class="du-flex-align b-radius pt-s pb-s pl-m pr-m lh-l" 
          [ngClass]="entry.waitingClarification ? 'bg-blue color-white' : 'bg-white'"
          (click)="selectLifeState(3)"
          translate="needClarification">
    </span>
  </div>
</div>

<div class="du-flex-column du-flex-justify du-flex-1 gap-xl p-m bt-grey">
  <div class="du-flex-column gap-xs">
    <span translate="noteToManagerOpt"></span>
    <textarea class="height-60" cols="30" rows="3" class="p-xs b-grey" placeholder="{{ 'messageToOffice' | translate }}" [(ngModel)]="getReport().externalNote"></textarea>
  </div>
  
  <div class="du-flex-column gap-s mta" *ngIf="entry.signedByCustomer">
    <div class="du-flex-column gap-s">
      <div class="du-flex-justify du-flex-align gap-xs p-s shadow-header bg-white b-radius" (click)="openSignatureBox()" [class.b-red]="checkSignature && !entry.signature">
        <span class="du-flex-align gap-xs">
          <span class="du-flex-center font-xl color-blue du-icon-signature"></span>
          <span translate="customerSignature"></span>
        </span>
        <div class="du-flex-align gap-xs mla pt-xxs pl-xs pr-xs pb-xxs b-radius" [class.bg-light-green]="entry.signature">
          <span *ngIf=" entry.signature" class="du-flex-center font-icon du-icon-circle-tick"></span>
          <span *ngIf=" entry.signature" class="color-green font-500" translate="signed"></span>
          <span *ngIf="!entry.signature" class="color-blue  font-500" translate="sign"></span>
        </div>
      </div>
      <span class="font-s color-red" *ngIf="checkSignature && !entry.signature" translate="provideCustomerSignature"></span>
    </div>

    <div class="du-flex-justify du-flex-align gap-xs p-s shadow-header bg-white b-radius" *ngIf="useCase !== 'vr' && entry.signature" (click)="openCustomerEmailBox()">
      <span class="du-flex-align gap-xs">
        <span class="du-flex-center font-xl color-blue du-icon-envelope"></span>
        <span translate="sendCopyByEmail"></span>
      </span>

      <span class="du-flex-align gap-xs">
        <span class="bg-orange b-radius color-white pl-s pr-s pt-xxs pb-xxs" *ngIf="getReport().customerEmails &&  getReport().customerEmails.length">{{ getReport().customerEmails.length }}</span>
        <span class="du-flex-center font-xl color-blue du-icon-arrow-big-right"></span>
      </span>
    </div>
  </div>

  <div class="du-flex-column gap-m">
    <div class="du-flex-align-start gap-xs p-s bg-light-orange b-radius" *ngIf="entry && entry.lifeState && entry.lifeState !== 'signed_by_customer'">
      <span class="du-flex-center font-xl color-orange du-icon-warning"></span>
      <span class="font-s lh-l" translate="informationWillBeChecked"></span>
    </div>

    <div class="du-flex-align-start gap-xs p-s bg-light-orange b-radius" *ngIf="useCase === 'vr'">
      <span class="du-flex-center font-xl color-orange du-icon-warning"></span>
      <span class="font-s lh-l" translate="vacationNoLongerBeChanged"></span>
    </div>

    <button class="btn-secondary" translate="save" *ngIf="!entry.signature" [disabled]="!readyForSubmit()" (click)="saveEntry()"></button>
    <div class="du-flex-align gap-s pb-xs">
      <button class="du-flex-align gap-xs width-full btn-secondary" (click)="goBack()">
        <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
        <span translate="back"></span>
      </button>
      <button class="width-full btn-primary" translate="submit" [disabled]="!readyForSubmit()" (click)="submitEntry()"></button>
    </div>
  </div>
</div>
