import { Component, OnInit } from '@angular/core';
import { User } from '@shared/factories';

import { NotificationService, UserService } from '@shared/services';
import { take } from 'rxjs';

@Component({
  selector:      'profile-user',
  templateUrl: './profile-user.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify bg-grey' }
})
export class ProfileUserComponent implements OnInit {
  user: User;
  constructor(
    private userService:         UserService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser.pipe(take(1)).subscribe(
      user => this.user = user,
      err => this.notificationService.alert(err)
    );
  }

}
