<div class="du-flex-column du-flex-1 gap-m overflow">
  <span class="text-l bold" translate="selectDocumentType"></span>
  <ul class="du-flex-column gap-m overflow-auto" *ngIf="types && types.length">
    <li *ngFor="let type of types" (click)="tempType = type"
         class="du-flex-justify du-flex-align du-flex-max-content lh-l b-radius pl-m pr-m pt-s pb-s font-400 hover-active shadow-header"
        [class.bg-white]="!tempType || tempType.name !== type.name"
        [class.bg-blue]="tempType && tempType.name === type.name"
        [class.color-white]="tempType && tempType.name === type.name">
      <span>{{ type.name }}</span>
    </li>
  </ul>
</div>

<div class="du-flex-align gap-s">
  <button class="du-flex-align gap-xs width-full btn-secondary" (click)="goBack()">
    <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
    <span translate="back"></span>
  </button>
  <button class="width-full btn-primary" translate="next" (click)="confirmType()" [disabled]="!tempType"></button>
</div>
