import { Component } from '@angular/core';
import { Router    } from '@angular/router';
import { Location  } from '@angular/common';
import { from      } from 'rxjs/internal/observable/from';
import { map, tap  } from 'rxjs/operators';
import { Observable, forkJoin } from 'rxjs';

import { DBService, HolidaysService, NotificationService, SessionStorageService, UserService, VacationRequestsService } from '@shared/services';
import { Assignment, HolidayExtended, VacationRequestLocal } from '@shared/factories';
import { AssignmentModel } from '@shared/models';
import { collapse        } from '@shared/animations';

@Component({
  selector:      'vacation-date-select',
  templateUrl: './vacation-date-select.component.html',
  host: { class: 'du-flex-column p-m bg-grey' },
  animations: [ collapse ]
})
export class VacationDateSelectComponent {
  startsOn:      Date;
  endsOn:        Date;
  leaveType:     string;
  reason:        string;

  availableDays: number;
  validUntil:    Date;
  assignments:   Assignment[];
  holidays:      HolidayExtended[];
  holidaysList:  HolidayExtended[];
  closeHint:     boolean;

  minDate:       Date = new Date(new Date().getFullYear(),   new Date().getMonth() - 3, new Date().getDate());
  maxDate:       Date = new Date(new Date().getFullYear()+1, new Date().getMonth(),     new Date().getDate());
  constructor(
    private router:                  Router,
    private vacationRequestsService: VacationRequestsService,
    private holidaysService:         HolidaysService,
    private sessionStorageService:   SessionStorageService,
    private notificationService:     NotificationService,
    private dbService:               DBService,
    public  userService:             UserService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setHeaderTitle('vacationRequest');
    this.sessionStorageService.setProgressBar(2, 4);
    this.setVacationInfo();

    let vacation = this.sessionStorageService.temporaryVacationRequest;
    if (vacation) {
      this.startsOn  = vacation.startsOn && new Date(vacation.startsOn);
      this.endsOn    = vacation.endsOn   && new Date(vacation.endsOn);
      this.leaveType = vacation.leaveType;
      this.reason    = vacation.reason;
      forkJoin([
        this.loadAssignments(),
        this.loadHolidays()
      ]).subscribe(     
        () => this.notificationService.close(),
        err  => this.notificationService.alert(err)
      );
    } else this.router.navigateByUrl('time-tracking/home');
  }

  private setVacationInfo(): void {
    let user = this.userService.currentUserValue;
    if (user?.vacationInfo) {
      this.availableDays = user.vacationInfo.available_days;
      this.validUntil    = new Date(user.vacationInfo.valid_until);
    }
  }

  private loadAssignments(): Observable<Assignment[]> {
    this.notificationService.wait();
    return from(this.dbService.loadMultipleFromDB('assignments')).pipe(
      map((res: AssignmentModel[]) => res.map(a => new Assignment(a))),
      map(res => res.filter(assignment => assignment.active)),
      map(res => res.filter(assignment => assignment.endsAt.getTime()   >= this.minDate.getTime() &&
                                          assignment.startsAt.getTime() <= this.maxDate.getTime())),
      tap(list => this.assignments = list)
    );
  }

  private loadHolidays(): Observable<HolidayExtended[]> {
    return this.holidaysService.holidaysListMappedByStates().pipe(
      tap(list => this.holidays = list),
      tap(()   => this.filterHolidaysByPeriodAndState())
    );
  }

  dateChangedStart(dateString: any): void {
    this.startsOn = new Date(new Date(dateString).setHours(0,0,0,0));
    this.dateChanged('startsOn');
  }

  dateChangedEnd(dateString: any): void {
    this.endsOn = new Date(new Date(dateString).setHours(0,0,0,0));
    this.dateChanged('endsOn');
  }

  private dateChanged(dateField: string = null): void {
    this.validationCheck(dateField);
  }

  validationCheck(dateField: string = null): void {
    let now = new Date(new Date().setHours(0,0,0,0) - 4*7*24*60*60*1000);
    setTimeout(() => {
      if (this.startsOn) this.startsOn = new Date(Math.max(this.startsOn.getTime(), now.getTime()));
      if (this.endsOn)   this.endsOn   = new Date(Math.max(this.endsOn.getTime(),   now.getTime()));

      if ( this.startsOn && !this.endsOn) this.endsOn   = this.reason === 'death_of_close_relatives' ? new Date(this.startsOn.getTime() + (24*60*60*1000)) : new Date(this.startsOn);
      if (!this.startsOn &&  this.endsOn) this.startsOn = new Date(this.endsOn);
      if (this.startsOn  &&  this.endsOn) this.endsOn   = new Date(Math.max(this.startsOn.getTime(), this.endsOn.getTime()));
      this.preselectDates(dateField);
      this.filterHolidaysByPeriodAndState();
      this.closeHint = false;
    });
  }

  private preselectDates(dateField: string): void {
    if (this.startsOn && this.endsOn) {
      if (dateField === 'startsOn' && this.endsOn.getTime() < this.startsOn.getTime()) this.endsOn   = new Date(this.startsOn);
      if (dateField === 'endsOn'   && this.endsOn.getTime() < this.startsOn.getTime()) this.startsOn = new Date(this.endsOn);
      if (this.reason === 'wedding_birth' || this.reason === 'death_of_relatives' ) {
        this.startsOn = new Date((this as any)[dateField]);
        this.endsOn   = new Date((this as any)[dateField]);
      }
      if (this.reason === 'death_of_close_relatives') {
        if (dateField === 'startsOn' && this.workingVacationDays() > 2) this.endsOn   = this.startsOn.getDay() === 5 ? new Date(this.startsOn.getTime()+3*24*60*60*1000) : new Date(this.startsOn.getTime()+1*24*60*60*1000);
        if (dateField === 'endsOn'   && this.workingVacationDays() > 2) this.startsOn = new Date(this.endsOn.getTime() - (this.endsOn.getDay() ? this.endsOn.getDay() === 1 ? 3 : 1 : 2 )*24*60*60*1000);
      }
    }
  }

  workingVacationDays(): number {
    let count = 0;
    for (let start = new Date(this.startsOn); start.getTime() <= this.endsOn.getTime(); start.setDate(start.getDate()+1)) { 
      if (start.getDay() > 0 && start.getDay() < 6) count++;
    }
    return count;
  }

  totalVacationDays(): number {
    if (this.startsOn && this.endsOn) return Math.round((+this.endsOn - +this.startsOn) / (24*60*60*1000))+1;
    else return 0;
  }

  saveVacation(): void {
    this.notificationService.wait();
    this.createVacation();
    this.vacationRequestsService.saveVacationRequest(this.sessionStorageService.temporaryVacationRequest).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private createVacation(): void {
    let vacation = this.sessionStorageService.temporaryVacationRequest;
    if (!vacation.startsOn || !vacation.endsOn || vacation.startsOn.getTime() !== this.startsOn.getTime() || vacation.startsOn.getTime() !== this.startsOn.getTime()) {
      let data = {
        leave_type: vacation.leaveType,
        reason:     vacation.reason,
        starts_on:  this.startsOn.toISOString(),
        ends_on:    this.endsOn.toISOString(),
      };
      vacation = new VacationRequestLocal(data);
      (vacation as any).holidays = this.holidaysList;

      this.sessionStorageService.setTemporaryValue(vacation);
      this.sessionStorageService.setTemporaryAssignments(this.filterAssignmentsByPeriod());
    }
  }

  confirmVacation(): void {
    this.createVacation();
    this.goNext();
  }

  private goNext(): void {
    if (this.filterAssignmentsByPeriod()?.length) this.router.navigateByUrl('time-tracking/assignment-select-vr');
    else this.submitVacation();
  }

  goBack(): void {
    this.router.navigateByUrl('/time-tracking/vacation-type-select');
  }

  private filterHolidaysByPeriod(): HolidayExtended[] {
    return this.holidays?.filter(h => h.date.getTime() >= this.startsOn.getTime() && h.date.getTime() <= this.endsOn.getTime());
  }

  private filterAssignmentsByPeriod(): Assignment[] {
    return this.assignments?.filter(a =>
      a.startsAt.getTime() <= this.startsOn.getTime() && a.endsAt.getTime() >= this.startsOn.getTime() ||
      a.startsAt.getTime() >= this.startsOn.getTime() && a.endsAt.getTime() <= this.endsOn.getTime()   ||
      a.startsAt.getTime() <= this.endsOn.getTime()   && a.endsAt.getTime() >= this.endsOn.getTime()
    );
  }

  checkForAssignment(): Assignment[] {
    return this.startsOn && this.endsOn && this.filterAssignmentsByPeriod();
  }

  filterHolidaysByPeriodAndState(): void {
    this.holidaysList = null;
    let state         = this.userService.currentUserValue?.location?.state_iso;
    this.holidaysList = this.startsOn && this.endsOn && this.filterHolidaysByPeriod()?.filter(h => !h.states || state && h.states.includes(state));
  }

  private submitVacation(): void {
    this.notificationService.wait();
    this.vacationRequestsService.submitVacationRequest(this.sessionStorageService.temporaryVacationRequest).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

}
