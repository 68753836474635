<div class="du-flex-justify gap-s relative">
  <div class="du-flex-column gap-xxs">
    <span class="font-s" translate="assignment"></span>
    <span class="lh-l bold">{{ assignmentTitle }}</span>
  </div>

  <div class="du-flex-justify gap-s height-max-content" *ngIf="showNav">
    <button class="btn-blue-thin width-max-content p-xs font-30 du-icon-arrow-left"  (click)="goPrev.emit()" [disabled]="disablePrev"></button>
    <button class="btn-blue-thin width-max-content p-xs font-30 du-icon-arrow-right" (click)="goNext.emit()" [disabled]="disableNext"></button>
  </div>

  <more-box *ngIf="preselect" (editCallback)="editCallback.emit()" (deleteCallback)="deleteCallback.emit()"></more-box>
</div>

<div class="du-flex-justify gap-m">
  <div class="du-flex-column gap-xxs">
    <span class="font-s" translate="period"></span>
    <span class="du-flex-align gap-xxs font-500 roboto">
      <span>{{ startDate | date: 'dd.MM.yyyy' }}</span>
      <span>-</span>
      <span>{{ endDate   | date: 'dd.MM.yyyy' }}</span>
    </span>
  </div>

  <span class="du-flex-align gap-xxs bg-grey b-radius bold pt-xxs pb-xxs pl-xs pr-xs">
    <span class="du-flex-center font-icon du-icon-calendar-week"></span>
    <span translate="cw"></span>
    <span></span>
    <span class="roboto">{{ startDate | date: 'ww' }}</span>
  </span>
</div>
