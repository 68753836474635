<div class="du-flex-column gap-m p-m bb-grey">
  <span class="font-l bold" translate="imprint"></span>
  <div class="du-flex-column gap-s">
    <div class="du-flex-align du-flex-justify">
      <span class="du-flex-1 font-s" translate="phone"></span>
      <span class="du-flex-1 roboto bold">+49 20189479-0</span>
    </div>
    <div class="du-flex-align du-flex-justify">
      <span class="du-flex-1 font-s" translate="email"></span>
      <span class="du-flex-1 roboto bold">info@mytempton.de</span>
    </div>
    <div class="du-flex-align du-flex-justify" (click)="toggleDebug()">
      <span class="du-flex-1 font-s" translate="website"></span>
      <span class="du-flex-1 roboto bold">www.tempton.de</span>
    </div>
  </div>
</div>

<div class="du-flex-align du-flex-justify p-m bb-grey">
  <span class="font-l bold" translate="licenses"></span>
  <span class="du-flex-center du-icon-arrow-big-right font-xl color-blue"></span>
</div>

<div class="du-flex-align du-flex-justify p-m bb-grey" (click)="openPrivacy()">
  <span class="font-l bold" translate="dataPrivacy"></span>
  <span class="du-flex-center du-icon-arrow-big-right font-xl color-blue"></span>
</div>
