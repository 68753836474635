<div class="du-flex-column du-flex-align gap-m p-m" *ngIf="user">
  <div class="circle-100 bg-white bg-center bg-cover bg-no-repeat" [style.background-image]="'url(' + user.avatarThumb + ')'"></div>
  <span class="du-flex-column du-flex-align gap-xs">
    <span class="font-xl">{{ user.firstName }} {{ user.lastName }}</span>
    <span>{{ user.phoneNumber }}</span>
  </span>

  <div class="du-flex-align width-full b-radius du-flex-justify bg-white pl-s pr-s pt-xxs pb-xxs">
    <span class="du-flex-align gap-xxs">
      <span class="du-flex-center font-xl du-icon-marker"></span>
      <span>{{ user.address.city }}</span>
    </span>
    <span class="du-flex-algin gap-xs">
      <span class="du-flex-align">
        <span translate="personalNumber"></span>
        <span>:</span>
      </span>
      <span>{{ user.personalNumber }}</span>
    </span>
  </div>
</div>

<div class="du-flex-column gap-s p-m bt-grey">
  <div class="du-flex-align du-flex-justify bg-white b-radius p-s">
    <span class="du-flex-align gap-xxs">
      <span class="du-flex-center font-xl du-icon-password"></span>
      <span  class="font-500" translate="changePassword"></span>
    </span>
    <span class="du-flex-center font-xl du-icon-arrow-big-right color-blue"></span>
  </div>
  <div class="du-flex-align du-flex-justify bg-white b-radius p-s">
    <span class="du-flex-align gap-xxs">
      <span class="du-flex-center font-xl du-icon-image"></span>
      <span  class="font-500" translate="changeProfileImage"></span>
    </span>
    <span class="du-flex-center font-xl du-icon-arrow-big-right color-blue"></span>
  </div>
  <div class="du-flex-align du-flex-justify bg-white b-radius p-s">
    <span class="du-flex-align gap-xxs">
      <span class="du-flex-center font-xl du-icon-shield"></span>
      <span  class="font-500" translate="enableTwoFactor"></span>
    </span>
    <span class="du-flex-center font-xl du-icon-arrow-big-right color-blue"></span>
  </div>
</div>
