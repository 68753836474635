<div class="du-flex-column du-flex-center height-full gap-m">
  <span class="du-flex-center bg-white circle-120" [ngClass]="'du-icon-' + getIcon(type)"></span>
  <h2 class="font-xl mt-xxl bold"
     [class.color-blue]="type === 'submit' || type === 'save'"
     [class.color-red]="type === 'failure'"
     [class.color-orange]="type === 'offline'">{{ title | translate }}!</h2>
  <h3 class="color-primary ml-l mr-l text-center lh-l">{{ message | translate }}</h3>
</div>

<div class="du-flex-column gap-l mt-l"> 
  <button class="width-full" *ngFor="let button of buttons"
         [class.btn-primary]="!button.secondary"
         [class.btn-secondary]="button.secondary"
         (click)="button.callback()">
    {{ button.label | translate }}
  </button>
</div>
