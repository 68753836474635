import { Component, Input, OnInit } from '@angular/core';
import { SessionStorageService  } from '@shared/services';
import { HeaderControlItemModel } from '@shared/models';

@Component({
  selector:      'header',
  templateUrl: './header.component.html',
  host: { class: 'du-flex-align du-flex-justify width-full height-min-50 mt-ios bg-white shadow-header z-1', '[class.hide]' : '!visible' }
})
export class HeaderComponent implements OnInit {
  @Input() visible: boolean;
  @Input() title:   string;
  @Input() left:    HeaderControlItemModel[];
  @Input() right:   HeaderControlItemModel[];
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.headerVisibility.subscribe(visibility => this.visible = visibility);
    this.sessionStorageService.headerTitle.subscribe(title => this.title = title);
    this.sessionStorageService.headerControls.subscribe(controls => {
      this.left  = controls && controls.left;
      this.right = controls && controls.right;
    });
  }

}
