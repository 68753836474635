<div class="du-flex-align gap-m">
  <span class="du-flex-center font-xl du-icon-arrow-big-left" (click)="close()"></span>
  <span class="font-16 font-500" translate="sendCopyByEmail"></span>
</div>

<div class="du-flex-column gap-xs">
  <span translate="New Email"></span>
  <div class="du-flex-align gap-s">
    <input type="text" class="width-full" [(ngModel)]="customEmail">
    <button class="bg-grey font-xl color-blue p-s du-icon-circle-plus" (click)="addEmail()"></button>
  </div>
</div>

<div class="du-flex-column du-flex-1 gap-xs overflow-auto">
  <div class="du-flex-justify pt-s pb-s gap-m" *ngFor="let email of customerEmails; let i = index" [class.bb-grey]="i+1 !== customerEmails.length" (click)="email.active = !email.active">
    <div class="du-flex-column gap-s">
      <span>{{ email.email }}</span>
      <span>{{ email.name  }}</span>
    </div>
    <span *ngIf="!email.active" class="du-flex-center font-xl p-xs du-icon-checkbox"></span>
    <span *ngIf=" email.active" class="du-flex-center font-xl p-xs color-blue du-icon-checkbox-filled"></span>
  </div>
</div>

<button class="btn-primary mb-xs" translate="next" (click)="submit()"></button>
