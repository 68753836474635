<ng-container *ngIf="report">
  <div class="du-flex-column du-flex-1 overflow-auto">
    <div class="du-flex-column gap-m p-m shadow-header">
      <span class="du-flex-align bold font-16" *ngIf="useCase === 'ar-c'">
        <span translate="summary"></span>
        <span>:</span>
      </span>

      <basic-details-header [showNav]="useCase === 'ar'"
                            [startDate]="report.startDate"
                            [endDate]="report.endDate"
                            [assignmentTitle]="report.assignment.title"

                            [disablePrev]="!reportIndex"
                            [disableNext]=" reportIndex+1 === cachedReports.length"

                            (goPrev)="goPrevReport()"
                            (goNext)="goNextReport()">
      </basic-details-header>

      <div class="du-flex-wrap gap-xs" *ngIf="useCase === 'ar'">
        <div class="du-flex-align gap-xxs bg-grey pt-xxs pb-xxs pl-xs pr-xs b-radius bold" *ngIf="!reportOverview.attachmentPath && reportOverview.reportCorrected">
          <span class="du-flex-center font-icon du-icon-edited"></span>
          <span class="font-s lh-l du-flex-1" translate="editedInternaly"></span>
        </div>
        <div class="du-flex-align gap-xxs bg-grey pt-xxs pb-xxs pl-xs pr-xs b-radius bold" *ngIf="reportOverview.mileageMoneyReportId">
          <span class="du-flex-center font-icon du-icon-mileage"></span>
          <span class="font-s lh-l du-flex-1" translate="millageApplied"></span>
        </div>
        <div class="du-flex-align gap-xxs bg-grey pt-xxs pb-xxs pl-xs pr-xs b-radius bold" *ngIf="reportOverview.confirmationEmails" (click)="showEmailDetails()">
          <span class="du-flex-center font-icon du-icon-envelope"></span>
          <span class="font-s lh-l du-flex-1" translate="sentByEmail"></span>
        </div>
      </div>

      <div *ngIf="reportOverview.splitParentId || reportOverview.splitChildId" class="du-flex-justify du-flex-align gap-m p-s bg-grey b-radius bold"
            [routerLink]="['/time-tracking/report-details', reportOverview.splitParentId || reportOverview.splitChildId]">
        <span class="du-flex-align gap-xs">
          <span class="du-flex-center font-xl du-icon-split"></span>
          <span class="font-s lh-l du-flex-1" translate="arWasSplit"></span>
        </span>
        <span class="du-flex-center font-xl color-blue du-icon-arrow-big-right"></span>
      </div>
    </div>

    <ul class="du-flex-column du-flex-1 bg-grey gap-m p-m" *ngIf="!reportOverview.attachmentPath || reportOverview.approvedAt">
      <daily-overview-item [daily]="daily" *ngFor="let daily of reportOverview.dailyReports"></daily-overview-item>
    </ul>
    <photo-item *ngIf="reportOverview.attachmentPath" class="mt-m ml-xl mr-xl mb-xl" [itemUrl]="reportOverview.attachmentPath"></photo-item>

    <div class="du-flex-column bg-white" *ngIf="reportOverview.customerSignature">
      <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bb-grey" (click)="collapseSignature = !collapseSignature">
        <span class="font-500" translate="customerSignature"></span>
        <span class="du-flex-center font-xl color-blue du-icon-arrow-down" [class.rotate]="collapseSignature"></span>
      </div>
      <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseSignature">
        <div class="du-flex-column overflow relative">
          <img class="b-grey" [src]="reportOverview.customerSignature">
          <span *ngIf="reportOverview.customerSigner" class="absolute left-0 bottom-0 ml-s mb-s text-s">Unterschrieben von: {{ reportOverview.customerSigner }}</span>
        </div>
      </div>
    </div>

    <div class="du-flex-column bb-grey" *ngIf="report.externalNote">
      <div class="du-flex-justify du-flex-align pl-xl pr-xl pt-m pb-m bg-card-grey" (click)="collapseNote = !collapseNote">
        <span class="font-500" translate="noteToManager"></span>
        <span class="du-flex-center font-xl color-blue du-icon-arrow-down" [class.rotate]="collapseNote"></span>
      </div>
      <div class="du-flex-column pl-xl pr-xl pt-s pb-s" @collapse *ngIf="collapseNote">
        <div class="du-flex-column overflow">
          <span class="overflow">{{ report.externalNote }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="du-flex-column gap-m bt-grey p-m">
    <div class="du-flex-column gap-xs">
      <span class="du-flex-align du-flex-justify">
        <span class="font-400" translate="totalWorkingHours"></span>
        <span class="pl-m pr-m pt-xxs pb-xxs text-l font-500 roboto b-radius bg-green-light">{{ report.durationWithPauses | numberToTime }}</span>
      </span>
      <span class="du-flex-align du-flex-justify">
        <span class="font-400" translate="totalWorkingHoursWithoutBreaks"></span>
        <span class="pl-m pr-m pt-xxs pb-xxs text-l font-500 roboto b-radius bg-grey">{{ report.durationExludingPauses | numberToTime }}</span>
      </span>
    </div>

    <div class="du-flex-column gap-m pb-xs">
      <div *ngIf="useCase === 'ar-c'" class="du-flex-align gap-s">
        <button class="du-flex-align gap-xs width-full btn-secondary b-grey" (click)="goBack()">
          <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
          <span translate="back"></span>
        </button>
        <button class="width-full btn-primary" translate="next" (click)="goNext()"></button>
      </div>
      <button *ngIf="useCase === 'ar'" class="width-full color-blue" translate="back" (click)="goBack()"></button>
    </div>
  </div>
</ng-container>
