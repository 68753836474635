import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location               } from '@angular/common';
import { Capacitor              } from '@capacitor/core';
import { Observable, forkJoin, from, map, of, switchMap, tap } from 'rxjs';

import { AssignmentsService, DBService, EbsService, NotificationService, SessionStorageService } from '@shared/services';
import { collapse } from '@shared/animations';
import { EBS      } from '@shared/factories';

@Component({
  selector:      'ebs-details',
  templateUrl: './ebs-details.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify bg-grey' },
  animations: [ collapse ]
})
export class EbsDetailsComponent implements OnInit {
  ebs:          EBS;
  version:      any;
  assignmentId: number;

  collapseBlockOne:   boolean;
  collapseBlockTwo:   boolean;
  collapseBlockThree: boolean;
  collapseBlockFour:  boolean;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private location:              Location,
    private dbService:             DBService,
    private ebsService:            EbsService,
    private assignmentsService:    AssignmentsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('ebs');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setProgressBar(null);

    this.notificationService.wait();
    this.route.params.pipe(
      switchMap(params => {
        if (params['assignmentId']) {
          this.assignmentId = params['assignmentId'];
          this.version      = params['version'];
          if (!isNaN(this.version)) return this.loadEbsBeVer(+params['assignmentId'], +this.version);
          else return this.loadLatestEBS(+params['assignmentId']);
        } else return of();
      })
    ).subscribe(
      ebs => {
        if (ebs) {
          this.ebs = ebs;
          this.notificationService.close();
        } else this.notificationService.alert('noEBSContactSupport', () => this.goBack());
      },
      err => this.notificationService.alert(err)
    );
  }

  private goBack(): void {
    if (!this.version || isNaN(this.version)) this.location.back();
    else this.router.navigateByUrl('/time-tracking/ebs-overview');
  }

  private loadLatestEBS(assignmentId: number): Observable<EBS> {
    return from(this.dbService.loadMultipleFromDB('ebs', { assignment_id: assignmentId })).pipe(
      map(list => list.find(el => +el.version === +Math.max(...list.map(e => e.version)))),
      map(ebs => ebs ? new EBS(ebs) : null)
    );
  }

  private loadEbsBeVer(assignmentId: number, version: number): Observable<EBS> {
    return from(this.dbService.loadOneFromDB('ebs', { assignment_id: assignmentId, version })).pipe(
      map(ebs => new EBS(ebs))
    );
  }

  openMap(value: string): void {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios')     window.open(`maps://?q=${value}`, '_system');
      if (Capacitor.getPlatform() === 'android') window.open(`geo:0,0?q=${value}`, '_system');
    } else window.open(`https://www.google.com/maps?q=${value}` , '_system');
  }

  call(phone: string): void {
    let tel;
    let num = phone.replace(/ /g, '');
    if (num) {
      if (num.charAt(0) === '0') tel = `+49${num.substring(1)}`;
      else if (num.substring(0,2) === '49' && num.length === 12) tel = `+${num}`;
      else if (num.substring(0,3) === '+49') tel = `${num}`;
      else tel = `+49${num}`;
    } else tel = '+49 699 0501 4320'; // Tempton Digital (Support)

    let link = `tel:${tel}`;
    window.open(link, '_system');
  }

  confirm(): void {
    this.notificationService.wait();
    this.ebsService.confirmEBS(this.ebs.id).pipe(
      switchMap(res => forkJoin([
        this.assignmentsService.syncAssignments(),
        this.ebsService.syncEBS()
      ])),
      tap(() => {
        this.ebs.confirmedAt = new Date();
        if (this.version === 'max') this.updateTempAssignment();
      }),
      tap(() => this.sessionStorageService.setChip({
        message: 'ebsConfirmed',
        bgColor: 'green'
      }))
    ).subscribe(
      res => this.goBack(),
      err => this.notificationService.alert(err)
    );
  }

  private updateTempAssignment(): void {
    let temp = this.sessionStorageService.temporaryAssignments;
    if (temp?.length) {
      let index = temp.findIndex(a => a.id === this.assignmentId);
      if (index) {
        temp[index].latestEbs.confirmedAt = new Date();
        this.sessionStorageService.setTemporaryAssignments(temp);
      }
    }
  }

  dataUpdated(field: string): boolean {
    return !!(this.ebs.previousData &&
             (this.ebs.previousData as any)[field] !== null && 
             (this.ebs.previousData as any)[field] !== undefined);
  }

  sectionUpdated(section: string): boolean {
    switch (section) {
      case 'Einsatzdaten':
        return this.dataUpdated('aufueberlbeginn')    ||
               this.dataUpdated('aufmuhrzeit')        ||
               this.dataUpdated('aufarbzeitwoche')    ||
               this.dataUpdated('aufansprech1')       ||
               this.dataUpdated('aufadresse1')        ||
               this.dataUpdated('anspr_telefon')      ||
               this.dataUpdated('auftelefon1')        ||
               this.dataUpdated('aufbetrstaette')     ||
               this.dataUpdated('aufberuf')           ||
               this.dataUpdated('auftextmarken4')     ||
               this.dataUpdated('aufpostaet1');
      case 'Sicherheits':
        return this.dataUpdated('aufarbs')            ||
               this.dataUpdated('aufgefahrarbeit')    ||
               this.dataUpdated('aufuntersuchidx');
      case 'Aufwandsentschädigung':
        return this.dataUpdated('aufueentfernung')    ||
               this.dataUpdated('aufgefahrarbeit')    ||
               this.dataUpdated('aufuntersuchidx')    ||
               this.dataUpdated('aufueentfernung')    ||
               this.dataUpdated('auffahrbetrag')      ||
               this.dataUpdated('aufvmabetrag')       ||
               this.dataUpdated('aufuebernbetrag')    ||
               this.dataUpdated('pel705preis')        ||
               this.dataUpdated('txt_sonstigeZulage') ||
               this.dataUpdated('auftarifgruppe')     ||
               this.dataUpdated('auftarifmagruppe')   ||
               this.dataUpdated('aufdeckelbetragakt') ||
               this.dataUpdated('auftarifbranche')    ||
               this.dataUpdated('auftarifvertrag')
      default:
        return null;
    };
  }

}
