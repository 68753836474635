<div class="du-flex-column gap-s du-flex-1">
  <span class="bold font-l lh-l" *ngIf="document && document.docType && document.docType.name">
    {{ document.docType.name }}
  </span>
  <div class="du-flex-1 b-radius bg-card-grey relative overflow" (click)="!attachment && takePicture()">
    <img class="width-full object-contain"            [src]="attachment">
    <img class="absolute-center width-150 opacity-25" *ngIf="!attachment" src="assets/svg/camera-default.svg">
  </div>
</div>

<div class="du-flex-column gap-m">
  <div class="du-flex-column gap-xs">
    <span class="font-s" translate="noteToManagerOpt"></span>
    <textarea cols="30" rows="3" class="p-xs b-grey" placeholder="{{ 'messageToOffice' | translate }}" [(ngModel)]="note"></textarea>
  </div>
  <button class="du-flex-align gap-xs width-full b-grey" [ngClass]="attachment ? 'btn-secondary' : 'btn-primary'" (click)="takePicture()">
    <span class="du-flex-center font-xl du-icon-camera"></span>
    <span>{{ (attachment ? 'retakePicture' : 'takePicture') | translate }}</span>
  </button>
  <button class="width-full btn-secondary b-grey" translate="save"   (click)="save()"   *ngIf="attachment && !report?.id"></button>
  <button class="width-full btn-secondary b-grey" translate="update" (click)="save()"   *ngIf="attachment &&  report?.id"></button>
  <button class="width-full btn-primary"          translate="submit" (click)="submit()" [disabled]="!attachment"></button>
</div>
