import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SessionStorageService  } from '@shared/services';
import { PagingModel            } from '@shared/models';

@Component({
  selector:      'entries-overview',
  templateUrl: './entries-overview.component.html',
  host: { class: 'height-full du-flex-column overflow' }
})
export class EntriesOverviewComponent implements OnInit {
  useCase:     string;
  paging:      PagingModel;
  loadEntries: Function;

  // @HostBinding('class') get class(): string {
  //   return `height-full du-flex-column overflow ${this.useCase !== 'n' && this.useCase !== 'r' ? 'p-xl' : 'pt-xl pb-xl'}`;
  // }

  constructor(
    private router:                Router,
    private route:                 ActivatedRoute,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.sessionStorageService.paging.subscribe(paging => this.paging = paging);
  }

  private prepareHeader(): void {
    this.prepareTitle();
    this.prepareControls();
    this.sessionStorageService.setProgressBar(null);
  }

  private prepareTitle(): void {
    if (this.useCase === 'ar') this.sessionStorageService.setHeaderTitle('activityReport');
    if (this.useCase === 'vr') this.sessionStorageService.setHeaderTitle('overviewVacation');
    if (this.useCase === 'pd') this.sessionStorageService.setHeaderTitle('sentDocument');
    if (this.useCase === 'sr') this.sessionStorageService.setHeaderTitle('salaryReports');
    if (this.useCase === 'mm') this.sessionStorageService.setHeaderTitle('mileageOverview');
    if (this.useCase === 'n' ) this.sessionStorageService.setHeaderTitle('notifications');
    if (this.useCase === 'r' ) this.sessionStorageService.setHeaderTitle('releases');
  }

  private prepareControls(): void {
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
  }

  loadHandler(callback: Function): void {
    this.loadEntries = callback;
  }

  private goBack(): void {
    this.router.navigateByUrl('/time-tracking/home');
  }

  loadEntriesHandler(reload: boolean = null, page: number = null): void {
    if (!this.paging || !this.paging.total_pages || !this.paging.current_page || this.paging.current_page < this.paging.total_pages || reload) {
      let tempPage;
      if      (reload)                   tempPage = page || 1;
      else if (this.paging.current_page) tempPage = this.paging.current_page+1;
      else                               tempPage = 1;
      this.loadEntries(reload, tempPage);
    }
  }

  loadPage(page: number): void {
    this.loadEntriesHandler(true, page);
  }

}
