import { Component, OnInit            } from '@angular/core';
import { ActivatedRoute, Router       } from '@angular/router';
import { Observable, defer, from, tap } from 'rxjs';

import { ActivityReportsService, DBService, MileageReportsService, NotificationService, PhotoDocumentsService, SessionStorageService, UserService, VacationRequestsService } from '@shared/services';
import { ActivityReportLocal, MileageReportLocal, PhotoDocument, PhotoDocumentLocal, StandaloneMileageReportOverview, VacationRequestLocal, VacationRequestOverview } from '@shared/factories';
import { ActivityReportLocalModel, MileageReportLocalModel, PhotoDocumentLocalModel, VacationRequestLocalModel } from '@shared/models';
import { collapse } from '@shared/animations';

@Component({
  templateUrl: './preselect.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify gap-l bg-grey' },
  animations: [collapse]
})
export class PreselectComponent implements OnInit {
  useCase:  string;
  entries: (ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal)[] = [];
  constructor(
    private route:                   ActivatedRoute,
    private router:                  Router,
    private dbService:               DBService,
    private activityReportsService:  ActivityReportsService,
    private photoDocumentsService:   PhotoDocumentsService,
    private vacationRequestsService: VacationRequestsService,
    private mileageReportsService:   MileageReportsService,
    private userService:             UserService,
    private sessionStorageService:   SessionStorageService,
    private notificationService:     NotificationService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    this.prepareTemplate();
    this.prepareReports();

    if (!this.userService.checkTutorial(this.useCase)) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: this.useCase }
    });
  }

  private prepareReports(): void {
    this.notificationService.wait();
    defer(() => from(this.dbService.loadMultipleFromDB(this.dbTable))).pipe(
      tap(entries => this.prepareEntries(entries)),
    ).subscribe(() => this.notificationService.close());
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('/time-tracking/home')}] });
    if (this.useCase === 'ar') this.sessionStorageService.setHeaderTitle('activityReports');
    if (this.useCase === 'pr') this.sessionStorageService.setHeaderTitle('photoReports');
    if (this.useCase === 'pd') this.sessionStorageService.setHeaderTitle('photoDocument');
    if (this.useCase === 'vr') this.sessionStorageService.setHeaderTitle('vacationRequests');
    if (this.useCase === 'mm') this.sessionStorageService.setHeaderTitle('mileageMoney');
  }

  private prepareTemplate(): void {
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryValue(null);
  }

  private prepareEntries(entries: ActivityReportLocalModel[] | PhotoDocumentLocalModel[] | VacationRequestLocalModel[] | MileageReportLocalModel[]): void {
    if (this.useCase === 'ar') this.entries = this.prepareActivityReports(entries as ActivityReportLocalModel[]);
    if (this.useCase === 'pr') this.entries = this.prepareActivityReports(entries as ActivityReportLocalModel[]);
    if (this.useCase === 'pd') this.entries = this.preparePhotoDocuments( entries as PhotoDocumentLocalModel[]);
    if (this.useCase === 'vr') this.entries = this.prepareVacationRequest(entries as VacationRequestLocalModel[]);
    if (this.useCase === 'mm') this.entries = this.prepareMileageRequest( entries as MileageReportLocalModel[]);
  }

  private prepareActivityReports(entries: ActivityReportLocalModel[] ): ActivityReportLocal[]  { return entries.map(r => new ActivityReportLocal(r)).sort((a, b) => a.startDate.getTime() - b.startDate.getTime()); }
  private preparePhotoDocuments( entries: PhotoDocumentLocalModel[]  ): PhotoDocumentLocal[]   { return entries.map(e => new PhotoDocumentLocal(e));   }
  private prepareVacationRequest(entries: VacationRequestLocalModel[]): VacationRequestLocal[] { return entries.map(e => new VacationRequestLocal(e)); }
  private prepareMileageRequest( entries: MileageReportLocalModel[]  ): MileageReportLocal[]   { return entries.map(e => new MileageReportLocal(e));   }

  get activityReports():  ActivityReportLocal[]  { return this.entries as ActivityReportLocal[];  }
  get photoDocuments():   PhotoDocumentLocal[]   { return this.entries as PhotoDocumentLocal[];   }
  get vacationRequests(): VacationRequestLocal[] { return this.entries as VacationRequestLocal[]; }
  get mileageReports():   MileageReportLocal[]   { return this.entries as MileageReportLocal[];   }

  getStartDate(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal): Date {
    if (entry instanceof ActivityReportLocal)  return entry.startDate;
    if (entry instanceof VacationRequestLocal) return entry.startsOn;
    if (entry instanceof MileageReportLocal)   return entry.startDate;
    return null;
  }

  getEndDate(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal): Date {
    if (entry instanceof ActivityReportLocal)  return entry.endDate;
    if (entry instanceof VacationRequestLocal) return entry.endsOn;
    if (entry instanceof MileageReportLocal)   return entry.endDate;
    return null;
  }

  getAssignmentTitle(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal): string {
    if (entry instanceof ActivityReportLocal)  return entry.assignment.title;
    if (entry instanceof VacationRequestLocal) return entry.assignment.title;
    if (entry instanceof MileageReportLocal)   return entry.assignment.title;
    return null;
  }

  goToEdit(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal): void {
    this.sessionStorageService.setTemporaryValue(entry);
    if (this.useCase === 'ar') this.router.navigateByUrl(`/time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pr') this.router.navigateByUrl(`/time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'mm') this.router.navigateByUrl(`/time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pd') this.router.navigateByUrl('/time-tracking/document-select');
    if (this.useCase === 'vr') this.router.navigateByUrl('/time-tracking/vacation-type-select');
  }

  submitEntry(entry: ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal): void {
    this.notificationService.wait();
    let call;
    if (this.useCase === 'ar') call = this.submitActivityReport( entry as ActivityReportLocal);
    if (this.useCase === 'pr') call = this.submitActivityReport( entry as ActivityReportLocal);
    if (this.useCase === 'pd') call = this.submitPhotoDocument(  entry as PhotoDocumentLocal);
    if (this.useCase === 'vr') call = this.submitVacationRequest(entry as VacationRequestLocal);
    if (this.useCase === 'mm') call = this.submitMilegeReport(   entry as MileageReportLocal);

    call.subscribe(
      (res: any) => this.notificationService.close(),
      (err: any) => this.notificationService.alert(err)
    );
  }

  private submitActivityReport(report:   ActivityReportLocal):  Observable<any>                                                       { return this.activityReportsService.submitActivityReport(report);    }
  private submitPhotoDocument(document:  PhotoDocumentLocal):   Observable<PhotoDocument                   | PhotoDocumentLocal>      { return this.photoDocumentsService.submitPhotoDocument(document);    }
  private submitVacationRequest(request: VacationRequestLocal): Observable<VacationRequestLocal            | VacationRequestOverview> { return this.vacationRequestsService.submitVacationRequest(request); }
  private submitMilegeReport(mileage:    MileageReportLocal):   Observable<StandaloneMileageReportOverview | MileageReportLocal>      { return this.mileageReportsService.submitMileageReport(mileage);     }

  deleteEntry(reportId: number): void {
    this.notificationService.wait();
    defer(() => from(this.dbService.deleteOneFromDB(this.dbTable, reportId))).pipe(
      tap(() => this.entries = this.entries.filter(r => r.id !== reportId))
    ).subscribe(
      ()  => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private get dbTable(): string {
    if (this.useCase === 'ar') return 'localReports';
    if (this.useCase === 'pr') return 'photoReports';
    if (this.useCase === 'pd') return 'localDocuments';
    if (this.useCase === 'vr') return 'localVacations';
    if (this.useCase === 'mm') return 'localMileages';
    return '';
  }

  getReportName(): string {
    if (this.useCase === 'ar') return 'newReport';
    if (this.useCase === 'pr') return 'newReport';
    if (this.useCase === 'pd') return 'newDocument';
    if (this.useCase === 'vr') return 'newRequest';
    if (this.useCase === 'mm') return 'newMileage';
    return 'create';
  }

  getEmptyEntriesTitle(): string {
    if (this.useCase === 'ar') return 'noSavedReports';
    if (this.useCase === 'pr') return 'noSavedReports';
    if (this.useCase === 'pd') return 'noPendingDocuments';
    if (this.useCase === 'vr') return 'noPendingVacations';
    if (this.useCase === 'mm') return 'noPendingMileages';
    return '';
  }

  callbackHandler(): void {
    if (this.useCase === 'ar') this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pr') this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'mm') this.router.navigateByUrl(`time-tracking/assignment-select-${this.useCase}`);
    if (this.useCase === 'pd') this.router.navigateByUrl('time-tracking/document-select');
    if (this.useCase === 'vr') this.router.navigateByUrl('time-tracking/vacation-type-select');
  }

  getTemplateTitle(): string {
    switch (this.useCase) {
      case 'ar': return 'savedReports';
      case 'pr': return 'savedPhotoreports';
      case 'pd': return 'savedDocuments';
      case 'vr': return 'savedVacations';
      case 'mm': return 'savedMileages';
      default:
        return null;
    }
  }

}
