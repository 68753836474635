<div class="du-flex-align bg-white pl-m pr-m pb-s shadow-header z-1 gap-s">
  <div class="du-flex-center du-flex-1 gap-xxs pl-s pr-s pt-xxs pb-xxs b-radius" [ngClass]="activeTab === 'profile' ? 'bg-blue color-white' : 'bg-grey'" (click)="activeTab = 'profile'">
    <span class="du-flex-center font-xl du-icon-user"></span>
    <span class="font-500" translate="profile"></span>
  </div>
  <div class="du-flex-center du-flex-1 gap-xxs pl-s pr-s pt-xxs pb-xxs b-radius" [ngClass]="activeTab === 'devices' ? 'bg-blue color-white' : 'bg-grey'" (click)="activeTab = 'devices'">
    <span class="du-flex-center font-xl du-icon-devices"></span>
    <span class="font-500"  translate="devices"></span>
  </div>
  <div class="du-flex-center du-flex-1 gap-xxs pl-s pr-s pt-xxs pb-xxs b-radius" [ngClass]="activeTab === 'badges' ? 'bg-blue color-white' : 'bg-grey'"  (click)="activeTab = 'badges'">
    <span class="du-flex-center font-xl du-icon-badges"></span>
    <span class="font-500" translate="badges"></span>
  </div>
</div>

<profile-user    *ngIf="activeTab === 'profile'"></profile-user>
<profile-devices *ngIf="activeTab === 'devices'"></profile-devices>