<div class="du-flex-column du-flex-1 overflow-auto">
  <div class="b-radius b-grey relative ml-xl mr-xl mt-m" *ngIf="banner && banner.image" (click)="openBannerUrl()">
    <img class="b-radius width-full" [src]="banner.image" (load)="notificationService.close()">
  </div>  
  <div class="du-flex-column gap-m p-m bb-grey">
    <span class="font-l bold" translate="youImportantToUs"></span>
    <div class="du-flex-column">
      <span class="lh-l" translate="specialThank"></span>
      <span class="lh-l" translate="exclusiveOffers"></span>
    </div>
  </div>
  <div class="du-flex-column gap-m p-m">
    <span class="font-l bold" translate="secureBenefits"></span>
    <span class="lh-l" translate="logOnToTheWebsite"></span>
    <span class="lh-l" translate="checkYourEmail"></span>
  </div>
</div>

<div class="du-flex-column gap-m p-m bt-grey">
  <div class="du-flex-column gap-xs">
    <span class="font-l font-400" translate="actionCode"></span>
    <div class="du-flex-justify du-flex-align p-m bg-grey b-radius color-blue" (click)="copyToClipboard()">
      <span>{{ promoCode }}</span>
      <span class="du-flex-center font-xl du-icon-copy"></span>
  </div>
  </div>
  <button class="width-full btn-primary" translate="registerHere" (click)="openBenefitProgram()"></button>
</div>
