<div class="du-flex-column du-flex-1 overflow" *ngIf="entry">
  <div class="du-flex-column gap-m p-m">
    <span class="du-flex-column gap-xxs">
      <span class="font-16 bold" translate="fillInDetails"></span>
      <span class="font-s"       translate="swipeToSeeDays"></span>
    </span>
    <day-selector *ngIf="entry" [useCase]="useCase" [entry]="entry" [activeEntryIndex]="activeEntryIndex" (daySelectCallback)="moveToReport($event)" [tutorialStep]="tutorialStep" [tutorialNumber]="1" [tutorialText]="'canSeeDaysOfWeek' | translate"></day-selector>
  </div>

  <ul class="du-flex gap-xxxl pl-m pr-m transition-left-200" [class.overflow]="!tutorialStep" id="hammerContainer" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" [tutorialStep]="tutorialStep" [tutorialNumber]="3" [tutorialText]="'canSeeDaysActivated' | translate">
    <ng-container *ngIf="useCase !== 'mm'">
      <constructor-daily *ngFor="let daily of activityReport.dailyReports; let i = index"
                          class="du-flex-full"
                         [index]="i"
                         [daily]="daily"
                         [useCase]="useCase"
                         [tutorialStepVal]="tutorialStep"
                         (adjustDaily)="adjustDaily($event)"
                         (validate)="validate()"
                         (resetToDefault)="resetToDefault($event)">
      </constructor-daily>
    </ng-container>

    <ng-container *ngIf="useCase === 'mm'">
      <constructor-work-day *ngFor="let workDay of mileageReport.workDays; let i = index"
                             class="du-flex-full"
                            [index]="i"
                            [workDay]="workDay"
                            (validate)="validate()"
                            (resetToDefault)="resetToDefault($event)">
      </constructor-work-day>
    </ng-container>
  </ul>

</div>

<div class="du-flex-column" *ngIf="entry">
  <div class="du-flex-center pt-m gap-xxs">
    <span class="b-radius-xs height-xs animate-width-bg" *ngFor="let dot of entries; let i = index"
         [ngClass]="activeEntryIndex === i ? 'width-28' : 'width-xs'"
         [class.bg-blue]="activeEntryIndex  === i && dot.placeholder"
         [class.bg-white]="activeEntryIndex !== i && dot.placeholder"
         [class.bg-red]="dot.techErrorsAll.size || dot.dateErrors.size"
         [class.bg-orange]="!(dot.techErrorsAll.size || dot.dateErrors.size) && dot.legalErrorsAll.size"
         [class.bg-green]="!dot.placeholder && !(dot.techErrorsAll.size || dot.dateErrors.size) && !dot.legalErrorsAll.size">
    </span>
  </div>

  <div class="du-flex-column gap-m p-m" [class.bt-grey]="useCase === 't'">
    <div class="du-flex-column gap-s" *ngIf="useCase === 't'">
      <span class="font-500" translate="templateName"></span>
      <input type="text" [(ngModel)]="templateName">
    </div>

    <div class="du-flex-align gap-s pb-xs">
      <button class="du-flex-align gap-xs width-full btn-secondary" (click)="goBack()">
        <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
        <span translate="back"></span>
      </button>
      <button class="width-full btn-primary" (click)="callbackHandler()" [disabled]="readyForSubmit()">
        {{ (useCase === 't' ? templateId ? 'save' : 'create' : 'next') | translate }}
      </button>
    </div>

  </div>
</div>
