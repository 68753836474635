import { Component, OnInit } from '@angular/core';
import { Router            } from '@angular/router';

import { SessionStorageService, DBService, NotificationService } from '@shared/services';
import { DocumentType, PhotoDocumentLocal } from '@shared/factories';
import { DocumentTypeModel } from '@shared/models';

@Component({
  templateUrl: './document-select.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-m bg-grey gap-l' },
})
export class DocumentSelectComponent implements OnInit {
  document: PhotoDocumentLocal;

  types:    DocumentType[] = [];
  tempType: DocumentType;
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('photoDocument');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setProgressBar(null);
    this.notificationService.wait();
    this.dbService.loadAllFromDB('documentTypes')
    .then((res: DocumentTypeModel[]) => {
      this.types = res.map(a => new DocumentType(a));
    })
    .then(() => {
      this.document = this.sessionStorageService.temporaryPhotoDocument;
      this.tempType = this.document?.docType;
      this.notificationService.close();
    });
  }

  confirmType(): void {
    if (this.document) this.document.docType = this.tempType;
    else this.document = new PhotoDocumentLocal({ docType: this.tempType.toJSON() });
    this.sessionStorageService.setTemporaryValue(this.document);
    this.router.navigateByUrl('time-tracking/photo-report-pd');
  }

  goBack(): void {
    if (this.document.id) this.router.navigateByUrl('time-tracking/preselect-pd');
    else                  this.router.navigateByUrl('time-tracking/home');
  }

}