import Dexie from 'dexie';
import {
  ActivityReportLocalModel,
  ActivityReportOverviewModel,
  AssignmentModel,
  BannerModel,
  CustomerEmailModel,
  DailyLocalExtendedModel,
  DocumentTypeModel,
  EBSModel,
  HolidayModel,
  MileageReportLocalModel,
  PhotoDocumentLocalModel,
  PhotoDocumentModel,
  PushNotificationOverviewModel,
  PushNotificationUnreadModel,
  ReleaseModel,
  SalaryReportModel,
  TemplateModel,
  UserModel,
  VacationRequestOverviewModel
} from './models';
import { StandaloneMileageReportOverview, VacationRequestLocal } from './factories';

export class AppDB extends Dexie {
  users!:            Dexie.Table<UserModel>;
  localDailies!:     Dexie.Table<DailyLocalExtendedModel>;
  localReports!:     Dexie.Table<ActivityReportLocalModel>;
  photoReports!:     Dexie.Table<ActivityReportLocalModel>;
  activityReports!:  Dexie.Table<ActivityReportOverviewModel>;
  templates!:        Dexie.Table<TemplateModel>;

  salaryReports!:    Dexie.Table<SalaryReportModel>;
  photoDocuments!:   Dexie.Table<PhotoDocumentModel>;
  localDocuments!:   Dexie.Table<PhotoDocumentLocalModel>;

  customers!:        Dexie.Table<CustomerEmailModel>;
  assignments!:      Dexie.Table<AssignmentModel>;
  ebs!:              Dexie.Table<EBSModel>;

  holidays!:         Dexie.Table<HolidayModel>;
  banners!:          Dexie.Table<BannerModel>;
  documentTypes!:    Dexie.Table<DocumentTypeModel>;

  vacationRequests!: Dexie.Table<VacationRequestOverviewModel>;
  localVacations!:   Dexie.Table<VacationRequestLocal>;

  mileageReports!:   Dexie.Table<StandaloneMileageReportOverview>;
  localMileages!:    Dexie.Table<MileageReportLocalModel>;

  releases!:         Dexie.Table<ReleaseModel>;
  pnOverview!:       Dexie.Table<PushNotificationOverviewModel>;

  unreadPn!:         Dexie.Table<PushNotificationUnreadModel>;
  pendingUpload!:    Dexie.Table<ActivityReportLocalModel | PhotoDocumentLocalModel | VacationRequestLocal>;
  constructor() {
    super('ngdexieliveQuery');
    this.version(1).stores({
      users:            'id',
      localDailies:   '++id, userId',
      localReports:   '++id, userId',
      photoReports:   '++id, userId',
      activityReports:  'id, userId',
      templates:      '++id, userId',

      salaryReports:    'id, userId',
      photoDocuments:   'id, userId',
      localDocuments: '++id, userId',

      customers:        'id, userId',
      assignments:      'id, userId',
      ebs:              'id, userId, assignment_id',

      holidays:       '++id, state_iso',
      banners:          'id, userId',
      documentTypes:    'name',

      vacationRequests: 'id, userId',
      localVacations: '++id, userId',

      mileageReports:   'id, userId',
      localMileages:  '++id, userId',

      releases:         'id, userId',
      pnOverview:       'id, userId',

      unreadPn:         'id, userId',
      pendingUpload:  '++id, userId, useCase',
    });
  }

}

export const db = new AppDB();
