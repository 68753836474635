import { ValidationError, DateError, TechnicalError, LegalError } from "@shared/factories";

export function ErrorsHandlerMixin<Base extends new (...args: any[]) => {}>(Base: Base) {
  return class extends Base {
    childrenName: string;
    errors:       ValidationError[];
    constructor(...args: any[]) {
      super(...args);
      this.errors = [];
    }

    checkError(inputFieldName: string): ValidationError { return this.errors.find(e => e.inputFieldName === inputFieldName); }

    get errorsAll(): Set<string> { return new Set([...this.techErrorsAll, ...this.legalErrorsAll]); }

    get techErrorsAll():  Set<string> { return new Set([...this.techErrors,  ...this.childrenTechErrors]);  }
    get legalErrorsAll(): Set<string> { return new Set([...this.legalErrors, ...this.childrenLegalErrors]); }

    get dateErrors():  Set<string> { return new Set(this.errors.filter(err => err instanceof DateError).map(err => err.message)); }
    get techErrors():  Set<string> { return new Set(this.errors.filter(err => err instanceof TechnicalError).map(err => err.message)); }
    get legalErrors(): Set<string> { return new Set(this.errors.filter(err => err instanceof LegalError    ).map(err => err.message)); }

    get childrenErrors(): ValidationError[] { return this.childrenName ? ((this as any)[this.childrenName] as any[]).reduce((errors, p) => errors = [...errors, ...p.errors], []) : []; }

    get childrenTechErrors():  Set<string>  { return this.childrenName ? new Set(((this as any)[this.childrenName] as any[]).reduce((errors, p) => errors = [...errors, ...p.techErrors],  [])) : new Set(); }
    get childrenLegalErrors(): Set<string>  { return this.childrenName ? new Set(((this as any)[this.childrenName] as any[]).reduce((errors, p) => errors = [...errors, ...p.legalErrors], [])) : new Set(); }
  }

}