<div class="du-flex-column pt-xs">
  <ng-container *ngFor="let el of sidebarIcons; let i = index">
    <ng-container *ngTemplateOutlet="sidebarItem; context: { el: el, index: i, arrayLength: sidebarIcons.length, onclick: this.elCallback.bind(this) }"></ng-container>
  </ng-container>
</div>

<div class="du-flex-column pb-m">
  <ng-container *ngFor="let el of bottomIcons; let i = index">
    <ng-container *ngTemplateOutlet="sidebarItem; context: { el: el, index: i, arrayLength: bottomIcons.length, onclick: this.elCallback.bind(this) }"></ng-container>
  </ng-container>
</div>

<ng-template #sidebarItem let-el="el" let-index="index" let-arrayLength="arrayLength" let-onclick="onclick">
  <span class="du-flex-justify pl-m pr-m pt-s pb-s font-500"
        [class.hide]="el.hide"
        [class.bb-grey]="index+1 !== arrayLength"
        [ngClass]="el.class"
        (click)="onclick && onclick(el)">
    <div class="du-flex-align gap-s">
      <img  *ngIf="el.svg"  class="width-35" [src]="'assets/svg/'+el.svg+'.svg'">
      <span *ngIf="el.icon" class="du-flex-center font-xl" [ngClass]="'du-icon-'+el.icon"></span>
      <span *ngIf="el.label">{{ el.label | translate }}</span>
    </div>
    <span *ngIf="el.arrow" class="du-flex-center font-icon color-blue du-icon-arrow-big-right"></span>
  </span>
</ng-template>
