<ng-container *ngIf="mileage">
  <div class="du-flex-column gap-m p-m shadow-header bb-grey">
    <span class="du-flex-align bold font-16" *ngIf="useCase === 'mm-c'">
      <span translate="summary"></span>
      <span>:</span>
    </span>

    <basic-details-header [showNav]="useCase === 'mm'"
                          [startDate]="mileage.startDate"
                          [endDate]="mileage.endDate"
                          [assignmentTitle]="mileage.assignment.title"

                          [disablePrev]="!mileageIndex"
                          [disableNext]=" mileageIndex+1 === cachedReports.length"

                          (goPrev)="goPrevReport()"
                          (goNext)="goNextReport()">
    </basic-details-header>

    <div *ngIf="mileageOverview.splitParentId || mileageOverview.splitChildId" class="du-flex-justify du-flex-align gap-m p-s bg-grey b-radius bold"
          [routerLink]="['/time-tracking/mileage-details', mileageOverview.splitParentId || mileageOverview.splitChildId]">
      <span class="du-flex-align gap-xs">
        <span class="du-flex-center font-xl du-icon-split"></span>
        <span class="font-s lh-l du-flex-1" translate="arWasSplit"></span>
      </span>
      <span class="du-flex-center font-xl color-blue du-icon-arrow-big-right"></span>
    </div>
  </div>

  <div class="du-flex-column du-flex-1 gap-s overflow-auto bg-grey p-m">
    <div class="du-flex-column gap-s bg-white p-s b-radius" *ngFor="let workDay of mileage.workDays">
      <div class="du-flex-justify gap-m bg-grey b-radius p-xs font-500">
        <span class="font-16">{{ workDay.date | date: 'EEEE, dd MMMM yyyy' }}</span>
        <span class="roboto">{{ workDay.amountOfKm | distanceFormat }} km</span>
      </div>
      <div class="du-flex-column gap-xxs">
        <span class="font-s" translate="placeOfDeparture"></span>
        <span class="font-400">{{ workDay.externalEmployeeAddress }}</span>
      </div>
      <div class="du-flex-column gap-xxs">
        <span class="font-s" translate="destination"></span>
        <span class="font-400">{{ workDay.assignmentAddress }}</span>
      </div>
    </div>
  </div>

  <div class="du-flex-column gap-m bt-grey mta p-m">
    <div class="du-flex-justify bg-grey p-xs b-radius">
      <span class="du-flex-align gap-xxs">
        <span class="du-flex-align">
          <span translate="totalKm"></span>
          <span>:</span>
        </span>
        <span class="font-500 roboto">{{ mileage.totalKm | distanceFormat }} km</span>
      </span>
      <span class="du-flex-align gap-xxs">
        <span class="du-flex-align">
          <span translate="numOfDays"></span>
          <span>:</span>
        </span>
        <span class="font-500 roboto">{{ mileage.workDays.length }}</span>
      </span>
    </div>

    <div class="du-flex-column gap-m pb-xs">
      <div class="du-flex-column gap-m" *ngIf="useCase === 'mm-c'">
        <button class="du-flex-align gap-xs b-blue width-full btn-secondary" translate="save" (click)="saveMileage()"></button>
        <div class="du-flex-align gap-m">
          <button class="du-flex-align gap-xs b-grey width-full btn-secondary" (click)="goBack()">
            <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
            <span translate="back"></span>
          </button>
          <button class="width-full btn-primary" translate="submit" (click)="submitMileage()"></button>
        </div>
      </div>
      <button *ngIf="useCase === 'mm'" class="color-blue" translate="back" (click)="goBack()"></button>
    </div>
  </div>
</ng-container>
