import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Capacitor } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

import { ActivityReportLocal, PhotoDocumentLocal } from '@shared/factories';
import { NotificationService, SessionStorageService, PhotoDocumentsService, ImageService, ActivityReportsService } from '@shared/services';

@Component({
  templateUrl: './photo-report.component.html',
  host: { class: 'height-full overflow-auto du-flex-column gap-xl du-flex-justify p-m' }
})
export class PhotoReportComponent implements OnInit {
  report:   ActivityReportLocal;
  document: PhotoDocumentLocal;

  attachment: string;
  note:       string;
  useCase:    string;
  constructor(
    private router:                 Router,
    private route:                  ActivatedRoute,
    private imageService:           ImageService,
    private activityReportsService: ActivityReportsService,
    private photoDocumentsService:  PhotoDocumentsService,
    private notificationService:    NotificationService,
    private sessionStorageService:  SessionStorageService
  ) {}
  
  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    if (this.useCase === 'pr') this.preparePhotoReport();
    if (this.useCase === 'pd') this.preparePhotoDocument();
  }

  private preparePhotoReport(): void {
    this.sessionStorageService.setHeaderTitle('photoReport');
    this.sessionStorageService.setProgressBar(3, 3);
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });

    let report = this.sessionStorageService.temporaryActivityReport;
    if (!report || !report.assignment || !report.startDate) this.router.navigate(['time-tracking/home']);
    else {
      this.report     = report;
      this.attachment = this.report?.attachment;
      this.note       = this.report?.externalNote;
    }
  }

  private preparePhotoDocument(): void {
    this.sessionStorageService.setHeaderTitle('photoDocument');
    this.sessionStorageService.setProgressBar(null);

    let document = this.sessionStorageService.temporaryPhotoDocument;
    if (!document || !document.docType) this.router.navigate(['time-tracking/home']);
    else {
      this.document   = document;
      this.attachment = this.document?.attachment;
      this.note       = this.document?.messageExternal;
    }
  }

  takePicture(): void {
    this.notificationService.wait();
    if (Capacitor.isNativePlatform()) this.takePictureDevice();
    else this.takePictureWeb();
  }

  private takePictureWeb(): void {
    this.imageService.loadImageXHR('https://picsum.photos/350/500.jpg').subscribe(
      (image: any) => {
        this.attachment = image;
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  private takePictureDevice(): void {
    Camera.getPhoto({
      source: CameraSource.Camera,
      quality: 70,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      correctOrientation: true
    }).then(photo => 'data:image/jpeg;base64,' + photo.base64String)
    .then(image => {
      this.attachment = image;
      this.notificationService.close();
    });
  }

  save(): void {
    if (this.useCase === 'pr') this.savePhotoReport();
    if (this.useCase === 'pd') this.saveDocument();
  }

  submit(): void {
    if (this.useCase === 'pr') this.submitReport();
    if (this.useCase === 'pd') this.submitPhotoDocument();
  }

  private prepareReportForSubmit(): void { 
    this.notificationService.wait();
    this.report.attachment   = this.attachment;
    this.report.externalNote = this.note;
    this.report.dailyReports = [];
    this.report.setSignedByCustomer();
  }

  private savePhotoReport(): void {
    this.prepareReportForSubmit();
    this.activityReportsService.savePhotoReport(this.report).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private submitReport(): void {
    this.prepareReportForSubmit();
    this.activityReportsService.submitActivityReport(this.report).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private prepareDocumentForSubmit(): void {
    this.notificationService.wait();
    this.document.attachment      = this.attachment;
    this.document.messageExternal = this.note;
  }

  private saveDocument(): void {
    this.prepareDocumentForSubmit();
    this.photoDocumentsService.savePhotoDocument(this.document).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private submitPhotoDocument(): void {
    this.prepareDocumentForSubmit();
    this.photoDocumentsService.submitPhotoDocument(this.document).subscribe(
      res => this.notificationService.close(),
      err => this.notificationService.alert(err)
    );
  }

  private goBack(): void {
    if (this.useCase === 'pr') this.router.navigateByUrl('/time-tracking/week-select-pr');
    if (this.useCase === 'pd') this.router.navigateByUrl('/time-tracking/document-select');
  }

}
