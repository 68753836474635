<div class="du-flex-justify du-flex-align-start p-m bb-grey">
  <span class="font-500 lh-l"> {{ document.docType.name }}</span>
  <img src="assets/svg/close.svg" (click)="deleteDocumentPrompt()">
</div>

<div class="du-flex-column gap-s p-m">
  <div class="du-flex-justify color-blue font-s" (click)="collapsed = !collapsed">
    <span *ngIf="!collapsed" translate="showMore"></span>
    <span *ngIf=" collapsed" translate="showLess"></span>
    <img  src="assets/svg/arrow-down-blue.svg" [class.rotate]="collapsed">
  </div>
  <div class="du-flex-wrap gap-s" *ngIf="collapsed">
    <div class="width-130 b-radius overflow relative animate-width" [class.width-full]="extendImage">
      <img class="width-full" [src]="document.attachment">
      <img class="absolute bottom-0 right-0 mr-m mb-m" src="assets/svg/expand-blue.svg" (click)="extendImage = !extendImage">
    </div>
    <div class="du-flex-column gap-xs font-s">
      <span translate="noteToManager"></span>
      <span class="lh-l">{{ document.messageExternal || '-' }}</span>
    </div>
  </div>
</div>

<div class="du-flex gap-l bt-grey p-m">
  <button class="width-full btn-secondary b-grey" translate="edit"   (click)="editDocument()"></button>
  <button class="width-full btn-primary"          translate="submit" (click)="submitDocumentPrompt()"></button>
</div>
