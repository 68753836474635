<div class="du-flex-justify shadow-header ml-m mr-m bg-white b-radius" [routerLink]="['/time-tracking/constructor-t']">
  <span class="du-flex-align du-flex-1 gap-xs pt-s pb-s pl-m pr-m">
    <span class="du-flex-center font-xl du-icon-template"></span>
    <span class="font-500" translate="createTemplate"></span>
  </span>
  <span class="du-flex-center pl-s pr-s du-icon-arrow-big-right color-blue font-xl"></span>
</div>

<div class="du-flex gap-xxxl pl-m pr-m overflow transition-left-200" id="hammerContainer" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()" *ngIf="templates && templates.length">
  <template-item *ngFor="let template of templates; let i = index"
                [template]="template">
  </template-item>
</div>

<div class="du-flex-center gap-xxs" *ngIf="templates && templates.length">
  <span class="b-radius-xs height-xs animate-width-bg" *ngFor="let dot of templates; let i = index"
       [ngClass]="activeTemplate === i ? 'width-28' : 'width-xs'"
       [class.bg-blue]="activeTemplate  === i"
       [class.bg-white]="activeTemplate !== i">
  </span>
</div>
