import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { SubscriptionLike } from 'rxjs';

import { SessionStorageService } from '@shared/services';
import { ProgressModel } from '@shared/models';

@Component({
  selector:      'progress-bar',
  templateUrl: './progress-bar.component.html',
  host: { class: 'du-flex-align gap-xs pl-m pr-m bg-white z-1 overflow animate-height' }
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  subscription:  SubscriptionLike;
  progressState: ProgressModel;
  @HostBinding('style.height.px') height = '0';
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.subscription = this.sessionStorageService.progressBar.subscribe(bar => {
      if (bar?.progress) this.height = '20';
      else               this.height = '0';

      if (this.progressState && (!bar || !bar.progress)) setTimeout(() => this.progressState = bar, 200);
      else this.progressState = bar;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.sessionStorageService.setProgressBar(null);
  }

  get progressRange(): number[] {
    let temp = [];
    if (this.progressState) {
      for (let i = 0; i < this.progressState.range; i++) { temp.push(i); }
      return temp;
    }
    return null;
  }

}
