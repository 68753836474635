import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { from, map, of, switchMap } from 'rxjs';

import { PhotoDocument, PushNotificationUnread, PhotoDocumentLocal } from '@shared/factories';
import { NotificationService, SessionStorageService, PhotoDocumentsService, DBService } from '@shared/services';

@Component({
  selector:      'documents-overview',
  templateUrl: './documents-overview.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class DocumentsOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;

  documents: (PhotoDocument | PhotoDocumentLocal)[] = [];
  unreadPn:   PushNotificationUnread[]              = [];
  constructor(
    private dbService:             DBService,
    private photoDocumentsService: PhotoDocumentsService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadDocuments.bind(this));
    this.notificationService.wait();
    from(this.dbService.loadMultipleFromDB('unreadPn')).subscribe(
      pns => {
        this.unreadPn = pns.map(pn => new PushNotificationUnread(pn)).filter(e => e.sourceType === 'photo_documents');
        this.loadDocuments();
      }
    );
  }

  loadDocuments(reload: boolean = null, page: number = null): void {
    this.notificationService.wait();
    this.photoDocumentsService.loadPhotoDocuments(page).pipe(
      switchMap(documents => {
        if (page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'pd' })).pipe(
          map(pendingPd => pendingPd.map(vr => new PhotoDocumentLocal(vr))),
          map(pendingPd => [...pendingPd, ...documents])
        );
        else return of(documents);
      })
    ).subscribe(
      documents => {
        if (reload) this.documents = [...documents];
        else this.documents = [...this.documents, ...documents];
        this.sessionStorageService.setCachedEntries(this.documents);
        this.notificationService.close();
      },
      err => this.notificationService.alert(err),
      () => this.sessionStorageService.setPulltoRefreshState(true)
    );
  }

}
