<div class="du-flex-justify du-flex-align bb-grey">
  <span class="du-flex-center du-icon-arrow-big-left color-blue font-xl p-s mra" (click)="close()"></span>
  <span class="absolute left-70 right-70 du-flex-center color-grey bold">{{ template.name }}</span>
</div>

<ul class="du-flex-column gap-m p-m overflow-auto">
  <li class="du-flex-column gap-xs" *ngFor="let daily of template.dailies; let i = index">
    <span class="bold">{{ daily.startsAt | date: 'EEEE' }}</span>
    <div class="du-flex-align-start">
      <div class="du-flex-column gap-xxs width-120">
        <span class="font-s" translate="workingHours"></span>
        <span class="bold roboto">{{ daily.startsAt | date: 'HH:mm' }} - {{ daily.endsAt | date: 'HH:mm' }}</span>
      </div>
      <div class="du-flex-column du-flex-1 gap-xxs">
        <span class="du-flex-align gap-xxs font-s">
          <span translate="breaks"></span>
          <span *ngIf="daily.pauses && daily.pauses.length">({{ daily.pauses.length }})</span>
        </span>
        <span class="du-flex-wrap gap-xxs bold">
          <span class="roboto" *ngFor="let pause of daily.pauses">
            {{ pause.start | date: 'HH:mm' }} - {{ pause.end | date: 'HH:mm' }}
          </span>
        </span>
      </div>
    </div>
  </li>
</ul>

<div class="du-flex-column gap-l p-m bt-grey">
  <span class="lh-l font-500" translate="wantUseThisTemplate"></span>
  <div class="du-flex-align gap-m">
    <button class="width-full btn-secondary b-grey" translate="no"  (click)="close()"></button>
    <button class="width-full btn-primary"          translate="yes" (click)="useTemplate()"></button>
  </div>
</div>
