<basic-overview-item [startDate]="report.startDate"
                     [endDate]="report.endDate"
                     [stateColor]="getStateColor()"

                     [notSynced]="getLocalReport().notSynced"
                     [createdByTempton]="getOverviewReport().createdByTempton"
                     [signer]="!!getOverviewReport().customerSignature"

                     [assignmentTitle]="report.assignment && report.assignment.title"
                     [bottomIcons]="getBottomIcons()">
</basic-overview-item>
