import { Injectable       } from "@angular/core";
import { Title            } from "@angular/platform-browser";
import { BehaviorSubject, Observable, take } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { ActivityReportLocal, Assignment, MileageReportLocal, PhotoDocumentLocal, VacationRequestLocal } from "@shared/factories";
import { ChipModel, FilterFlowModel, HeaderControlItemModel, HeaderControlsModel, PagingModel, ProgressModel, SortFlowModel } from "@shared/models";

import { PendingService } from "./pending.service";

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private headerVisibilitySubject = new BehaviorSubject<boolean>(null);
  headerVisibility = this.headerVisibilitySubject.asObservable();

  private headerTitleSubject = new BehaviorSubject<string>(null);
  headerTitle = this.headerTitleSubject.asObservable();
  titleRaw: string;

  private headerControlsSubject = new BehaviorSubject<HeaderControlsModel>(null);
  headerControls = this.headerControlsSubject.asObservable();

  private overflowStackSubject = new BehaviorSubject<string[]>(null);
  overflowStack = this.overflowStackSubject.asObservable();

  private progressBarSubject = new BehaviorSubject<ProgressModel>(null);
  progressBar = this.progressBarSubject.asObservable();

  private cachedEntriesSubject = new BehaviorSubject<any[]>(null);
  cachedEntries = this.cachedEntriesSubject.asObservable();

  private temporaryReportSubject      = new BehaviorSubject<ActivityReportLocal | PhotoDocumentLocal | VacationRequestLocal | MileageReportLocal>(null);
  private temporaryAssignmentsSubject = new BehaviorSubject<Assignment[]>(null);

  private pagingSubject     = new BehaviorSubject<PagingModel>(null);
  private sortFlowSubject   = new BehaviorSubject<SortFlowModel>(null);
  private filterFlowSubject = new BehaviorSubject<FilterFlowModel>(null);
  private searchFlowSubject = new BehaviorSubject<string>(null);
  private tabFlowSubject    = new BehaviorSubject<string>(null);

  paging     = this.pagingSubject.asObservable();
  sortFlow   = this.sortFlowSubject.asObservable();
  search     = this.searchFlowSubject.asObservable();
  filterFlow = this.filterFlowSubject.asObservable();
  tabFlow    = this.tabFlowSubject.asObservable();

  private dynamicComponentSource = new BehaviorSubject<any>(null);
  dynamicComponent = this.dynamicComponentSource.asObservable();

  private chipSource = new BehaviorSubject<ChipModel>(null);
  chip = this.chipSource.asObservable();

  private offlineModeSource = new BehaviorSubject<boolean>(null);
  offlineMode = this.offlineModeSource.asObservable();

  private storeUrlSource = new BehaviorSubject<string>(null);
  storeUrl = this.storeUrlSource.asObservable();

  private syncDataSource = new BehaviorSubject<boolean>(null);
  syncData = this.syncDataSource.asObservable();

  private pulltoRefreshStateSource = new BehaviorSubject<boolean>(null);
  pulltoRefreshState = this.pulltoRefreshStateSource.asObservable();

  debug:              boolean = false;
  skipCampaignBanner: boolean = false;
  constructor (
    private title:            Title,
    private pendingService:   PendingService,
    private translateService: TranslateService,
  ) { }

  get headerVisibilityValue(): boolean {
    return this.headerVisibilitySubject.value;
  }

  setHeaderVisibility(visible: boolean): void {
    return this.headerVisibilitySubject.next(visible);
  }

  get headerTitleValue(): string {
    return this.headerTitleSubject.value;
  }

  setOnlyHeaderTitle(title: string): void {
    this.titleRaw = title;
    this.translateService.get(title).pipe(take(1)).subscribe((res: string) => {
      this.title.setTitle(res);
      this.headerTitleSubject.next(title);
    });
  }

  setHeaderTitle(title: string): void {
    this.headerVisibilitySubject.next(!!title);
    if (title && title.trim()) this.setOnlyHeaderTitle(title);
    else {
      this.title.setTitle('Tempton Umbrella')
      this.headerTitleSubject.next(null);
    };
  }

  syncHeaderTitle(): void {
    this.translateService.get(this.titleRaw).pipe(take(1)).subscribe((res: string) => this.title.setTitle(res));
    return this.headerTitleSubject.next(this.titleRaw);
  }

  get headerControlsValue(): HeaderControlsModel {
    return this.headerControlsSubject.value;
  }

  setHeaderControls(controls: HeaderControlsModel): void {
    if (controls?.leftGoBack) controls.left = this.setHeaderBack(controls.leftGoBack);
    if (controls?.leftGoHome) controls.left = this.setHeaderHome(controls.leftGoHome);
    this.headerControlsSubject.next(controls);
  }

  private setHeaderBack(callback: Function): HeaderControlItemModel[] { return [{ icon: 'arrow-big-left color-blue font-xl', callback: () => callback() }]; }
  private setHeaderHome(callback: Function): HeaderControlItemModel[] { return [{ icon: 'icon-menu  du-flex-center font-xl', callback: () => callback() }]; }

  get overflowStackValue(): string[] {
    return this.overflowStackSubject.value;
  }

  pushOverflowStack(item: string = ''): void {
    let stack = this.overflowStackSubject.value && this.overflowStackSubject.value.length ? this.overflowStackSubject.value : [];
    stack.push(item);
    return this.overflowStackSubject.next(stack);
  }

  popOverflowStack(item: string = ''): void {
    let stack = this.overflowStackSubject.value && this.overflowStackSubject.value.length ? this.overflowStackSubject.value : [];
    if (stack.indexOf(item) !== -1) stack.splice(stack.indexOf(item), 1);
    return this.overflowStackSubject.next(stack);
  }

  clearOverflowStack(): void {
    return this.overflowStackSubject.next([]);
  }

  get progressBarValue(): ProgressModel {
    return this.progressBarSubject.value;
  }

  setProgressBar(progress: number, range: number = 0): void {
    return this.progressBarSubject.next({ progress, range: progress && range });
  }

  get cachedEntriesValue(): any[] {
    return this.cachedEntriesSubject.value;
  }

  setCachedEntries(entries: any[]): void {
    return this.cachedEntriesSubject.next(entries);
  }

  get temporaryValue():           ActivityReportLocal  |
                                  PhotoDocumentLocal   |
                                  VacationRequestLocal |
                                  MileageReportLocal   { return this.temporaryReportSubject.value                          }
  get temporaryActivityReport():  ActivityReportLocal  { return this.temporaryReportSubject.value as ActivityReportLocal;  }
  get temporaryPhotoDocument():   PhotoDocumentLocal   { return this.temporaryReportSubject.value as PhotoDocumentLocal;   }
  get temporaryVacationRequest(): VacationRequestLocal { return this.temporaryReportSubject.value as VacationRequestLocal; }
  get temporaryMileageReport():   MileageReportLocal   { return this.temporaryReportSubject.value as MileageReportLocal;   }
  setTemporaryValue(value:        ActivityReportLocal  |
                                  PhotoDocumentLocal   |
                                  VacationRequestLocal |
                                  MileageReportLocal): void { this.temporaryReportSubject.next(value); }

  get temporaryValueObservable():           Observable<ActivityReportLocal  |
                                                       PhotoDocumentLocal   |
                                                       VacationRequestLocal |
                                                       MileageReportLocal>   { return this.temporaryReportSubject.asObservable();                                     }
  get temporaryActivityReportObservable():  Observable<ActivityReportLocal>  { return this.temporaryReportSubject.asObservable() as Observable<ActivityReportLocal>;  }
  get temporaryPhotoDocumentObservable():   Observable<PhotoDocumentLocal>   { return this.temporaryReportSubject.asObservable() as Observable<PhotoDocumentLocal>;   }
  get temporaryVacationRequestObservable(): Observable<VacationRequestLocal> { return this.temporaryReportSubject.asObservable() as Observable<VacationRequestLocal>; }
  get temporaryMileageReportObservable():   Observable<MileageReportLocal>   { return this.temporaryReportSubject.asObservable() as Observable<MileageReportLocal>;   }

  get temporaryAssignments():          Assignment[] { return this.temporaryAssignmentsSubject.value;             }
  setTemporaryAssignments(assignments: Assignment[]): void { this.temporaryAssignmentsSubject.next(assignments); }

  get pagingValue():     PagingModel     { return this.pagingSubject.value;     }
  get sortFlowValue():   SortFlowModel   { return this.sortFlowSubject.value;   }
  get filterFlowValue(): FilterFlowModel { return this.filterFlowSubject.value; }
  get tabFlowValue():    any             { return this.tabFlowSubject.value;    }

  setPaging(    paging:     PagingModel    ): void { this.pagingSubject.next(paging);         }
  setSortFlow(  sortFlow:   SortFlowModel  ): void { this.sortFlowSubject.next(sortFlow);     }
  setFilterFlow(filterFlow: FilterFlowModel): void { this.filterFlowSubject.next(filterFlow); }
  setSearchFlow(searchFlow: string         ): void { this.searchFlowSubject.next(searchFlow); }
  setTabFlow(   tabFlow:    any            ): void { this.tabFlowSubject.next(tabFlow);       }

  changeDynamicComponents(comp: any): void {
    this.dynamicComponentSource.next(comp);
  }

  pushDynamicComponent(item: any): void {
    return this.dynamicComponentSource.next(Object.assign(item, { type: 'create' }));
  }

  popDynamicComponent(item: string): void {
    return this.dynamicComponentSource.next({
      component: item,
      type:     'remove'
    });
  }

  setDebug(debug: boolean): void {
    this.debug = debug;
  }

  setChip(chip: ChipModel): void {
    return this.chipSource.next(chip);
  }

  get offlineModeValue(): any {
    return this.offlineModeSource.value;
  }

  setOfflineMode(offline: boolean): void {
    if (!offline) this.pendingService.syncPendingList().subscribe();
    return this.offlineModeSource.next(offline);
  }

  get storeUrlValue(): string {
    return this.storeUrlSource.value;
  }

  setStoreUrl(url: string): void {
    return this.storeUrlSource.next(url);
  }

  triggerSyncData(): void {
    return this.syncDataSource.next(true);
  }

  setPulltoRefreshState(state: boolean): void {
    return this.pulltoRefreshStateSource.next(state);
  }

}
