<vacations-overview-item [vacation]="vacation" [extended]="true" (close)="close()"></vacations-overview-item>

<div class="du-flex-column gap-m pt-m pl-m pr-m pb-xl bt-grey" *ngIf="vacation.reason || vacation.externalComment || vacation.internalReview || vacation.archivedBySystem">
  <div class="du-flex-justify gap-m">
    <div class="du-flex-column gap-m">
      <div class="du-flex-column gap-xxs mra" *ngIf="vacation.reason">
        <span class="font-s" translate="reason"></span>    
        <span class="bold">{{ (vacation.reason | translate) || vacation.externalComment }}</span>
      </div>
      <div class="du-flex-column du-flex-align-start gap-xxs" *ngIf="vacation.internalReview || vacation.archivedBySystem">
        <span *ngIf="vacation.internalRejectionReason" class="font-s" translate="rejectionReason"></span>
        <span *ngIf="vacation.internalRejectionReason" class="bold">{{ vacation.internalRejectionReason | translate }}</span>
        <span *ngIf="vacation.archivedBySystem"        class="bold">{{ 'archivedBySystem' | translate }}</span>
      </div>
    </div>
    <div class="du-flex-column du-flex-align-end gap-xxs mla mta">
      <span class="du-flex-align gap-xxs" *ngIf="vacation.internalReviewedAt">
        <span translate="at"></span>
        <span>{{ vacation.internalReviewedAt | date: 'HH:mm' }}</span>
        <span class="bold">{{ vacation.internalReviewedAt | date: 'dd.MM.yyyy' }}</span>
      </span>
      <span class="du-flex-align gap-xxs" *ngIf="vacation.reviewedByInternal">
        <span translate="by"></span>
        <span class="bold">{{ vacation.reviewedByInternal.first_name }} {{ vacation.reviewedByInternal.last_name }}</span>
      </span>
    </div>
  </div>

  <div class="du-flex-column gap-xxs" *ngIf="vacation.internalReviewComment">
    <span class="font-s" translate="comment"></span>
    <span class="bold">{{ vacation.internalReviewComment }}</span>
  </div>
</div>
