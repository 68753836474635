import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { forkJoin, from, map, of, switchMap } from 'rxjs';

import { PushNotificationUnread, VacationRequestLocal, VacationRequestOverview } from '@shared/factories';
import { DBService, NotificationService, PushNotificationsService, SessionStorageService, VacationRequestsService } from '@shared/services';

@Component({
  selector:      'vacations-overview',
  templateUrl: './vacations-overview.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class VacationsOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;

  vacations: (VacationRequestLocal | VacationRequestOverview)[] = [];
  unreadPn:   PushNotificationUnread[]                          = [];
  constructor(
    private dbService:                DBService,
    private vacationRequestsService:  VacationRequestsService,
    private pushNotificationsService: PushNotificationsService,
    private notificationService:      NotificationService,
    private sessionStorageService:    SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadVacations.bind(this));

    this.notificationService.wait();
    from(this.dbService.loadMultipleFromDB('unreadPn')).subscribe(
      pns => {
        this.unreadPn = pns.map(pn => new PushNotificationUnread(pn)).filter(e => e.sourceType === 'vacation_request');
        this.loadVacations();
      }
    );
  }

  loadVacations(reload: boolean = null, page: number = null): void {
    this.notificationService.wait();
    this.vacationRequestsService.loadVacations(page).pipe(
      switchMap(vacations => {
        if (page === 1) return from(this.dbService.loadMultipleFromDB('pendingUpload', { useCase: 'vr' })).pipe(
          map(pendingVr => pendingVr.map(vr => new VacationRequestLocal(vr))),
          map(pendingVr => [...pendingVr, ...vacations])
        );
        else return of(vacations);
      })
    ).subscribe(
      vacations => {
        if (reload) this.vacations = [...vacations];
        else this.vacations = [...this.vacations, ...vacations];
        this.notificationService.close();
        this.setPnAsRead();
      },
      err => this.notificationService.alert(err),
      ()  => this.sessionStorageService.setPulltoRefreshState(true)
    );
  }

  openVacationDetails(vacation: VacationRequestOverview | VacationRequestLocal): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'VacationDetails',
      props: { vacation }
    });
  }

  private setPnAsRead(): void {
    let ids = this.unreadPn.filter(pn => this.vacations.find(vr => pn.sourceId === vr.id)).map(pn => pn.id);
    forkJoin(ids.map(id => this.pushNotificationsService.markAsRead(id))).pipe(
      switchMap(() => forkJoin(ids.map(id => this.dbService.deleteOneFromDB('unreadPn', id))))
    ).subscribe(
      ()  => {},
      err => this.notificationService.alert(err)
    );
  }

}
