import { Component, OnInit } from '@angular/core';
import { take              } from 'rxjs';

import { NotificationService, UserService } from '@shared/services';
import { UserDevice                       } from '@shared/factories';

@Component({
  selector:      'profile-devices',
  templateUrl: './profile-devices.component.html',
  host: { class: 'height-full du-flex-column gap-m p-m bg-grey' }
})
export class ProfileDevicesComponent implements OnInit {
  devices: UserDevice[];
  constructor(
    private userService:         UserService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.userService.loadUserDevices().pipe(take(1)).subscribe(
      devices => this.devices = devices.sort((a,b) => b.lastConnectedAt.getTime() - a.lastConnectedAt.getTime()),
      err     => this.notificationService.alert(err)
    );
  }

  getDeviceType(device: UserDevice): string {
    if (device.platform === 'android')      return 'du-icon-smartphone';
    if (device.model.includes('iPhone'))    return 'du-icon-smartphone';
    if (device.model.includes('iPad'))      return 'du-icon-tablet';
    if (device.model.includes('Macintosh')) return 'du-icon-laptop';
    return 'du-icon-device';
  }

  toggleDevice(device: UserDevice): void {
    this.notificationService.confirm({
      title: 'disconnectDevice',
      desc: 'sureDisconnectDevice',
      rightFn: () => console.log('disconect'),
      buttons: {
        left:  'back',
        right: 'Disconnect'
      },
      buttonsStyle: {
        right: 'btn-reject'
      }
    });
  }

}
