import { SortModel } from "@shared/models";

export const ar: SortModel[] = [
  { label: 'latest', column: 'start_date',    active: true },
  { label: 'newest', column: 'start_date',       dir: true },
  { label: 'A-Z',    column: 'assignment_title'            },
  { label: 'Z-A',    column: 'assignment_title', dir: true },
];

export const mm: SortModel[] = [
  { label: 'latest',  column: 'start_date', active: true },
  { label: 'closest', column: 'start_date',    dir: true },
];

export const vr: SortModel[] = [
  { label: 'latest',              column: 'starts_on',     active: true },
  { label: 'closest',             column: 'starts_on',        dir: true },
  { label: 'Vacation Type A-Z',   column: 'leave_type'                  },
  { label: 'Vacation Type Z-A',   column: 'leave_type',       dir: true },
  { label: 'Assignmant Name A-Z', column: 'assignment_title'            },
  { label: 'Assignmant Name Z-A', column: 'assignment_title', dir: true }
];

export const pd: SortModel[] = [
  { label: 'latest',       column: 'created_at', active: true },
  { label: 'alphabetical', column: 'full_name'                },
];

export const notifications: SortModel[] = [
  { label: 'latest',       column: 'created_at', active: true },
  { label: 'alphabetical', column: 'name'       },
  { label: 'underReview',  column: '!approved'  },
  { label: 'reviewed',     column: 'approved'   },
];
