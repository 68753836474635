export default (userName: string) => [
  { icon:  'tutorial',  label: 'tutorials'                                     },
  { icon:  'mileage',   label: 'mileageMoney',  path: 'mileage-config'         },
  { icon:  'user-plus', label: 'referAFriend',  path: 'refer-a-friend'         },
  { icon:  'euro',      label: 'benefits',      path: 'employee-benefits'      },
  { icon:  'tutorial',  label: 'visitWebsite'                                  },
  { icon:  'bell',      label: 'notifications', path: 'notifications'          },
  { icon:  'question',  label: 'helpSupport',   path: 'support'                },
  { icon:  'logout',    label: 'logout',        class: 'color-red'             },

  { icon:  'releases',  label: 'releases',      path: 'releases', bottom: true },
  { icon:  'legal',     label: 'legal',         path: 'legal',    bottom: true },
  { icon:  'bug',       label: 'debug',         path: 'debug',    bottom: true, hide: true },
  { class: 'p-m',       label: 'lang',          arrow: true,      bottom: true }
];
