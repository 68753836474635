<span class="du-flex-center font-xl pl-xs pr-xs b-grey b-radius du-icon-more" (click)="toggleOptions($event)"></span>
<div class="absolute right-0 top-0 mt-xl du-flex-column b-grey bg-white font-s z-1" *ngIf="optionsOpen">
  <span class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s bb-grey hover-active" (click)="editItem($event)">
    <span class="du-flex-center font-l du-icon-edit"></span>
    <span translate="edit"></span>
  </span>
  <span class="du-flex-align gap-xs pt-xs pb-xs pl-s pr-s hover-active" (click)="deleteItem($event)">
    <span class="du-flex-center font-l du-icon-delete"></span>
    <span translate="delete"></span>
  </span>
</div>
