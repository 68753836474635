import { UserDeviceModel } from "@shared/models";
import { FormatService } from "@shared/services";

export class UserDevice {
  id:              number;
  uuid:            string;
  manufacturer:    string;
  model:           string;
  platform:        string;

  lockedByUserAt:  Date;
  lastConnectedAt: Date;
  constructor(data: UserDeviceModel) {
    this.id              = data.id           ? data.id           : null;
    this.uuid            = data.uuid         ? data.uuid         : null;
    this.manufacturer    = data.manufacturer ? data.manufacturer : null;
    this.model           = data.model        ? data.model        : null;
    this.platform        = data.platform     ? data.platform     : null;

    this.lockedByUserAt  = FormatService.parseDate(data.locked_by_user_at);
    this.lastConnectedAt = FormatService.parseDate(data.last_connected_at);
  }

  toJSON(): UserDeviceModel {
    return {
      id:                this.id           ? this.id           : null,
      uuid:              this.uuid         ? this.uuid         : null,
      manufacturer:      this.manufacturer ? this.manufacturer : null,
      model:             this.model        ? this.model        : null,
      platform:          this.platform     ? this.platform     : null,

      locked_by_user_at: FormatService.parseDateISO(this.lockedByUserAt),
      last_connected_at: FormatService.parseDateISO(this.lastConnectedAt)
    };
  }

}
