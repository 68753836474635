<div class="du-flex-column du-flex-1 overflow-auto">

  <div class="du-flex-column p-m gap-m">
    <span class="font-16 font-500" translate="vacationType"></span>
    <ul class="du-flex-column gap-s" *ngIf="vacationTypes && vacationTypes.length">
      <li *ngFor="let type of vacationTypes" (click)="selectLeaveType(type)"
           class="du-flex-justify du-flex-align du-flex-max-content lh-l b-radius pl-m pr-m pt-s pb-s font-400 hover-active shadow-header"
          [class.bg-white]="leaveType !== type"
          [class.bg-blue]="leaveType === type"
          [class.color-white]="leaveType === type">
        {{ type | translate }}
      </li>
    </ul>
  </div>

  <div class="du-flex-column p-m gap-m" @collapse *ngIf="leaveType === 'special' || leaveType === 'unpaid'">
    <div class="du-flex-column gap-s overflow">
      <span class="bold" translate="reason"></span>
      <div class="du-flex-column gap-m" @collapse *ngIf="leaveType === 'special' || leaveType === 'unpaid'">
        <div class="du-flex-column" @collapse *ngIf="leaveType === 'special'">
          <ul class="du-flex-column gap-m overflow">
            <li *ngFor="let vReason of vacationReasons" (click)="selectReason(vReason)"
                 class="du-flex-justify du-flex-align du-flex-max-content lh-l b-radius pl-m pr-m pt-s pb-s font-400 hover-active shadow-header"
                [class.bg-white]="reason !== vReason"
                [class.bg-blue]="reason === vReason"
                [class.color-white]="reason === vReason">
              {{ vReason | translate }}
            </li>
          </ul>
        </div>
        <div class="du-flex-column" @collapse *ngIf="leaveType === 'unpaid'">
          <textarea cols="30" rows="3" class="p-xs b-grey no-resize" placeholder="{{ 'messageToOffice' | translate }}" [(ngModel)]="reason"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="du-flex-column du-flex-justify-end gap-s p-m mt-a">
  <div class="du-flex-column bg-light-blue b-radius p-xs" *ngIf="!closeHint && leaveType === 'special' && reason" @collapse>
    <div class="du-flex-align-start du-flex-justify gap-xs lh-l overflow">
      <span class="du-icon-warning color-blue font-icon"></span>
      <span class="du-flex-1 font-s">
        <span *ngIf="reason === 'wedding_birth'"            translate="weddingBirthHint"></span>
        <span *ngIf="reason === 'death_of_close_relatives'" translate="deathOfCloseRelativesHint"></span>
        <span *ngIf="reason === 'death_of_relatives'"       translate="deathOfRelativesHint"></span>
        <span class="ml-xs nowrap color-orange">({{ reason === 'death_of_close_relatives' ? '2' : '1' }} {{ reason === 'death_of_close_relatives' ? 'days' : 'day' | translate }})</span>
      </span>
      <span class="du-flex-center du-icon-close font-xl" (click)="closeHint = true"></span>
    </div>
  </div>
  <div class="du-flex-align gap-s pb-xs">
    <button class="du-flex-align gap-xs width-full btn-secondary" (click)="goBack()">
      <span class="du-flex-center font-xl du-icon-arrow-big-left"></span>
      <span translate="back"></span>
    </button>
    <button class="width-full btn-primary" translate="next" (click)="confirmVacation()" [disabled]="!readyToSubmit()"></button>
  </div>
</div>
