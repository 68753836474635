<div class="du-flex-column">
  <div class="du-flex-justify du-flex-align gap-xl" *ngIf="index >= 0">
    <span class="du-flex-align gap-xs font-16 bold animate-color" [class.color-red]="!skipErrors && workDay.dateErrors && workDay.dateErrors.size">
      <span>{{ workDay.date | date: 'EEE' }}</span>
      <span>{{ workDay.date | date: 'dd.MM.yyyy' }}</span>
      <span *ngIf="!skipErrors && workDay.dateErrors && workDay.dateErrors.size" class="du-flex-center font-xl du-icon-info"></span>
    </span>
    <span [ngClass]="workDay.placeholder ? 'opacity-0 events-none' : ''" class="du-flex-center font-xl du-icon-close" (click)="resetPlaceholder()"></span>
  </div>

  <div class="du-flex-column pt-xs" *ngIf="!skipErrors && workDay.dateErrors && workDay.dateErrors.size" @collapse>
    <div class="du-flex-column gap-xxs text-xs overflow">
      <span class="color-red" *ngFor="let err of workDay.dateErrors"  @collapse><span class="overflow">{{ err | translate }}</span></span>
    </div>
  </div>
</div>

<div class="du-flex-column gap-s">
  <div class="du-flex-column gap-xs">
    <span class="font-s" translate="placeOfDeparture"></span>
    <input type="text" class="grey width-full" [(ngModel)]="workDay.externalEmployeeAddress" (blur)="validate.emit()" [class.b-red-i]="!skipErrors && workDay.checkError('externalEmployeeAddress')">
  </div>

  <div class="du-flex-column gap-xs">
    <span class="font-s" translate="destination"></span>
    <input type="text" class="grey width-full" [(ngModel)]="workDay.assignmentAddress" (blur)="validate.emit()" [class.b-red-i]="!skipErrors && workDay.checkError('assignmentAddress')">
  </div>

  <div class="du-flex-column gap-xs">
    <span class="font-s" translate="amountOfKm"></span>
    <input type="number" min="1" max="999" pattern="[0-9]+" class="grey width-full" [(ngModel)]="workDay.amountOfKm" (blur)="validateDistance(workDay)" [class.b-red-i]="!skipErrors && workDay.checkError('amountOfKm')">
  </div>

  <div class="du-flex-column" *ngIf="!skipErrors && workDay.techErrors && workDay.techErrors.size" @collapse>
    <div class="du-flex-column gap-xxs text-xs overflow">
      <span class="color-red" *ngFor="let err of workDay.techErrors"  @collapse><span class="overflow">{{ err | translate }}</span></span>
    </div>
  </div>
</div>

<div class="absolute-full du-flex-center b-radius bg-card-grey-light" @fadeIn *ngIf="workDay.placeholder" (click)="addDefaultDay()">
  <span class="du-flex-center circle-75 bg-blue-gradient du-icon-plus color-white font-40"></span>
</div>
