import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PagingModel } from '@shared/models';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'pagination',
  templateUrl: './pagination.component.html',
  host: { class: 'du-flex-align gap-s bg-white bt-grey pl-m pr-m pb-l pt-s' }
})
export class PaginationComponent implements OnInit {
  readonly MAX_PAGES = 9;

  scrollTo:         boolean;
  paging:           PagingModel;
  loadedPages:      number[]           = [];
  paginationPages: (string | number)[] = [];
  @Output() loadNextPage = new EventEmitter<void>;
  @Output() loadPage     = new EventEmitter<number>;
  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.paging.subscribe(paging => this.preparePaging(paging));
    this.sessionStorageService.tabFlow.subscribe(tabs  => this.loadedPages = []);
  }

  private preparePaging(paging: PagingModel): void {
    this.paging = paging;
    if (!this.pageIsActive(paging.current_page)) this.loadedPages.push(paging.current_page);
    this.paginationPages = this.getPaginationButtons(paging);
    if (this.scrollTo) setTimeout(() => this.scrollToPage(paging.current_page));
  }

  pageIsActive(page: number | string): boolean {
    return !!this.loadedPages.find(p => p === page);
  }

  isString(val: unknown): boolean {
    return typeof val === 'string';
  }

  private getPaginationButtons(paging: PagingModel): (string | number)[] {
    let buttons = [];
    if (paging.total_pages >= 1 && paging.total_pages <= this.MAX_PAGES) for (let i = 1; i <= paging.total_pages; i++) { buttons.push(i); }
    else if (paging.total_pages >= this.MAX_PAGES+1) {
      buttons.push(1);

      if (paging.current_page <= 5) for (let i = 2; i <= this.MAX_PAGES-2; i++) { buttons.push(i); }
      else buttons.push(`paging-left du-icon-more font-xl ${this.loadedPages.length > 3 ? 'bg-blue color-white' : 'bg-grey'}`);

      if (paging.current_page >= 6 && paging.current_page + 4 < paging.total_pages) {
        buttons.push(paging.current_page - 2);
        buttons.push(paging.current_page - 1);
        buttons.push(paging.current_page);
        buttons.push(paging.current_page + 1);
        buttons.push(paging.current_page + 2);
      }

      if (paging.current_page + 4 < paging.total_pages) buttons.push('paging-right du-icon-more bg-grey font-xl');
      else for (let i = this.MAX_PAGES-3; i >= 1; i--) { buttons.push(paging.total_pages-i); }
      buttons.push(paging.total_pages);
    }
    return buttons;
  }

  paginate(page: number | string): void {
    if (this.pageIsActive(page)) this.scrollToPage(page as number);
    else {
      if (typeof page === 'string') {
        let index = +this.paginationPages.findIndex(p => p === page);
        let start = +this.paginationPages[index-1];
        let end   = +this.paginationPages[index+1];
        if (page.includes('bg-blue')) {
          let goTo   = Math.ceil(end/2);
          let paging = JSON.parse(JSON.stringify(this.paging));
          paging.current_page = goTo;
          this.sessionStorageService.setPaging(paging);
          this.scrollToPage(goTo);
        } else {
          let goTo = Math.ceil(start+((end-start)/2));
          this.loadPage.emit(goTo);
          this.loadedPages = [];
        }
      } else if (page-1 === this.loadedPages[this.loadedPages.length-1]) {
        this.loadNextPage.emit();
        this.scrollTo = true;
      } else {
        this.loadPage.emit(page);
        this.loadedPages = [];
      }
    }
  }

  private scrollToPage(page: number): void {
    let wrapper  = document.getElementById('pull-to-refresh-container');
    let scrollTo = wrapper.children[(page-1)*50];
    if (scrollTo) scrollTo.scrollIntoView({ block: "start", behavior: "smooth" });
    this.scrollTo = false;
  }

}
