import { Component, OnInit     } from '@angular/core';
import { fadeIn                } from '@shared/animations';
import { ChipModel } from '@shared/models';
import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'chip',
  templateUrl: './chip.component.html',
  host: { class: 'z-3' },
  animations: [ fadeIn ]
})
export class ChipComponent implements OnInit {
  chip:  ChipModel;
  timer: any;
  constructor(private sessionStorageService: SessionStorageService) {}

  ngOnInit(): void {
    this.sessionStorageService.chip.subscribe(chip => {
      clearTimeout(this.timer);
      this.chip  = chip;
      this.timer = setTimeout(() => {
        this.chip = null;
      }, chip.timer*1000 || 2000);
    });
  }

}
