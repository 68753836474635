import { Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import SignaturePad from 'signature_pad';

import { SessionStorageService } from '@shared/services';
import { slideUp               } from '@shared/animations';

@Component({
  selector:      'signature-pad',
  templateUrl: './signature-pad.component.html',
  host: { class: 'height-280 absolute bottom-0 left-0 right-0 du-flex-column btl-radius btr-radius bg-grey z-3' },
  animations: [ slideUp ]
})
export class SignaturePadComponent implements OnInit, OnDestroy {
  signaturePad: SignaturePad;
  @Output() callback = new EventEmitter<string>;
  @HostBinding('@slideUp') get slideIn() { return 'open'; }
  @HostListener('document:resize', ['$event']) resize( e: any) { this.resizeCanvas(); }

  constructor( private sessionStorageService: SessionStorageService ) {}

  ngOnInit(): void {
    this.sessionStorageService.pushOverflowStack('SignaturePad');
    const canvas = document.querySelector("canvas");
    canvas.style.height = 'calc(100% - 16px*2 - 16px - 44px)';
    this.signaturePad = new SignaturePad(canvas);
    this.resizeCanvas();
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack('SignaturePad');
  }

  private resizeCanvas(): void {
    const canvas  = document.querySelector("canvas");
    const ratio   = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width  = canvas.offsetWidth  * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear();
  }

  save(): void {
    this.callback.emit(this.signaturePad.toDataURL());
    this.close();
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent('SignaturePad');
  }

}
