<div class="height-min-fit-content bg-white shadow-header overflow b-radius du-flex-column gap-m p-s" *ngFor="let release of releases">
  <div class="du-flex-column gap-xxs">
    <span *ngIf="release.application === 'mobile'" class="bold" translate="mobile"></span>
    <span *ngIf="release.application === 'mt.api'" class="bold">API</span>
    <div class="du-flex-justify font-s">
      <span>{{ release.releasedAt | date: 'dd.MM.yyyy' }}</span>
      <span>{{ release.version }}</span>
    </div>
  </div>
  <div class="du-flex-column gap-s bold">
    <span translate="notes"></span>
    <ul class="du-flex-column gap-s ml-xl list-disc">
      <li *ngFor="let note of release.parcedNotes" class="lh-l">{{ note }}</li>
    </ul>
  </div>
</div>
