import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router   } from '@angular/router';

import { Template } from '@shared/factories';
import { DBService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'template-item',
  templateUrl: './template-item.component.html',
  host: { class: 'du-flex-column du-flex-full b-radius shadow-header bg-white' }
})
export class TemplateItemComponent {
  @Input()  template: Template;
  @Output() updateCallback = new EventEmitter<void>;
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService
  ) {}

  useTemplate(): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'TemplateDetails',
      props: {
        template: this.template
      }
    });
  }

  editTemplate(): void {
    this.router.navigate(['/time-tracking/constructor-t', this.template.id]);
  }

  deleteTemplatePrompt(): void {
    this.dbService.deleteOneFromDB('templates', this.template.id)
    .then(() => this.updateCallback.emit());
  }

}
