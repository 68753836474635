import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SalaryReport } from '@shared/factories';

import { NotificationService, SalaryReportsService, SessionStorageService } from '@shared/services';

@Component({
  selector:      'salary-overview',
  templateUrl: './salary-overview.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class SalaryOverviewComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  reports:  SalaryReport[] = [];
  constructor(
    private salaryReportsService:  SalaryReportsService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadReports.bind(this));
    this.loadReports();
  }

  loadReports(reload: boolean = null, page: number = null): void {
    this.notificationService.wait();
    this.salaryReportsService.loadSalaryReports(page).subscribe(
      reports => {
        if (reload) this.reports = [...reports];
        else this.reports = [...this.reports, ...reports];
        this.sessionStorageService.setCachedEntries(this.reports);
        this.notificationService.close();
      },
      err => this.notificationService.alert(err),
      () => this.sessionStorageService.setPulltoRefreshState(true)
    );
  }

}
