import { Component, OnInit } from '@angular/core';
import { defer, from, take } from 'rxjs';

import { Template      } from '@shared/factories';
import { TemplateModel } from '@shared/models';
import { DBService, NotificationService } from '@shared/services';

@Component({
  selector:      'templates-box',
  templateUrl: './templates-box.component.html',
  host: { class: 'du-flex-column gap-m pt-m' }
})
export class TemplatesBoxComponent implements OnInit {
  templates:       Template[];
  activeTemplate:  number;

  hammerContainer: HammerManager;
  constructor(
    private dbService:           DBService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    defer(() => from(this.dbService.loadMultipleFromDB('templates'))).pipe(take(1)).subscribe(
      (templates: TemplateModel[]) => {
        this.templates      = templates.map(r => new Template(r));
        this.activeTemplate = 0;
      },
      err => this.notificationService.alert(err)
    );
  }

  ngAfterViewInit(): void {
    this.setupHammerContainer();
  }

  private setupHammerContainer(): void {
    let container = document.getElementById('hammerContainer');
    if (container) this.hammerContainer = new Hammer(container);
  }

  private moveToReport(index: number): void {
    let elementName = 'template-item';
    let el = document.getElementsByTagName(elementName)[index];
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    this.activeTemplate = index;
  }

  swipeLeft(): void {
    this.moveToReport(Math.min(this.activeTemplate+1, 6));
  }

  swipeRight(): void {
    this.moveToReport(Math.max(this.activeTemplate-1, 0));
  }

}
