import { Component, OnInit } from '@angular/core';
import { Router            } from '@angular/router';

import { SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './profile.component.html',
  host: { class: 'height-full du-flex-column' }
})
export class ProfileComponent implements OnInit {
  activeTab: string = 'profile';
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.setHeader();
  }

  private setHeader(): void {
    this.sessionStorageService.setHeaderTitle('yourProfile');
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
  }

  goBack(): void {
    this.router.navigateByUrl(`time-tracking/home`);
  }

}
