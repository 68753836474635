import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, from, of, switchMap, take, tap } from 'rxjs';

import {
  ActivityReportLocal,
  DailyLocal,
  Holiday,
  MileageReportLocal,
  MileageReportPrefill,
  MileageWorkDayBasic,
  MileageWorkDayLocal,
  Template
} from '@shared/factories';

import {
  DBService,
  MileageReportsService,
  NotificationService,
  SessionStorageService,
  UserService,
  ValidationService,
  WeeksService
} from '@shared/services';

import { TemplateModel } from '@shared/models';
import { fadeIn        } from '@shared/animations';

@Component({
  templateUrl: './constructor.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify overflow bg-grey' },
  animations: [ fadeIn ]
})
export class ConstructorComponent implements OnInit, AfterViewInit {
  entry:            ActivityReportLocal | MileageReportLocal;
  entries:          (DailyLocal | MileageWorkDayLocal)[];
  activeEntryIndex: number    = 0;
  holidays:         Holiday[] = [];
  mileagePrefill:   MileageReportPrefill;
  useCase:          string;

  lastScrollPos:    number;
  skipScrollCheck:  boolean;
  hammerContainer:  HammerManager;

  templateId:       number;
  templateName:     string;

  tutorialStep:     number = 0;
  tempDailyState:   boolean;
  @HostListener('document:mousedown', ['$event']) close(event: MouseEvent) {
    if (this.tutorialStep) {
      event.preventDefault();
      event.stopPropagation();
      ++this.tutorialStep;
      if (this.tutorialStep === 3) this.activityReport.dailyReports[this.activeEntryIndex].placeholder = false;
      if (this.tutorialStep   > 6) this.endOnboarding();
    }
  }
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private mileageReportsService: MileageReportsService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private validationService:     ValidationService,
    private weeksService:          WeeksService,
    private userService:           UserService,
    private dbService:             DBService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.prepareHeader();
    if (this.useCase === 't') this.prepareTemplate();
    else this.prepareReport();
  }

  ngAfterViewInit(): void {
    this.setupHammerContainer();
  }

  private setupHammerContainer(): void {
    let container = document.getElementById('hammerContainer');
    if (container) this.hammerContainer = new Hammer(container);
  }

  private prepareTemplate(): void {
    this.route.params.pipe(
      take(1),
      switchMap(params => {
        if (+params['templateId']) return from(this.dbService.loadOneFromDB('templates', +params['templateId']));
        return of(null);
      })
    ).subscribe((template: TemplateModel) => {
      this.entry = new ActivityReportLocal({
        start_date: this.weeksService.getStartOfWeek(+new Date()-7*24*60*60*1000).toISOString(),
        end_date:   this.weeksService.getEndOfWeek(  +new Date()-7*24*60*60*1000).toISOString(),
      });

      this.prepareDefaultDailies(this.activityReport.startDate, this.activityReport.endDate);

      if (template) this.mapTemplate(new Template(template));
    });
    if (!this.userService.checkTutorial('t')) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 't' }
    });
  }

  private mapTemplate(template: Template): void {
    this.templateId   = template.id;
    this.templateName = template.name;

    template.dailies.forEach(d => {
      let day = this.activityReport.dailyReports.find(dr => dr.startsAt.getDay() === d.startsAt.getDay());
      if (day) {
        day.startsAt.setHours(d.startsAt.getHours(), d.startsAt.getMinutes(), d.startsAt.getSeconds(), 0);
        day.endsAt.setHours(  d.endsAt.getHours(),   d.endsAt.getMinutes(),   d.endsAt.getSeconds(),   0);
        day.pauses = d.pauses;
        day.pauses.forEach(p => {
          p.start.setFullYear(day.startsAt.getFullYear());
          p.start.setMonth(day.startsAt.getMonth());
          p.start.setDate(day.startsAt.getDate());
          p.end.setFullYear(day.startsAt.getFullYear());
          p.end.setMonth(day.startsAt.getMonth());
          p.end.setDate(day.startsAt.getDate());
          if (p.end.getTime() < p.start.getTime()) p.end.setDate(p.end.getDate()+1);
        });
        day.project     = d.project;
        day.placeholder = false;
      }
    });
  }

  private prepareReport(): void {
    let report: ActivityReportLocal | MileageReportLocal;
    if (this.useCase === 'mm') report = this.sessionStorageService.temporaryMileageReport;
    else                       report = this.sessionStorageService.temporaryActivityReport;

    if (!report || !report.assignment || !report.startDate) this.goBack();
    else {
      let calls: Observable<any>[] = [this.loadHolidays(report)];
      if (this.useCase === 'mm') calls.push(this.preloadMileageReport(report as MileageReportLocal));

      forkJoin(calls).pipe(
        tap(() => this.prepareDefaults(report)),
        tap(() => this.validate())
      ).subscribe();
    }
  }

  private loadHolidays(report: ActivityReportLocal | MileageReportLocal): Observable<Holiday[]> {
    return from(this.dbService.loadMultipleFromDB('holidays', { state_iso: report.assignment.stateISO })).pipe(
      tap(holidays => this.holidays = holidays.map(h => new Holiday(h)))
    );
  }

  private preloadMileageReport(report: MileageReportLocal): Observable<MileageReportPrefill> {
    let start = new Date(Math.max(new Date(report.assignment.startsAt).getTime(), this.weeksService.getStartOfWeek(report.startDate).getTime()));
    let end   = new Date(Math.min(new Date(report.assignment.endsAt).getTime(),   this.weeksService.getEndOfWeek(  report.startDate).getTime(), this.weeksService.getEndOfWeek(new Date).getTime()));

    return this.mileageReportsService.requestPrebuildMileageReport(start, end, report.assignment.id).pipe(
      tap(mileage => this.mileagePrefill = mileage)
    );
  }

  private prepareDefaults(report: ActivityReportLocal | MileageReportLocal): void {
    this.entry = report;
    if (this.useCase === 'mm') this.prepareMileageReportDefaults();
    else                       this.prepareDefaultDailies(report.startDate, report.endDate);
    this.entries = (this.entry as ActivityReportLocal)?.dailyReports || (this.entry as MileageReportLocal)?.workDays || [];
  }

  moveToReport(index: number): void {
    let elementName = this.useCase === 'mm' ? 'constructor-work-day' : 'constructor-daily';
    let el = document.getElementsByTagName(elementName)[index];
    el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    this.activeEntryIndex = index;
  }

  adjustDaily({ index, daily }: { index: number, daily: DailyLocal }): void {
    this.activityReport.dailyReports[index] = daily;
  }

  resetToDefault(index: number): void {
    if (this.useCase !== 'mm') this.resetDaily(index);
    if (this.useCase === 'mm') this.resetWorkDay(index);
  }

  private resetDaily(index: number): void {
    this.activityReport.dailyReports[index] = this.prepareDefaultDaily(this.activityReport.dailyReports[index].startsAt);
  }

  private resetWorkDay(index: number): void {
    let prefill = this.mileagePrefill.workDays.find(wd => wd.date.getTime() === this.mileageReport.workDays[index].date.getTime());
    this.mileageReport.workDays[index] = this.prepareDefaultMileage(prefill);
  }

  private prepareMileageReportDefaults(): void {
    this.mileageReport.ebsDataId = this.mileagePrefill.ebsDataId;
    this.mileageReport.workDays  = this.mileagePrefill.workDays.map(pwd => {
      let workDay = this.mileageReport.workDays?.find(wd => wd.date.getTime() === pwd.date.getTime());
      return workDay || this.prepareDefaultMileage(pwd);
    });
  }

  private prepareDefaultMileage(mileage: MileageWorkDayBasic): MileageWorkDayLocal {
    let workDay = mileage.toJSON();
    return new MileageWorkDayLocal({
      id:                        null,
      mileage_money_id:          null,

      date:                      workDay.date,
      amount_of_km:              workDay.amount_of_km,

      external_employee_address: workDay.external_employee_address,
      assignment_address:        workDay.assignment_address,
      placeholder:               true
    });
  }

  private prepareDefaultDailies(start: Date, end: Date): void {
    start.setHours(0,0,0,0);
    end.setHours(0,0,0,0);

    let dailies = this.activityReport.dailyReports || [];
    let day = new Date(start);
    do {
      let created = dailies.find(d => {
        let date = new Date(d.startsAt);
        date.setHours(0,0,0,0);
        return date.getTime() === day.getTime();
      })
      if (!created) dailies.push(this.prepareDefaultDaily(day));

      day.setDate(day.getDate() + 1);
    } while (day.getTime() <= end.getTime());

    this.activityReport.dailyReports = dailies.sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime());
  }

  private prepareDefaultDaily(day: Date): DailyLocal {
    day.setHours(0,0,0,0);
    return new DailyLocal({
      placeholder: true,
      mileage:     this.activityReport.mileageStart && this.activityReport.mileageEnd ? day.getTime() >= this.activityReport.mileageStart.getTime() && day.getTime() <= this.activityReport.mileageEnd.getTime() : null,
      holidays:    this.holidays?.filter(h => h.date.getTime() === day.getTime()),
      started_at:  new Date(day.getFullYear(), day.getMonth(), day.getDate(), 8,  0, 0),
      ended_at:    new Date(day.getFullYear(), day.getMonth(), day.getDate(), 16, 0, 0),
      pauses: [{
        id: 1,
        start: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12,  0, 0),
        end:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12, 30, 0),
      }]
    });
  }

  validate(): void {
    this.cleanErrors();
    if (this.useCase !== 'mm') this.validationService.validateAR(this.activityReport);
    if (this.useCase === 'mm') this.validationService.validateMM(this.mileageReport, this.mileagePrefill);
  }

  private cleanErrors(): void {
    if (this.useCase !== 'mm') this.cleanErrorsAR();
    if (this.useCase === 'mm') this.cleanErrorsMM();
  }

  private cleanErrorsAR(): void {
    this.activityReport.dailyReports.forEach(d => {
      d.errors = [];
      if (d.pauses && d.pauses.length) d.pauses.forEach(p => { p.errors = []; });

      d.endsAt.setDate(d.startsAt.getDate());
      d.endsAt.setMonth(d.startsAt.getMonth());
      d.endsAt.setFullYear(d.startsAt.getFullYear());
      if (d.endsAt < d.startsAt) d.endsAt.setDate(d.startsAt.getDate() + 1);
    });
  }

  private cleanErrorsMM(): void {
    this.mileageReport.workDays.forEach(wd => {
      wd.errors = [];
    });
  }

  private prepareHeader(): void {
    if (this.useCase === 'ar' || this.useCase === 'ar-t') {
      this.sessionStorageService.setHeaderTitle('activityReport');
      this.sessionStorageService.setProgressBar(3, 5);
    } else if (this.useCase === 'dr') {
      this.sessionStorageService.setHeaderTitle('combineDailyReports');
      this.sessionStorageService.setProgressBar(null);
    } else if (this.useCase === 't') {
      this.sessionStorageService.setHeaderTitle('templateCreation');
      this.sessionStorageService.setProgressBar(null);
    } else if (this.useCase === 'mm') {
      this.sessionStorageService.setHeaderTitle('mileageMoney');
      this.sessionStorageService.setProgressBar(3, 4);
    }
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
  }

  goBack(): void {
    if (this.useCase === 'ar'  ) this.router.navigateByUrl('time-tracking/preselect-ar');
    if (this.useCase === 'ar-t') this.router.navigateByUrl('time-tracking/week-select-ar-t');
    if (this.useCase === 'dr'  ) this.router.navigateByUrl('time-tracking/daily-overview');
    if (this.useCase === 't'   ) this.router.navigateByUrl('time-tracking/preselect-ar');
    if (this.useCase === 'mm'  ) this.router.navigateByUrl('time-tracking/week-select-mm');
  }

  callbackHandler(): void {
    if (this.useCase === 't') this.saveTemplate();
    else {
      this.sessionStorageService.setTemporaryValue(this.entry);
      this.router.navigateByUrl(`time-tracking/report-confirm-${this.useCase}`);
    }
  }

  private saveTemplate(): void {
    this.notificationService.wait();
    let template = new Template({
      id:      this.templateId,
      name:    this.templateName,
      dailies: this.activityReport.activeDailyReports.map(d => d.toJSON())
    });
    this.dbService.saveOneToDB('templates', template)
    .then(() => {
      if (this.templateId) this.router.navigateByUrl('/time-tracking/preselect-ar');
      else this.router.navigate(['time-tracking/info'], { queryParams: { type: 'save', useCase: 't' }});
      this.notificationService.close();
    })
    .catch(err => this.notificationService.alert(err));
  }

  private startOnboarding(): void {
    this.tutorialStep = 1;
    this.toggleDaysForOnboardingDemo();
    this.tempDailyState = this.activityReport.dailyReports[this.activeEntryIndex].placeholder;
    this.activityReport.dailyReports[this.activeEntryIndex].placeholder = true;
    this.sessionStorageService.pushOverflowStack('TutorialStep');
  }

  private toggleDaysForOnboardingDemo(): void {
    for (let i = 0; i < this.activeEntryIndex; i++) {
      let el = document.getElementsByTagName('constructor-daily')[i];
      el.classList.toggle('hide');
    }
  }

  private endOnboarding(): void {
    setTimeout(() => {
      this.tutorialStep = 0;
      this.toggleDaysForOnboardingDemo();
      this.activityReport.dailyReports[this.activeEntryIndex].placeholder = this.tempDailyState;
    });
    this.sessionStorageService.popOverflowStack('TutorialStep');
  }

  swipeLeft(): void {
    this.moveToReport(Math.min(this.activeEntryIndex+1, 6));
  }

  swipeRight(): void {
    this.moveToReport(Math.max(this.activeEntryIndex-1, 0));
  }

  get activityReport(): ActivityReportLocal { return this.entry as ActivityReportLocal; }
  get mileageReport():  MileageReportLocal  { return this.entry as MileageReportLocal;  }

  readyForSubmit(): boolean {
    if (!this.entry)                                                              return true;
    if (this.useCase !== 'mm' && !this.activityReport.activeDailyReports?.length) return true;
    if (this.useCase === 'mm' && !this.mileageReport.activeWorkDays?.length)      return true;
    if (this.useCase === 't'  && !this.templateName)                              return true;
    if (this.entry.techErrors?.size)                                              return true;
    if (this.useCase === 'mm' && this.mileageReport.dateErrors?.size)             return true;

    return false;
  }

}
