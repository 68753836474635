<div class="du-flex-column du-flex-1 overflow">
  <templates-box *ngIf="useCase === 'ar'"></templates-box>

  <span class="p-m color-primary font-l lh-l bb-grey bold">{{ getTemplateTitle() | translate }}</span>
  <span *ngIf="!entries.length"  class="p-xl text-center">{{ getEmptyEntriesTitle() | translate }}</span>

  <div class="du-flex-column du-flex-1 gap-s p-m overflow-auto">
    <ng-container *ngIf="useCase !== 'pd'">
      <div class="du-flex-column gap-m p-s bg-white b-radius shadow-header" *ngFor="let item of entries">
        <basic-details-header [preselect]="true"
                              [startDate]="getStartDate(item)"
                              [endDate]="getEndDate(item)"
                              [assignmentTitle]="getAssignmentTitle(item)"
                              (editCallback)="goToEdit(item)"
                              (deleteCallback)="deleteEntry(item.id)">
        </basic-details-header>
        <button class="width-full btn-primary" translate="submit" (click)="submitEntry(item)"></button>
      </div>
    </ng-container>

    <ng-container *ngIf="useCase === 'pd'">
      <document-preselect-item *ngFor="let document of photoDocuments"
                               [document]="document"
                               (submitDocument)="submitEntry($event)"
                               (deleteDocument)="deleteEntry($event)">
      </document-preselect-item>
    </ng-container>

  </div>
</div>

<div class="du-flex-column p-m">
  <button class="width-full btn-primary mb-xs" (click)="callbackHandler()">{{ getReportName() | translate }}</button>
</div>
