import { Component } from '@angular/core';
import { Router    } from '@angular/router';
import { AuthService } from '@shared/services';

@Component({
  templateUrl: './blank.component.html',
})

export class BlankComponent {
  constructor(
    private router:      Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (this.authService.accessTokenValue) this.router.navigateByUrl('/time-tracking/home');
    else if (this.router.url === '/')      this.router.navigateByUrl('/auth/start-screen');
  }

}
