<div class="du-flex-align" [ngClass]="extended ? '' : 'bl-' + stateColor + '-5'">
  <div class="du-flex-column du-flex-1 gap-m p-s">
    <div class="du-flex-justify du-flex-align">
      <span class="du-flex-align font-600 gap-xs">
        <span *ngIf="extended" class="du-flex-center circle-xl color-white" [ngClass]="'bg-' + stateColor">
          <span class="du-flex-center font-l" [ngClass]="'du-icon-' + stateIcon"></span>
        </span>
        <span class="du-flex-align gap-xxs">
          <span translate="cw"></span>
          <span>{{  startDate | date: 'ww' }}</span>
          <span>({{ startDate | date: 'dd' }}.{{ startDate | date: 'MM' }} - {{ endDate | date: 'dd' }}.{{ endDate | date: 'MM' }})</span>
        </span>
      </span>

      <div class="du-flex-align gap-xs">
        <span *ngIf="notSynced" class="du-flex-align gap-xs p-xs b-radius bg-light-orange color-orange">
          <span class="du-flex-center du-icon-offline font-l"></span>
          <span class="font-s" translate="notSynced" *ngIf="extended"></span>
        </span>
        <span *ngIf="createdByTempton" class="pt-xs pb-xs pl-s pr-s b-radius font-600 bg-light-blue color-blue">T</span>
        <span *ngIf="signer"           class="du-flex-align gap-xxs bg-green-light b-radius pl-s pr-s pt-xxs pb-xxs">
          <span class="du-flex-center font-xl du-icon-circle-tick"></span>
          <span class="font-s" translate="signed"></span>
        </span>
        <span *ngIf="extended" class="du-flex-center font-xl du-icon-close" (click)="close.emit()"></span>
      </div>
    </div>

    <div class="du-flex-justify gap-m">
      <div class="du-flex-column gap-xxs">
        <span class="font-s" translate="assignment"></span>
        <span class="font-500 lh-l">{{ assignmentTitle }}</span>
      </div>
      <div class="du-flex-column du-flex-align-end gap-xxs width-100" *ngIf="rightLabel">
        <span class="font-s">{{ rightLabel | translate }}</span>
        <span class="font-500">{{ rightValue }}</span>
      </div>
    </div>

    <div class="du-flex-align du-flex-1 gap-xs" *ngIf="bottomIcons && bottomIcons.length">
      <span *ngFor="let icon of bottomIcons" [ngClass]="'du-flex-center p-xxs b-radius bg-light-blue color-blue font-icon du-icon-' + icon"></span>
    </div>
  </div>
</div>
