import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector:      'more-box',
  templateUrl: './more-box.component.html'
})
export class MoreBoxComponent {
  optionsOpen: boolean;

  @Output() editCallback   = new EventEmitter<void>;
  @Output() deleteCallback = new EventEmitter<void>;

  @HostListener('document:mousedown', ['$event']) close(event: MouseEvent) {
    if (this.optionsOpen && !(event.target as HTMLElement).closest('more-box')) this.optionsOpen = null;
  }
  constructor() {}

  toggleOptions(e: Event): void {
    e.stopPropagation();
    this.optionsOpen = !this.optionsOpen;
  }

  editItem(e: Event): void {
    e.stopPropagation();
    this.optionsOpen = null;
    this.editCallback.emit();
  }

  deleteItem(e: Event): void {
    e.stopPropagation();
    this.optionsOpen = null;
    this.deleteCallback.emit();
  }

}
