import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, defer, forkJoin, from, map, take, tap } from 'rxjs';

import { App       } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { collapse, fadeIn } from '@shared/animations';
import { VacationRequestOverviewModel } from '@shared/models';
import { PushNotificationUnread, User, VacationRequestOverview } from '@shared/factories';
import { SessionStorageService, UserService, NotificationService, DBService, FormatService } from '@shared/services';
// import { SMSRetriever } from '../../../../../custom_plugins/capacitor-sms-retriever/src';

import { HomeBlockModel, HomeSectionModel, homeItems } from './home-items';

interface SavedEntriesModel {
  ar?: number;
  pr?: number;
  pd?: number;
  vr?: number;
  mm?: number;
}

@Component({
  selector:      'home',
  templateUrl: './home.component.html',
  host: { class: 'height-full du-flex-column gap-xl pb-m pl-m pr-m bg-grey color-primary font-600 relative' },
  animations: [fadeIn, collapse]
})
export class HomeComponent implements OnInit, OnDestroy {
  user:            User;

  savedEntries:    SavedEntriesModel = {};

  unreadSalaries:  number;
  unreadVacations: number;

  vacationInvites: VacationRequestOverview[];
  unconfirmedEbs:  number;

  tutorialStep:    number = 0;
  closeApp:        boolean;
  initLoad:        boolean;

  homeItems = homeItems;
  @HostListener('document:mousedown', ['$event']) close(event: MouseEvent) {
    if (this.tutorialStep) ++this.tutorialStep;
    if (this.tutorialStep > 3) {
      this.sessionStorageService.popOverflowStack('TutorialStep');
      setTimeout(() => this.tutorialStep = 0);
    }
  }
  constructor(
    private router:                Router,
    private userService:           UserService,
    private dbService:             DBService,
    private formatService:         FormatService,
    private notificationService:   NotificationService,
    public  sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.prepareHeader();
    this.clearTemp();
    this.closeAppOnDoubleBack();
    this.userService.currentUser.pipe(take(1)).subscribe(user => user && this.syncData());
  }

  ngOnDestroy(): void {
    if (Capacitor.isNativePlatform) App.removeAllListeners();
  }

  private prepareHeader(): void {
    this.sessionStorageService.setHeaderTitle(' ');
    this.sessionStorageService.setHeaderControls({
      left: [
        { icon: 'menu font-xl', callback: () => this.sessionStorageService.pushDynamicComponent({ component: 'Sidebar' }) },
        { svg: 'logo-blue' }
      ],
      right: [
        { icon: 'bell      circle-36 bg-grey color-blue font-xl', class: 'relative dot-orange-after-top-right'                                           },
        { icon: ' overflow circle-36 bg-grey color-blue font-xl', img: this.userService.currentUserValue.avatarThumb, callback: () => this.openProfile() }
      ]
    });
  }

  private clearTemp(): void {
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryValue(null);
    this.sessionStorageService.setTemporaryAssignments(null);
    this.sessionStorageService.setCachedEntries(null);
    this.sessionStorageService.setSortFlow(null);
    this.sessionStorageService.setFilterFlow(null);
    this.sessionStorageService.setTabFlow(null);
    this.sessionStorageService.setPaging(null);
  }

  private closeAppOnDoubleBack(): void { 
    if (Capacitor.isNativePlatform()) {
      // if (Capacitor.getPlatform() === 'android') SMSRetriever.hash().then(res => console.log(res));
      App.addListener('backButton', () => {
        if (this.closeApp) {
          this.closeApp = true;
          setTimeout(() => this.closeApp = false, 3000);
        } else App.exitApp();
      });
    }
  }

  syncData(): void {
    this.notificationService.wait();
    forkJoin([
      this.checkUnconfirmedEBS(),
      this.checkUnreadCounters(),
      this.checkSavedEntries(),
      this.checkVacationInvites()
    ]).pipe(take(1)).subscribe(res => {
      this.initLoad = true;
      this.notificationService.close();
    });
  }

  private checkUnconfirmedEBS(): Observable<any> {
    return defer(() => from(this.dbService.loadMultipleFromDB('ebs')).pipe(take(1),
      map(ebs => ebs.filter(e => !e.archived_at && !e.confirmed_at )),
      map(ebs => ebs.filter(e => new Date(e.assignment_ends_at).getTime() >= new Date().getTime() )),
      map(ebs => this.unconfirmedEbs = ebs.length)
    ));
  }

  private checkUnreadCounters(): Observable<PushNotificationUnread[]> {
    return defer(() => from(this.dbService.loadMultipleFromDB('unreadPn')).pipe(take(1),
      map(pn => pn.map(pn => new PushNotificationUnread(pn))),
      tap(pn => {
        this.unreadVacations = pn.filter(e => e.sourceType === 'vacation_request')?.length || 0;
        this.unreadSalaries  = pn.filter(e => e.sourceType === 'salary_report')?.length    || 0;
      })
    ));
  }

  private checkSavedEntries(): Observable<any[]> {
    return defer(() => forkJoin([
      from(this.dbService.loadMultipleFromDB('localReports'  )).pipe(take(1), tap(entries => this.savedEntries.ar = entries?.length)),
      from(this.dbService.loadMultipleFromDB('photoReports'  )).pipe(take(1), tap(entries => this.savedEntries.pr = entries?.length)),
      from(this.dbService.loadMultipleFromDB('localDocuments')).pipe(take(1), tap(entries => this.savedEntries.pd = entries?.length)),
      from(this.dbService.loadMultipleFromDB('localVacations')).pipe(take(1), tap(entries => this.savedEntries.vr = entries?.length)),
      from(this.dbService.loadMultipleFromDB('localMileages' )).pipe(take(1), tap(entries => this.savedEntries.mm = entries?.length))
    ]));
  }

  private checkVacationInvites(): Observable<VacationRequestOverview[]> {
    let filter = {
      state:              'awaiting_external_approval',
      creation_initiator: 'internal'
    };
    return defer(() => from(this.dbService.loadMultipleFromDB('vacationRequests', filter))).pipe(
      take(1),
      map((invites: VacationRequestOverviewModel[]) => invites.map(i => new VacationRequestOverview(i))),
      tap(invites => this.vacationInvites = invites),
      tap(() => this.prepareVacationInvites())
    );
  }

  private prepareVacationInvites(): void {
    if (this.vacationInvites?.length) {
      let vrBlock = this.homeItems[0].children.find(s => s.useCase === 'vr');
      vrBlock.children = vrBlock.children.filter(i => !i.id);
      vrBlock.children.unshift(...this.vacationInvites.map(i => ({
        id:        i.id,
        title:     this.formatService.period(i.startsOn, i.endsOn),
        subtitle:  i.leaveType,
        bgColor:   'light-orange',
        useCase:   'vr-invite',
        leftIcon:  'calendar-add',
        rightLink: 'confirm'
      })));
    }
  }

  handleSalaryReportAuth(): void {
    if (this.userService.salaryTwoFactorTokenValue) this.router.navigate(['/time-tracking/salary-overview']);
    else this.sessionStorageService.pushDynamicComponent({ component: 'Salary2FA' });
  }

  openTutorialList(): void {
    this.sessionStorageService.pushDynamicComponent({ component: 'TutorialsList' });
  }

  openProfile(): void {
    this.router.navigateByUrl('/time-tracking/profile');
  }

  private handleVacationInvite(vacationId: number): void {
    let vacation = this.vacationInvites.find(vi => vi.id === vacationId);
    if (vacation) this.sessionStorageService.pushDynamicComponent({
      component: 'VacationInvite',
      props: {
        vacation,
        callback: () => this.reviewVacationInvite(vacationId)
      }
    });
  }

  private reviewVacationInvite(vacationId: number): void {
    let vrBlock = this.homeItems[0].children.find(s => s.useCase === 'vr');
    let index   = vrBlock.children.findIndex(i => i.id === vacationId);
    vrBlock.children.splice(index, 1);
    this.vacationInvites = this.vacationInvites.filter(vi => vi.id !== vacationId);
    defer(() => from(this.dbService.deleteOneFromDB('vacationRequests', vacationId))).pipe(take(1)).subscribe();
  }

  private startTutorial(): void {
    if (!this.tutorialStep) this.sessionStorageService.pushOverflowStack('TutorialStep');
    ++this.tutorialStep;
  }

  getCounterHandler(useCase: string): string {
    return +this.getCounter(useCase) > 99 ? '99+' : this.getCounter(useCase)+'';
  }

  getCounter(useCase: string): number {
    switch (useCase) {
      case 'vr':      { return +this.unreadVacations + +this.vacationInvites?.length; };
      case 'vr-list': { return +this.unreadVacations;                                 };
      case 'ebs':     { return +this.unconfirmedEbs;                                  };
      case 'sr':      { return +this.unreadSalaries;                                  };
      case 'ar-new':
      case 'pr-new':
      case 'pd-new':
      case 'vr-new':
      case 'mm-new': { return (this.savedEntries as any)[useCase.split('-new')[0]];   };
      default:
        return null;
    };
  }

  navItemCallback(item: HomeSectionModel | HomeBlockModel): void {
    if ((item as HomeSectionModel).children) (item as HomeSectionModel).collapse = !(item as HomeSectionModel).collapse;
    else switch (item.useCase) {
      case 'ar-list':
      case 'd-list':
      case 'pd-list':
      case 'vr-list':
      case 'mm-list':
      case 'ebs':
      case 'd-combine':
      case 'ar-new':    { this.goToUrl(item.useCase);                             break; };
      case 'pr-new':
      case 'pd-new':
      case 'vr-new':
      case 'mm-new':    { this.checkForSavedAndRedirect(item.useCase);            break; };
      case 'vr-invite': { this.handleVacationInvite((item as HomeBlockModel).id); break; };
      case 'sr':        { this.handleSalaryReportAuth();                          break; };
      default:
        break;
    };
  }

  private goToUrl(useCase: string): void {
    switch (useCase) {
      case 'd-combine':
      case 'd-list':   { this.redirect('dailies-overview');     break; };
      case 'ar-list':  { this.redirect('reports-overview');     break; };
      case 'pd-list':  { this.redirect('documents-overview');   break; };
      case 'vr-list':  { this.redirect('vacations-overview');   break; };
      case 'mm-list':  { this.redirect('mileage-overview');     break; };
      case 'ar-new':   { this.redirect('preselect-ar');         break; };
      case 'mm-new':   { this.redirect('assignment-select-mm'); break; };
      case 'ebs':      { this.redirect('ebs-overview');         break; };
      case 'pr-new':   { this.redirect('assignment-select-pr'); break; };
      case 'pd-new':   { this.redirect('document-select');      break; };
      case 'vr-new':   { this.redirect('vacation-type-select'); break; };
      case 'mm-new':   { this.redirect('assignment-select-mm'); break; };
      default:
        break;
    };
  }


  private checkForSavedAndRedirect(useCase: string): void {
    let useCaseName: string = useCase.split('-new')[0];
    if ((this.savedEntries as any)[useCaseName]) this.router.navigateByUrl(`/time-tracking/preselect-${useCaseName}`);
    else this.goToUrl(useCase);
  }

  private redirect(url: string): void {
    this.router.navigateByUrl(`/time-tracking/${url}`);
  }

}
