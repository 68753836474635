<div class="du-flex-column du-flex-1 overflow-auto" *ngIf="user">
  <div class="du-flex-column gap-m p-m bb-grey">
    <div class="du-flex-column gap-xs">
      <span class="font-l bold" translate="licensePlate"></span>
      <span translate="enterLicensePlate"></span>
      <span translate="licensePlateEx"></span>
    </div>

    <div class="du-flex-column gap-xs">
      <span class="font-500" translate="country"></span>
      <div class="select-wrapper">
        <select class="grey" [(ngModel)]="country">
          <option [value]="null" disabled selected>Select Country</option>
          <option *ngFor="let country of countries" [value]="country.code">{{country.name}}</option>
        </select>
      </div>
    </div>

    <div class="du-flex-column gap-xs">
      <span class="font-500" translate="licensePlate"></span>
      <input type="text" [(ngModel)]="licensePlate" maxlength="15">
    </div>
  </div>

  <div class="du-flex-column gap-m p-m">
    <div class="du-flex-column gap-s">
      <div class="du-flex-column gap-xs">
        <span class="font-l bold" translate="departurePoint"></span>
        <span translate="changeDepatrurePoint"></span>
      </div>
      <div class="du-flex-column gap-xs">
        <span class="font-500" translate="streetName"></span>
        <input type="text" [(ngModel)]="user.address.street" disabled>
      </div>
    </div>

    <div class="du-flex-justify gap-xl">
      <div class="du-flex-column gap-xs">
        <span class="font-500" translate="postalCode"></span>
        <input type="text" [(ngModel)]="user.address.zip" disabled>
      </div>
      <div class="du-flex-column gap-xs">
        <span class="font-500" translate="city"></span>
        <input type="text" [(ngModel)]="user.address.city" disabled>
      </div>
    </div>

    <div class="du-flex-align b-grey gap-xs p-m shadow-header b-radius" (click)="openSignaturePad()" *ngIf="!signature || changesDetected() && !confirmSignature">
      <span class="du-flex-center du-icon-signature font-xl"></span>
      <span translate="signature"></span>
      <span class="color-blue bold mla" translate="sign"></span>
    </div>

    <div class="du-flex-column gap-s" *ngIf="changesDetected() ? confirmSignature : signature" (click)="openSignaturePad()">
      <img class="b-grey" [src]="confirmSignature || signature">
      <span *ngIf="changesDetected()" class="font-s lh-l" translate="withMySignatureConfirm"></span>
    </div>
  </div>
</div>

<div class="p-m mb-xs" *ngIf="user">
  <button class="width-full btn-primary" translate="submit" (click)="changeLicencePlate()" [disabled]="!licensePlate || !country || !signature || !changesDetected() || changesDetected() && !confirmSignature"></button>
</div>
