import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MileageWorkDayLocal } from '@shared/factories';
import { collapse, fadeIn    } from '@shared/animations';

@Component({
  selector:      'constructor-work-day',
  templateUrl: './constructor-work-day.component.html',
  host: { class: 'du-flex-column gap-s b-radius bg-white shadow-header relative p-s' },
  animations: [ fadeIn, collapse ]
})
export class MileageWorkDayComponent {
  @Input() index:   number;
  @Input() workDay: MileageWorkDayLocal;

  @Output() resetToDefault = new EventEmitter<number>;
  @Output() validate       = new EventEmitter<void>;

  skipErrors: boolean;
  constructor() {}

  resetPlaceholder(): void {
    this.skipErrors = true;
    setTimeout(() => {
      this.resetToDefault.emit(this.index);
      this.skipErrors = null;
    }, 200);
  }

  addDefaultDay(): void {
    this.workDay.placeholder = false;
    this.validate.emit();
  }

  validateDistance(workDay: MileageWorkDayLocal): void {
    if (!workDay.amountOfKm) workDay.amountOfKm = 1;
    this.validate.emit();
  }

}
