import { ActivityReportBasicModel, ActivityReportLocalModel, ActivityReportOverviewModel, ActivityReportToSubmitModel } from "@shared/models";
import { Assignment, AssignmentBasic, DailyLocal, DailyOverview, ValidationError } from "@shared/factories";
import { formatDate } from "@angular/common";

export class ActivityReportBasic {
  id:           number;
  userId:       number;

  startDate:    Date;
  endDate:      Date;

  attachment:   string;

  lifeState:    string;
  externalNote: string;
  constructor(data: ActivityReportBasicModel) {
    this.id           = data.id;
    this.userId       = data.userId;

    this.startDate    = this.parceDate(data.start_date);
    this.endDate      = this.parceDate(data.end_date);
    this.attachment   = data.attachment;
    this.lifeState    = data.life_state;
    this.externalNote = data.external_employee_notes;
  }

  parceDate(date: any): Date {
    return date ? date instanceof Date ? date : new Date(date) : null;
  }

  toJSON(): ActivityReportBasicModel {
    let obj: ActivityReportBasicModel = {
      userId:                  this.userId       ? this.userId                  : null,
      start_date:              this.startDate    ? this.startDate.toISOString() : null,
      end_date:                this.endDate      ? this.endDate.toISOString()   : null,

      attachment:              this.attachment   ? this.attachment              : null,

      life_state:              this.lifeState    ? this.lifeState               : null,
      external_employee_notes: this.externalNote ? this.externalNote            : null,
    };
    if (this.id) obj = Object.assign(obj, { id: this.id });
    return obj;
  }
}

export class ActivityReportLocal extends ActivityReportBasic {
  assignment:     Assignment;
  mileageStart:   Date;
  mileageEnd:     Date;

  dailyReports:   DailyLocal[];

  signature:      string;
  signer:         string;
  customerEmails: string[];

  useCase:        string;
  notSynced:      boolean;
  constructor(data: ActivityReportLocalModel) {
    super(data);

    this.assignment     = data.assignment ? new Assignment(data.assignment) : null;
    this.dailyReports   = this.notEmpty(data.dailyReports) ? data.dailyReports.map(d => new DailyLocal(d)) : null;

    this.mileageStart   = this.parceDate(data.mileageStart);
    this.mileageEnd     = this.parceDate(data.mileageEnd);

    this.signature      = data.signature;
    this.signer         = data.signer;

    this.customerEmails = this.notEmpty(data.customerEmails) ? data.customerEmails : [];

    this.useCase        = data.useCase   ? data.useCase   : null;
    this.notSynced      = data.notSynced ? data.notSynced : null;
  }

  get activeDailyReports():     DailyLocal[] { return this.dailyReports ? this.dailyReports.filter(dr => !dr.placeholder) : [];              }

  get durationWithPauses():     number       { return this.activeDailyReports.reduce((sum, dr) => sum = sum + dr.durationWithPauses,     0); }
  get durationExludingPauses(): number       { return this.activeDailyReports.reduce((sum, dr) => sum = sum + dr.durationExludingPauses, 0); }

  toJSON(): ActivityReportLocalModel {
    return Object.assign(super.toJSON(), {
      assignment:     this.assignment         ? this.assignment.toJSON()                     : null,
      dailyReports:   this.activeDailyReports ? this.activeDailyReports.map(d => d.toJSON()) : null,
      mileageStart:   this.mileageStart       ? this.mileageStart.toISOString()              : null,
      mileageEnd:     this.mileageEnd         ? this.mileageEnd.toISOString()                : null,
      signature:      this.signature          ? this.signature                               : null,
      signer:         this.signer             ? this.signer                                  : null,
      customerEmails: this.customerEmails     ? this.customerEmails                          : null,
      useCase:        this.useCase            ? this.useCase                                 : null,
      notSynced:      this.notSynced          ? this.notSynced                               : null
    });
  }

  toSubmitJSON(): ActivityReportToSubmitModel {
    return {
      submitted_at:            new Date().toISOString(),

      assignment_id:           this.assignment && this.assignment.id,
      start_date:              this.startDate instanceof Date ? formatDate(this.startDate, 'yyyy-MM-dd', 'de') : null,
      end_date:                this.endDate   instanceof Date ? formatDate(this.endDate,   'yyyy-MM-dd', 'de') : null,

      time_frames:             this.activeDailyReports && this.activeDailyReports.length ? this.activeDailyReports.map((dr) => dr.toSubmitData()) : null,

      external_employee_notes: this.externalNote,
      life_state:              this.lifeState,

      customer_signature:      this.signature,
      signer:                  this.signer,
      attachment:              this.attachment,

      mileage_money:           !!this.mileageStart,
      submit_with_timestamp:   0,

      customer_signature_confirmation_email: this.customerEmails.length ? this.customerEmails : null,
      customer_signature_confirmation:     !!this.customerEmails.length
    };
  }

  private notEmpty(array: any[]): any[] {
    return array && array.length ? array : null;
  }

  get errors(): ValidationError[] {
    return this.activeDailyReports.reduce((errors, d) => errors = [...errors, ...d.errors, ...d.childrenErrors], []);
  }

  get techErrors():  Set<string> { return new Set(this.activeDailyReports.reduce((set, d) => set = [...set, ...d.techErrorsAll],  [])); }
  get legalErrors(): Set<string> { return new Set(this.activeDailyReports.reduce((set, d) => set = [...set, ...d.legalErrorsAll], [])); }

  get signedByCustomer():        boolean { return this.lifeState && this.lifeState === 'signed_by_customer';        }
  get waitingCustomerApproval(): boolean { return this.lifeState && this.lifeState === 'waiting_customer_approval'; }
  get waitingClarification():    boolean { return this.lifeState && this.lifeState === 'waiting_clarification';     }

  setSignedByCustomer():        void { this.lifeState = 'signed_by_customer';        }
  setWaitingCustomerApproval(): void { this.lifeState = 'waiting_customer_approval'; }
  setWaitingClarification():    void { this.lifeState = 'waiting_clarification';     }
}

export class ActivityReportOverview extends ActivityReportBasic {
  dailyReports:         DailyOverview[];
  originalTimeFrames:   DailyOverview[];
  correctedTimeFrames:  DailyOverview[];

  assignment:           AssignmentBasic;
  lifeState:            string;

  customerReviewedAt:   Date;
  customerSignature:    string;
  customerSigner:       string;
  approvedAt:           Date;

  attachmentPath:       string;
  confirmationEmails:   string[];

  createdAt:            Date;
  createdById:          number;
  externalEmployeeId:   number;
  mileageMoneyReportId: number;

  splitParentId:        number;
  splitChildId:         number;

  haveHolidays:         boolean;
  createdByTempton:     boolean;
  reportCorrected:      boolean;

  approvedState:        boolean;
  awaitingState:        boolean;
  rejectedState:        boolean;
  constructor(data: ActivityReportOverviewModel) {
    super(data);

    this.originalTimeFrames   = data.original_time_frames  && data.original_time_frames.length  ? data.original_time_frames.map(tf  => new DailyOverview(tf)) : [];
    this.correctedTimeFrames  = data.corrected_time_frames && data.corrected_time_frames.length ? data.corrected_time_frames.map(tf => new DailyOverview(tf)) : [];

    this.assignment           = data.assignment ? new AssignmentBasic(data.assignment) : null;
    this.lifeState            = data.life_state;

    this.customerReviewedAt   = this.parceDate(data.customer_reviewed_at);
    this.customerSignature    = data.customer_signature;
    this.customerSigner       = data.signer;
    this.approvedAt           = this.parceDate(data.approved_at);

    this.attachmentPath       = data.attachment_path;
    this.confirmationEmails   = data.signature_confirmation && data.signature_confirmation.emails && data.signature_confirmation.emails.length ? data.signature_confirmation.emails.map(e => e) : null;
    this.dailyReports         = this.attachmentPath ? this.correctedTimeFrames : this.originalTimeFrames;

    this.createdAt            = this.parceDate(data.created_at);
    this.createdById          = data.created_by_id;
    this.externalEmployeeId   = data.external_employee_id;
    this.mileageMoneyReportId = data.mileage_money_report_id;

    this.splitParentId        = data.split_parent_id;
    this.splitChildId         = data.split_child_id;

    this.haveHolidays         = !!this.originalTimeFrames.find(tf => tf.holidays.length);
    this.createdByTempton     = this.createdById !== this.externalEmployeeId;
    this.reportCorrected      = this.checkReportCorrected();

    this.approvedState        = this.lifeState === 'approved_by_internal';
    this.awaitingState        = this.lifeState === 'waiting_customer_approval' || this.lifeState === 'signed_by_customer' || this.lifeState === 'waiting_clarification';
    this.rejectedState        = this.lifeState === 'rejected_by_internal'      || this.lifeState === 'archived_by_system';
  }

  private checkReportCorrected(): boolean {
    if (!this.approvedAt)        return false;
    if (this.customerReviewedAt) return true;
    if (this.attachmentPath)     return true;
    if (!this.attachmentPath && this.correctedTimeFrames && this.correctedTimeFrames.length) {
      let newFrames = this.correctedTimeFrames.find(dr => !dr.originalTimeFrameId);
      let deletedFrames = this.originalTimeFrames.find(otf => !this.correctedTimeFrames.find(ctf => otf.id === ctf.originalTimeFrameId));
      let newPause = this.originalTimeFrames.find(otf => {
        let ctf_p = this.correctedTimeFrames.find(ctf => ctf.originalTimeFrameId === otf.id);
        if (!ctf_p?.pauses?.length) return false;
        return ctf_p.pauses.length !== otf.pauses.length || 
               ctf_p.pauses.find(p => !otf.pauses.find(otf_p => otf_p.id === p.id));
      })
      let timesCorrected = this.correctedTimeFrames.find(tf => tf.corrected || tf.pauses?.find(p => p.corrected));
      return !!(newFrames || deletedFrames || timesCorrected || newPause);
    }
    return false;
  }

  toJSON(): ActivityReportOverviewModel {
    return Object.assign(super.toJSON(), {
      original_time_frames:    this.originalTimeFrames   ? this.originalTimeFrames.map(tf => tf.toJSON())  : null,
      corrected_time_frames:   this.correctedTimeFrames  ? this.correctedTimeFrames.map(tf => tf.toJSON()) : null,

      assignment:              this.assignment           ? this.assignment.toJSON()                        : null,
      life_state:              this.lifeState            ? this.lifeState                                  : null,

      customer_reviewed_at:    this.customerReviewedAt   ? this.customerReviewedAt.toISOString()           : null,
      customer_signature:      this.customerSignature    ? this.customerSignature                          : null,
      signer:                  this.customerSigner       ? this.customerSigner                             : null,
      approved_at:             this.approvedAt           ? this.approvedAt.toISOString()                   : null,

      attachment_path:         this.attachmentPath       ? this.attachmentPath                             : null,
      signature_confirmation: {
        emails:                this.confirmationEmails   ? this.confirmationEmails                         : null
      },
      created_at:              this.createdAt            ? this.createdAt.toISOString()                    : null,
      created_by_id:           this.createdById          ? this.createdById                                : null,
      external_employee_id:    this.externalEmployeeId   ? this.externalEmployeeId                         : null,
      mileage_money_report_id: this.mileageMoneyReportId ? this.mileageMoneyReportId                       : null,

      split_parent_id:         this.splitParentId        ? this.splitParentId                              : null,
      split_child_id:          this.splitChildId         ? this.splitChildId                               : null,
      additional_info:         this.collectAdditionalInfo()
    });
  }

  private collectAdditionalInfo(): string[] {
    let temp = [];
    if (this.approvedAt)                         temp.push('with_released_state');
    if (this.confirmationEmails)                 temp.push('with_customer_email');
    if (this.splitParentId || this.splitChildId) temp.push('with_split_report');
    if (this.mileageMoneyReportId)               temp.push('with_mileage_money');
    if (this.haveHolidays)                       temp.push('with_holidays');
    if (this.attachmentPath)                     temp.push('with_attachment');
    if (!this.attachmentPath)                    temp.push('without_attachment');
    return temp;
  }

  get durationWithPauses():     number { return this.dailyReports.reduce((sum, dr) => sum = sum + dr.durationWithPauses,     0); }
  get durationExludingPauses(): number { return this.dailyReports.reduce((sum, dr) => sum = sum + dr.durationExludingPauses, 0); }

}
