import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone:     false,
  selector:      'basic-overview-item',
  templateUrl: './basic-overview-item.component.html'
})
export class BasicOverviewItemComponent {
  @Input() startDate:        Date;
  @Input() endDate:          Date;

  @Input() extended:         boolean;
  @Input() stateColor:       string;
  @Input() stateIcon:        string;

  @Input() notSynced:        boolean;
  @Input() createdByTempton: boolean;
  @Input() signer:           boolean;

  @Input() assignmentTitle:  string;
  @Input() rightLabel:       string;
  @Input() rightValue:       string;

  @Input() bottomIcons:      string[];

  @Output() close = new EventEmitter<void>;
  constructor( ) {}

}
