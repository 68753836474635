import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';

import { App       } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import { SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './info.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-m bg-grey' }
})
export class InfoComponent implements OnInit, OnDestroy {
  type:    string;
  title:   string;
  message: string;
  buttons: any[];
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private sessionStorageService: SessionStorageService,
  ) { }

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle(null);
    this.sessionStorageService.setProgressBar(null);
    this.route.queryParams.pipe(take(1)).subscribe(params => this.prepareInfo(params['type'], params['useCase']));
    if (Capacitor.isNativePlatform) App.addListener('backButton', () => this.router.navigateByUrl('/'));
  }

  ngOnDestroy(): void {
    if (Capacitor.isNativePlatform) App.removeAllListeners();
  }

  getIcon(type: string): string {
    if (type === 'submit' ) return 'tick         color-blue   font-120';
    if (type === 'save'   ) return 'edited       color-blue   font-80';
    if (type === 'failure') return 'circle-cross color-red    font-120';
    if (type === 'offline') return 'offline      color-orange font-80';
    return null;
  }

  private prepareInfo(type: string, useCase: string): void {
    this.type = type;

    if (type === 'offline') this.prepareOfflineMessage();
    if (type === 'auth')    this.prepareAuthMessage();
    if (type === 'submit')  this.prepareSubmitMessage(useCase);
    if (type === 'save')    this.prepareSavedMessage(useCase);
    if (type === 'failed')  this.prepareFailedMessage();

    if (!this.buttons) this.buttons = [ { label: 'Done', callback: () => this.router.navigateByUrl('/') } ];
  }

  private prepareOfflineMessage(): void {
    this.title   = 'noConnection';
    this.message = 'willBeUploadedWhenOnline';
  }

  private prepareAuthMessage(): void {
    this.type    = 'submit';
    this.title   = 'allSetUp';
    this.message = 'nowLogIn';
    this.buttons = [{ label: 'toLogin', callback: () => this.router.navigateByUrl('/auth/login') }];
  }

  private prepareSubmitMessage(useCase: string): void {
    this.title   = 'sent';
    this.message = this.prepareSubmitMessageByUseCase(useCase);
  }

  private prepareSavedMessage(useCase: string): void {
    this.title   = 'saved';
    this.message = this.prepareSavedMessageByUseCase(useCase);
  }

  private prepareFailedMessage(): void {
    this.title   = 'error';
    this.message = 'errorMessage';
  }

  private prepareSubmitMessageByUseCase(useCase: string): string {
    switch (useCase) {
      case 'ar':             { return 'reportSubmitted';   };
      case 'pr':             { return 'photoReportSaved';  };
      case 'vr':             { return 'vacationSubmitted'; };
      case 'pd':             { return 'documentSubmitted'; };
      case 'mm':             { return 'mileageSubmitted';  };
      case 'refer-a-friend': { return 'referedAFriend';    };
      default:
        return '';
    };
  }

  private prepareSavedMessageByUseCase(useCase: string): string {
    switch (useCase) {
      case 'ar': { return 'reportSaved';      };
      case 'pr': { return 'photoReportSaved'; };
      case 'vr': { return 'vacationSaved';    };
      case 'pd': { return 'documentSaved';    };
      case 'mm': { return 'mileageSaved';     };
      case 't':
        this.buttons = [{ label: 'Done', callback: () => this.router.navigateByUrl('/time-tracking/preselect-ar') }];
        return 'templateSaved';
      default:
        return '';
    };
  }

}