<div class="du-flex-justify du-flex-align gap-m" *ngIf="index >= 0">
  <span class="du-flex-align gap-xxs font-l bold">
    <span>{{ daily.startsAt | date: 'EEE' }}</span>
    <span *ngIf="useCase !== 't'">{{ daily.startsAt | date: 'dd.MM.yyyy' }}</span>
  </span>
  <daily-tooltips [mileage]="daily.mileage" [holidays]="daily.holidays"></daily-tooltips>
  <span class="du-flex-center font-xl du-icon-close" (click)="resetPlaceholder()"></span>
</div>

<div class="du-flex-column gap-s">
  <div class="du-flex-align du-flex-justify gap-m">
    <span class="font-16 font-500" translate="workingHours"></span>
    <daily-tooltips [mileage]="daily.mileage" [holidays]="daily.holidays" *ngIf="index === null || index === undefined"></daily-tooltips>
  </div>

  <div class="du-flex-column" *ngIf="daily.techErrors && daily.techErrors.size" @collapse>
    <div class="du-flex-column gap5 text-xs overflow">
      <span class="color-red"    *ngFor="let err of daily.techErrors"  @collapse><span class="overflow">{{ err | translate}}</span></span>
    </div>
  </div>

  <div class="du-flex-column" *ngIf="showLegal && daily.legalErrors && daily.legalErrors.size" @collapse>
    <div class="du-flex-column gap5 text-xs overflow">
      <span class="color-orange" *ngFor="let err of daily.legalErrors" @collapse><span class="overflow">{{ err | translate}}</span></span>
    </div>
  </div>

  <div class="du-flex-align du-flex-justify gap-m" [tutorialStep]="tutorialStepVal" [tutorialNumber]="4" [tutorialText]="'hereCanInputTime' | translate">
    <div class="du-flex-align gap-xl">
      <div class="du-flex-align gap-xs">
        <input class="time-input white" type="time" [class.b-red-i]="daily.techErrors.size" [class.b-orange-i]="!daily.techErrors.size && daily.legalErrors.size" [ngModel]="daily.startsAt | date: 'HH:mm'" (ngModelChange)="updateTime($event, daily.startsAt)">
        <span>-</span>
        <input class="time-input white" type="time" [class.b-red-i]="daily.techErrors.size" [class.b-orange-i]="!daily.techErrors.size && daily.legalErrors.size" [ngModel]="daily.endsAt   | date: 'HH:mm'" (ngModelChange)="updateTime($event, daily.endsAt)">
      </div>
      <span class="b-radius p-s font-500 animate-bg" [ngClass]="daily.techErrors && daily.techErrors.size ? 'bg-red-light' : daily.legalErrors && daily.legalErrors.size ? 'bg-orange-light' : 'bg-green-light'">{{ daily.durationExludingPauses | numberToTime }}</span>
    </div>
    <span *ngIf="daily.legalErrors && daily.legalErrors.size" class="du-flex-center font-xl color-orange du-icon-info" (click)="showLegal = !showLegal"></span>
  </div>
</div>

<div class="du-flex-column gap-s" [class.overflow-auto]="!tutorialStepVal" [tutorialStep]="tutorialStepVal" [tutorialNumber]="5" [tutorialText]="'hereCanInputBreaks' | translate">
  <div class="du-flex-justify">
    <div class="du-flex-column gap-s">
      <span class="font-16 font-500" translate="breakTime"></span>
      <div class="du-flex-column" *ngIf="daily.pauseTechErrors && daily.pauseTechErrors.size" @collapse>
        <div class="du-flex-column gap5 text-xs overflow">
          <!-- <span class="color-orange" *ngFor="let err of daily.pauseLegalErrors" @collapse><span class="overflow">{{ err | translate}}</span></span> -->
          <span class="color-red"    *ngFor="let err of daily.pauseTechErrors"  @collapse><span class="overflow">{{ err | translate}}</span></span>
        </div>
      </div>
    </div>
    <span *ngIf="!daily.placeholder" class="bg-grey b-radius du-icon-plus font-xl du-flex-center p-xs color-blue" (click)="addPause()"></span>
  </div>
  <div class="du-flex-column" *ngFor="let pause of daily.sortedPauses" @collapse>
    <div class="du-flex-justify gap-xl overflow">
      <div class="du-flex-justify du-flex-align gap-xl">
        <div class="du-flex-align gap-xs">
          <input class="time-input white" type="time" [ngModel]="pause.start | date: 'HH:mm'" (ngModelChange)="updateTime($event, pause.start)" [class.b-red-i]="pause.techErrors.size" [class.b-orange-i]="!pause.techErrors.size && pause.legalErrors.size" >
          <span>-</span>
          <input class="time-input white" type="time" [ngModel]="pause.end   | date: 'HH:mm'" (ngModelChange)="updateTime($event, pause.end)"   [class.b-red-i]="pause.techErrors.size" [class.b-orange-i]="!pause.techErrors.size && pause.legalErrors.size" >
        </div>
        <span class="b-radius p-s font-500 animate-bg" [ngClass]="pause.techErrors && pause.techErrors.size ? 'bg-red-light' : pause.legalErrors && pause.legalErrors.size ? 'bg-orange-light' : 'bg-green-light'">{{ pause.duration | numberToTime }}</span>
      </div>
      <span *ngIf="!daily.placeholder" class="bg-grey b-radius du-icon-minus font-xl du-flex-center p-xs" (click)="deletePause(pause)"></span>
    </div>
  </div>
</div>

<div [tutorialStep]="tutorialStepVal" [tutorialNumber]="6" [tutorialText]="'canTypeSpecifications' | translate">
  <input class="relative" type="text" placeholder="Project" [(ngModel)]="daily.project">
</div>

<div class="absolute-full du-flex-center b-radius bg-card-grey-light" @fadeIn *ngIf="daily.placeholder" (click)="addDefaultDay()">
  <div class="du-flex-center width-full" [tutorialStep]="tutorialStepVal" [tutorialNumber]="2" [tutorialText]="'activateDaySelectedAbove' | translate">
    <div class="du-flex-center circle-75 bg-blue-gradient">
      <img src="assets/svg/plus-white.svg">
    </div>
  </div>
</div>
