import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { PushNotificationOverview } from '@shared/factories';
import { NotificationService, NotificationsOverviewService } from '@shared/services';

@Component({
  selector:      'notifications',
  templateUrl: './notifications.component.html',
  host: { class: 'height-full du-flex-column gap-xs overflow-auto' }
})
export class NotificationsComponent implements OnInit {
  @Output() loadEntries = new EventEmitter<Function>;
  notifications: PushNotificationOverview[] = [];
  constructor(
    private notificationService:          NotificationService,
    private notificationsOverviewService: NotificationsOverviewService,
  ) {}

  ngOnInit(): void {
    this.loadEntries.emit(this.loadNotifications.bind(this));
    this.loadNotifications();
  }

  loadNotifications(reload: boolean = null, page: number = null): void {
    this.notificationService.wait();
    this.notificationsOverviewService.syncNotifications(page).subscribe(
      notifications => {
        this.notifications = [...this.notifications, ...notifications];
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

}
