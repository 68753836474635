<basic-overview-item [startDate]="vacation.startsOn"
                     [endDate]="vacation.endsOn"
                     [extended]="extended"

                     [stateColor]="getStateColor()"
                     [stateIcon]="getStateIcon()"

                     [notSynced]="localVacation.notSynced"
                     [createdByTempton]="overviewVacation.createdByTempton"
                     [signer]="!!overviewVacation.signer"

                     [assignmentTitle]="vacation.assignment && vacation.assignment.title || 'Kein Einsatz vorliegend'"
                     [rightLabel]="'vacationType'"
                     [rightValue]="vacation.leaveType | translate"
                     (close)="close.emit()">
</basic-overview-item>
