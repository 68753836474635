import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeIn } from '@shared/animations';

import { SessionStorageService } from '@shared/services';

@Component({
  selector:      'legal',
  templateUrl: './legal.component.html',
  host: { class: 'height-full du-flex-column bg-grey' },
  animations: [fadeIn]
})
export class LegalComponent implements OnInit {
  debug:        boolean;
  debugCounter: number = 0;
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('legalInformation');
    this.sessionStorageService.setHeaderControls({ left: [{ icon: 'arrow-big-left color-blue font-icon', callback: () => this.router.navigateByUrl('time-tracking/home') }] });
    this.sessionStorageService.setProgressBar(null);
    this.debug = this.sessionStorageService.debug;
    if (this.debug) this.debugCounter = 10;
  }

  openPrivacy(): void {
    window.open('https://www.tempton.de/datenschutz', '_blank');
  }

  toggleDebug(): void {
    if (this.debug) {
      --this.debugCounter;
      if (!this.debugCounter) {
        this.sessionStorageService.setDebug(false);
        this.sessionStorageService.setChip({ message: 'Debug mode DeActivated' });
        this.debug = false;
      } else this.sessionStorageService.setChip({ message: `To deActivate Debug mode click ${this.debugCounter} times` });
    } else {
      ++this.debugCounter;
      if (this.debugCounter === 10) {
        this.sessionStorageService.setDebug(true);
        this.sessionStorageService.setChip({ message: 'Debug mode Activated' });
        this.debug = true;
      } else this.sessionStorageService.setChip({ message: `To activate Debug mode click ${10 - this.debugCounter} times` });
    }
  }

}
