import { Component, OnInit } from '@angular/core';
import { Router            } from '@angular/router';

import { PhotoDocumentLocal      } from '@shared/factories';
import { PhotoDocumentLocalModel } from '@shared/models';
import { DBService, NotificationService, PhotoDocumentsService, SessionStorageService, UserService } from '@shared/services';

@Component({
  selector:      'document-preselect',
  templateUrl: './document-preselect.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify' }
})
export class DocumentPreselectComponent implements OnInit {
  documents: PhotoDocumentLocal[] = [];
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private userService:           UserService,
    private photoDocumentsService: PhotoDocumentsService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('photoDocument');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.setTemporaryValue(null);

    if (!this.userService.checkTutorial('pd')) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 'pd' }
    });

    this.dbService.loadMultipleFromDB('localDocuments')
    .then((documents: PhotoDocumentLocalModel[]) => this.documents = documents.map(d => new PhotoDocumentLocal(d)))
    .then(() => this.notificationService.close());
  }

  submitDocument(document: PhotoDocumentLocal): void {
    this.notificationService.wait();
    this.photoDocumentsService.submitPhotoDocument(document).subscribe(
      res => {
        this.deleteDocument(document.id);
        this.router.navigate(['time-tracking/info'], { queryParams: { type: 'submit', useCase: 'pd' }});
      },
      err => this.notificationService.alert(err)
    );
  }

  deleteDocument(documentId: number): Promise<PhotoDocumentLocal[] | void> {
    this.notificationService.wait();
    return this.dbService.deleteOneFromDB('localDocuments', documentId)
    .then(() => this.documents = this.documents.filter(d => d.id !== documentId))
    .then(() => this.notificationService.close())
    .catch(err => this.notificationService.alert(err));
  }

  callbackHandler(): void {
    this.router.navigateByUrl('time-tracking/document-select');
  }

  goBack(): void {
    this.router.navigateByUrl('time-tracking/home');
  }

}
