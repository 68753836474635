import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { AuthService, NotificationService, SessionStorageService, UserService } from '@shared/services';
import { slideRight } from '@shared/animations';

import SidebarIcons from './sidebar-items'

interface SidebarItem {
  label?:  string;
  path?:   string;
  
  icon?:   string;
  svg?:    string;
  
  class?:  string;
  border?: boolean;
  arrow?:  boolean;
  
  hide?:   boolean;
  bottom?: boolean;
}

@Component({
  selector:      'sidebar',
  templateUrl: './sidebar.component.html',
  host: { class: 'du-flex-column du-flex-justify absolute-full-height width-320 left-0 btr-radius bbr-radius bg-white'},
  animations: [ slideRight ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private readonly COMPONENT_NAME: string = 'Sidebar';
  sidebarIcons: SidebarItem[];
  bottomIcons:  SidebarItem[];

  @HostBinding('@slideRight') get slideIn() { return 'open'; }
  constructor(
    private router:                Router,
    private translateService:      TranslateService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService,
    private authService:           AuthService,
    public  userService:           UserService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser.subscribe(user => {
      this.sidebarIcons = SidebarIcons(`${user.firstName} ${user.lastName}`).filter(i => !i.bottom);
      this.bottomIcons  = SidebarIcons(`${user.firstName} ${user.lastName}`).filter(i =>  i.bottom);
      this.resetLang(this.translateService.currentLang);
      if (this.sessionStorageService.debug) this.sidebarIcons.find(i => i.label === 'debug').hide = false;
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => this.resetLang(event.lang));
    this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);
  }

  ngOnDestroy(): void {
    this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  elCallback(el: SidebarItem): void {
    if (el.path) this.router.navigate(['time-tracking/', el.path]);
    if (el.label === 'visitWebsite') this.openWebsite(this.translateService.currentLang);
    if (el.label === 'tutorials'   ) this.openDynamic('TutorialsList');
    if (el.label === 'lang'        ) this.openDynamic('LanguageList');
    if (el.label === 'logout'      ) this.logout();

    if (el.label !== 'tutorials' && el.label !== 'lang') this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
  }

  private openWebsite(lang: string): any {
    switch (lang) {
      case 'en':
      case 'pl':
      case 'ru':
      case 'tr':
        return window.open(`https://www.tempton.de/${lang}/landingpage-multilanguage`, '_blank');
      case 'cz':
        return window.open("https://www.tempton.de/cs/landingpage-multilanguage", '_blank');
      case 'ua':
        return window.open("https://www.tempton.de/uk/landingpage-multilanguage", '_blank');       
      case 'de':
        return window.open("https://www.tempton.de", '_blank');
      default:
        return window.open("https://www.tempton.de/en/landingpage-multilanguage", '_blank');    
    }
  }

  private openDynamic(component: string): void {
    this.sessionStorageService.pushDynamicComponent({ component });
  }

  private resetLang(lang: string): void {
    let item = this.bottomIcons.find(i => i.label === 'lang');
    item.svg = `flags/${lang}`;
  }

  logout(): void {
    this.notificationService.confirm({
      title: 'note',
      desc:  'Are you sure you want to logout?',
      buttons: { left: 'cancel', right: 'logout' },
      rightFn: () => this.authService.logout()
    });
  }

}
