import { Location } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { slideUp                            } from '@shared/animations';
import { TutorialScreenModel                } from '@shared/models';
import { SessionStorageService, UserService } from '@shared/services';

@Component({
  selector:      'tutorial-screen',
  templateUrl: './tutorial-screen.component.html',
  host: { class: 'absolute bottom-0 left-0 right-0 du-flex-column z-2' },
  animations: [ slideUp ]
})
export class TutorialScreenComponent implements OnInit, OnDestroy {
  useCase:    string;
  fullscreen: boolean;
  tutorial:   TutorialScreenModel;
  private readonly COMPONENT_NAME: string = 'TutorialScreen';
  @HostBinding('class')      get classes()  { return  this.fullscreen || this.useCase === 'dr' ? 'height-615 btl-radius btr-radius overflow' : 'top-50'; }
  @HostBinding('@slideUp')   get slideIn()  { return 'open'; }
  @HostBinding('@.disabled') get disabled() { return !this.fullscreen && this.useCase !== 'dr'; }
  constructor(
    private location:              Location,
    private userService:           UserService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    if (!this.fullscreen && this.useCase && this.useCase !== 'dr') this.sessionStorageService.setHeaderControls({
      left: [{
        icon: 'arrow-big-left color-blue font-icon',
        callback: () => {
          this.location.back();
          this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
        }
      }]
    });
    if (this.fullscreen) this.sessionStorageService.pushOverflowStack(this.COMPONENT_NAME);

    this.parceTutorial(this.useCase);
  }

  ngOnDestroy(): void {
    if (this.fullscreen) this.sessionStorageService.popOverflowStack(this.COMPONENT_NAME);
  }

  close(): void {
    this.sessionStorageService.popDynamicComponent(this.COMPONENT_NAME);
    this.userService.passTutorial(this.useCase);
  }

  parceTutorial(useCase: string) {
    switch (useCase) {
      case 'ar':
        this.prepareActivityReportTutorial();
        break;
      case 'dr':
        this.prepareDailyReportTutorial();
        break;
      case 'do':
        this.prepareDailyOverviewTutorial();
        break;
      case 'pr':
        this.preparePhotoReportTutorial();
        break;
      case 'pd':
        this.preparePhotoDocumentsTutorial();
        break;
      case 't':
        this.prepareTemplateTutorial();
        break;
      case 'vr':
        this.prepareVacationRequestTutorial();
        break;
      case 'mm':
        this.prepareMileageMoneyTutorial();
        break;
      default:
        break;
    }
  }

  private prepareActivityReportTutorial(): void {
    this.tutorial = {
      icon:     'calendar-month',
      title:    'activityReport',
      subtitle: 'submitAndGetPayed',
      steps: [
        { icon: 'circle-click', label: 'chooseYourAssignment'   },
        { icon: 'table',        label: 'chooseWeekWorked'       },
        { icon: 'sun',          label: 'activateDaysWorked'     },
        { icon: 'clock',        label: 'fillWorkingHoursBreaks' },
        { icon: 'circle-tick',  label: 'submitStrong'           },
      ]
    };
  }

  private prepareDailyReportTutorial(): void {
    this.tutorial = {
      icon:     'calendar-day',
      title:    'dailyReport',
      subtitle: 'noteCombineReport',
      steps: [
        { icon: 'circle-click', label: 'chooseYourAssignment'   },
        { icon: 'table',        label: 'chooseDayWorked'        },
        { icon: 'clock',        label: 'fillWorkingHoursBreaks' },
        { icon: 'circle-tick',  label: 'submitStrong'           },
      ]
    };
  }

  private prepareDailyOverviewTutorial(): void {
    this.tutorial = {
      icon:     'combine',
      title:    'combineDailyReports',
      subtitle: 'withThisFeatureCombine',
      steps: [
        { icon: 'circle-click', label: 'createDailyReports'   },
        { icon: 'table',        label: 'chooseWeekWorked'       },
        { icon: 'clock',        label: 'adjustWorkingHoursBreaks' },
        { icon: 'circle-tick',  label: 'submitStrong'           },
      ]
    };
  }

  private preparePhotoReportTutorial(): void {
    this.tutorial = {
      icon:     'camera',
      title:    'photoReport',
      subtitle: 'submitReportTakingPicture',
      steps: [
        { icon: 'circle-click', label: 'chooseYourAssignment' },
        { icon: 'table',        label: 'chooseWeekWorked'     },
        { icon: 'camera-plus',  label: 'takeClearPicture'     },
        { icon: 'circle-tick',  label: 'submitStrong'         },
      ]
    };
  }

  private preparePhotoDocumentsTutorial(): void {
    this.tutorial = {
      icon:     'upload',
      title:    'uploadPhotoDocument',
      subtitle: 'submityOurPersonalDocuments',
      steps: [
        { icon: 'circle-click', label: 'chooseTypeDocument' },
        { icon: 'camera-plus',  label: 'takeClearPicture'   },
        { icon: 'circle-tick',  label: 'submitStrong'       },
      ]
    };
  }

  private prepareTemplateTutorial(): void {
    this.tutorial = {
      icon:     'template',
      title:    'templateCreation',
      subtitle: 'createTemplatesFillQuicker',
      steps: [
        { icon: 'table',       label: 'chooseDayWorked'        },
        { icon: 'clock',       label: 'fillWorkingHoursBreaks' },
        { icon: 'circle-tick', label: 'saveAndReuse'           },
      ]
    };
  }

  private prepareVacationRequestTutorial(): void {
    this.tutorial = {
      icon:     'holiday',
      title:    'vacationRequest',
      subtitle: 'createVacationRequestHere',
      steps: [
        { icon: 'circle-click',   label: 'chooseVacationType'     },
        { icon: 'calendar-month', label: 'chooseDates'            },
        { icon: 'table',          label: 'assignmentAvailableTut' },
        { icon: 'circle-tick',    label: 'submit'                 },
      ]
    };
  }

  private prepareMileageMoneyTutorial(): void {
    this.tutorial = {
      icon:     'mileage',
      title:    'mileageMoney',
      subtitle: 'createMileageMoneyHere',
      steps: [
        { icon: 'circle-click', label: 'chooseYourAssignment' },
        { icon: 'table',        label: 'chooseWeekWorked'     },
        { icon: 'mileage',      label: 'fillOutMileage'       },
        { icon: 'circle-tick',  label: 'submit'               },
      ]
    };
  }

}
