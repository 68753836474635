<div class="du-flex-column du-flex-1 overflow-auto">
  <div class="b-radius b-grey relative ml-xl mr-xl mt-m" *ngIf="banner && banner.image">
    <img class="b-radius width-full" [src]="banner.image" (load)="notificationService.close()">
  </div>  
  <div class="du-flex-column gap-l p-m bb-grey">
    <div class="du-flex-column gap-xxs">
      <span class="font-l bold" translate="enterContactInformation"></span>
      <span class="font-s lh-l" translate="youWillReceiveBonus"></span>
    </div>
    <div class="du-flex-column gap-m" [formGroup]="friendForm">
      <div class="du-flex-column gap-xs">
        <span class="bold" translate="salutation"></span>
        <div class="select-wrapper">
          <select class="grey" formControlName="salutation">
            <option [value]="null" disabled selected>{{ 'selectSalutation' | translate }}</option>
            <option [value]="'mr'"      translate="mr"></option>
            <option [value]="'mrs'"     translate="mrs"></option>
            <option [value]="'diverse'" translate="diverse"></option>
          </select>
        </div>
      </div>

      <div class="du-flex-column gap-xs"><span class="bold" translate="firstName"   ></span><input type="text"  formControlName="firstName"    [class.b-red-i]="friendForm.controls['firstName'].touched    && !friendForm.controls['firstName'].value"></div>
      <div class="du-flex-column gap-xs"><span class="bold" translate="lastName"    ></span><input type="text"  formControlName="lastName"     [class.b-red-i]="friendForm.controls['lastName'].touched     && !friendForm.controls['lastName'].value"></div>
      <div class="du-flex-column gap-xs"><span class="bold" translate="mobileNumber"></span><input type="tel"   formControlName="mobileNumber" [class.b-red-i]="friendForm.controls['mobileNumber'].touched && !friendForm.controls['mobileNumber'].value"></div>
      <div class="du-flex-column gap-xs"><span class="bold" translate="email"       ></span><input type="email" formControlName="email"        [class.b-red-i]="friendForm.controls['email'].touched        && friendForm.controls['email'].invalid"></div>

      <div class="bg-light-orange b-radius du-flex-align-start p-s gap-xs">
        <span class="du-flex-center font-xl du-icon-warning color-orange"></span>
        <span class="lh-l font-s" translate="ensureContactIsHappy"></span>
      </div>
    </div>
  </div>

  <div class="du-flex-column gap-s p-m">
    <span class="bold" translate="requirement"></span>
    <span class="lh-l" translate="sendYourContactDetails"></span>
  </div>
</div>

<div class="m-m">
  <button class="width-full btn-primary" translate="submit" [disabled]="friendForm.invalid" (click)="referAFriend()"></button>
</div>


