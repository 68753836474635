import { Component, EventEmitter, Input, OnInit, Output    } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators   } from '@angular/forms';
import { ActivationService, NotificationService, SessionStorageService          } from '@shared/services';
import { PasswordMatchValidator, PasswordStrengthValidator } from '@shared/validators';
import { Observable } from 'rxjs';

@Component({
  selector:      'password-stage',
  templateUrl: './password-stage.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify bg-grey p-m' }
})
export class PasswordStageComponent implements OnInit {
  userForm: FormGroup;
  pass:     string;

  @Input() userId: string;
  @Input() code:   string;
  @Input() type:   string;
  @Output() callback = new EventEmitter<Observable<string>>();
  constructor(
    private formBuilder:           FormBuilder,
    private activationService:     ActivationService,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setProgressBar(3, 3);
    this.buildForm();
  }

  private buildForm(): void {
    this.userForm = this.formBuilder.group({
      password:         new FormControl(null, PasswordStrengthValidator()),
      confirm_password: new FormControl(null, [Validators.required, PasswordMatchValidator('password')]),
    });
  }

  callbackHandler(): void {
    if (!this.userForm.invalid) {
      this.notificationService.wait();
      this.callback.emit(this.activationService.setPassword(this.type, this.userId, this.code, this.userForm.get('password').value))
    };
  }
}
