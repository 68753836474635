import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class WeeksService {
  startOfCurrentWeek: Date   = this.getStartOfWeek();
  weeksSize:          number = 7*24*60*60*1000;
  constructor () { }

  getStartOfWeek (date: Date | number = new Date): Date {
    date = new Date(date);
    date.setHours(0,0,0,0);
    let day = (date.getDay() + 6)%7;
    return new Date(Number(date) - day * 24*60*60*1000);
  }

  getEndOfWeek (date: Date | number = new Date): Date {
    let start = this.getStartOfWeek(date);
    return new Date(+start + this.getWeeksSize(start) - 1);
  }

  getWeeksRange(range: number): any[] {
    let weeks = [];
    let start = new Date(this.startOfCurrentWeek);
    let end   = new Date(+this.startOfCurrentWeek + this.weeksSize);
    for (let i = 0; i < range; i++) {
      weeks.push({
        weekStart: new Date(+start + i * this.weeksSize),
        weekEnd:   new Date(+end   + i * this.weeksSize)
      });
    }
    return weeks;
  }

  getWeeksRangeReverse(range: number): any[] {
    let weeks = [];
    let start = new Date(this.startOfCurrentWeek);
    let end   = new Date(+this.startOfCurrentWeek + this.weeksSize - 1000);
    for (let i = 0; i < range; i++) {
      weeks.push({
        weekStart: new Date(+start - i * this.weeksSize),
        weekEnd:   new Date(+end   - i * this.weeksSize)
      });
    }
    return weeks;
  }

  private getWeeksSize(date: Date | number, nWeeks = 1): number {
    let d = new Date(date);
    d.setDate(d.getDate() + nWeeks * 7);
    return Number(d) - Number(date);
  }

  getWeekStartByWeekNumber(weekNum: number): Date {
    weekNum = isNaN(weekNum) || weekNum > 53 || weekNum < 1 ? 1 : weekNum;
    let year = new Date().getFullYear();
    let weekStart = this.getWeekStartByWeekYear(weekNum, year);
    if (weekStart > new Date()) weekStart = this.getWeekStartByWeekYear(weekNum, year - 1);
    return weekStart;
  }

  private getWeekStartByWeekYear(weekNum: number, year: number): Date  {
    let weekStart = new Date(year, 0, 1 + (weekNum - 1) * 7);
    let dow = weekStart.getDay();
    if (dow <= 4) weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);
    else weekStart.setDate(weekStart.getDate() + 8 - weekStart.getDay());
    return weekStart;
  }

  getWeekNumber(date: Date): number {
    let currentDate = new Date(date.getTime());
    let dayOfWeek   = (currentDate.getDay() + 6) % 7 + 1;
    currentDate.setDate(currentDate.getDate() + (4 - dayOfWeek));

    let yearStart    = new Date(currentDate.getFullYear(), 0, 4);
    let isoYearStart = new Date(yearStart);
    isoYearStart.setDate(yearStart.getDate() - ((yearStart.getDay() + 6) % 7));

    if (currentDate.getTime() < isoYearStart.getTime()) {
      let prevYearStart    = new Date(currentDate.getFullYear() - 1, 0, 4);
      let prevIsoYearStart = new Date(prevYearStart);
      prevIsoYearStart.setDate(prevYearStart.getDate() - ((prevYearStart.getDay() + 6) % 7));
      return Math.ceil((((currentDate.getTime() - prevIsoYearStart.getTime()) / 86400000) + 1) / 7);
    }

    let nextYearStart    = new Date(currentDate.getFullYear() + 1, 0, 4);
    let nextIsoYearStart = new Date(nextYearStart);
    nextIsoYearStart.setDate(nextYearStart.getDate() - ((nextYearStart.getDay() + 6) % 7));
    if (currentDate >= nextIsoYearStart) return 1;  
    return Math.ceil((((currentDate.getTime() - isoYearStart.getTime()) / 86400000) + 1) / 7);
  }

}
