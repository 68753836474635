import { Component, OnInit      } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take        } from 'rxjs';

import { collapse } from '@shared/animations';
import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';
import { NotificationService, SessionStorageService } from '@shared/services';

@Component({
  templateUrl: './report-details.component.html',
  host: { class: 'height-full du-flex-column overflow-auto' },
  animations: [ collapse ]
})
export class ReportDetailsComponent implements OnInit {
  cachedReports:     ActivityReportOverview[] = [];
  report:            ActivityReportOverview | ActivityReportLocal;

  reportIndex:       number = 0;
  collapseSignature: boolean;
  collapseNote:      boolean;
  useCase:           string;
  constructor(
    private route:                 ActivatedRoute,
    private router:                Router,
    private notificationService:   NotificationService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit(): void {
    this.useCase = this.route.snapshot.data['useCase'];
    this.sessionStorageService.setHeaderTitle('activityReport');
    this.sessionStorageService.setHeaderControls({
      leftGoBack: this.goBack.bind(this),
      right: this.report && this.reportOverview.customerSignature ? [{ icon: 'circle-tick font-icon', label: 'signed', class: 'du-flex-align gap-xxs pl-xs pr-xs pt-xxs pb-xxs font-s font-400 bg-green-light b-radius' }] : null
    });

    if      (this.useCase === 'ar')   this.prepareOverviewReport();
    else if (this.useCase === 'ar-c') this.prepareLocalReport();
  }

  private prepareOverviewReport(): void {
    this.notificationService.wait();
    this.sessionStorageService.setProgressBar(null);
    this.sessionStorageService.cachedEntries.pipe(
      take(1),
      switchMap(reports => {
        this.cachedReports = reports || [];
        return this.route.params;
      })
    ).subscribe(
      params => {
        this.renderReport(this.cachedReports.findIndex(r => r.id === +params['id']));
        this.notificationService.close();
      },
      err => this.notificationService.alert(err)
    );
  }

  private prepareLocalReport(): void {
    this.sessionStorageService.setProgressBar(4, 5);
    let report = this.sessionStorageService.temporaryActivityReport;

    if (this.useCase === 'ar-c' && !report || !report.assignment || !report.startDate || !report.dailyReports?.length) this.router.navigateByUrl('/time-tracking/home');
    else {
      this.report = new ActivityReportLocal(report.toJSON());
      this.report.dailyReports = this.reportLocal.activeDailyReports;
    }
  }

  renderReport(reportIndex: number): void {
    if (reportIndex !== -1) {
      this.reportIndex = reportIndex;
      this.report      = this.cachedReports[reportIndex];
    } else this.goBack();
  }

  showEmailDetails(): void {
    this.sessionStorageService.setChip({ message: this.reportOverview.confirmationEmails.join('\n') });
  }

  goPrevReport(): void {
    this.renderReport(this.reportIndex-1);
  }

  goNextReport(): void {
    this.renderReport(this.reportIndex+1);
  }

  goNext(): void {
    this.router.navigateByUrl(`time-tracking/status-select-ar`);
  }

  goBack(): void {
    if (this.useCase === 'ar-c') this.router.navigateByUrl('/time-tracking/constructor-ar');
    else                         this.router.navigateByUrl('/time-tracking/reports-overview');
  }

  get reportLocal():    ActivityReportLocal    { return this.report as ActivityReportLocal;    }
  get reportOverview(): ActivityReportOverview { return this.report as ActivityReportOverview; }

}
