import { Component } from '@angular/core';
import { Router    } from '@angular/router';

import { SessionStorageService, UserService } from '@shared/services';
import { VacationRequestLocal               } from '@shared/factories';
import { collapse                           } from '@shared/animations';

@Component({
  selector:      'vacation-type-select',
  templateUrl: './vacation-type-select.component.html',
  host: { class: 'du-flex-column bg-grey' },
  animations: [ collapse ]

})
export class VacationTypeSelectComponent {
  readonly vacationTypes:   string[] = ['annual', 'special', 'overtime', 'unpaid'];
  readonly vacationReasons: string[] = ['wedding_birth', 'death_of_close_relatives', 'death_of_relatives'];
 
  leaveType: string;
  reason:    string;
  closeHint: boolean;
  constructor(
    private router:                Router,
    private sessionStorageService: SessionStorageService,
    public  userService:           UserService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setHeaderTitle('vacationRequest');
    this.sessionStorageService.setProgressBar(1, 4);

    if (!this.userService.checkTutorial('vr')) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 'vr' }
    });

    let vacation = this.sessionStorageService.temporaryVacationRequest;
    if (vacation) {
      this.leaveType = vacation.leaveType;
      this.reason    = vacation.reason;
    }
  }

  selectLeaveType(leaveType: string): void {
    this.leaveType = leaveType;
    this.reason    = null;
  }

  selectReason(reason: string): void {
    this.reason    = reason;
    this.closeHint = false;
  }

  confirmVacation(): void {
    let vacation = this.sessionStorageService.temporaryVacationRequest;
    let data = {
      leave_type: this.leaveType,
      reason:     this.reason
    };

    if      (!vacation || !vacation.leaveType)      vacation = new VacationRequestLocal(data);
    else if (vacation.leaveType !== this.leaveType) vacation = new VacationRequestLocal(Object.assign(vacation.toJSON(), data));

    this.sessionStorageService.setTemporaryValue(vacation);
    this.goNext();
  }

  readyToSubmit(): boolean {
    if (!this.leaveType) return false;
    if (this.leaveType === 'special' && !this.reason) return false;
    if (this.leaveType === 'unpaid'  && !this.reason) return false;
    return true;
  }

  private goNext(): void {
    this.router.navigateByUrl('/time-tracking/vacation-date-select');
  }

  goBack(): void {
    let vacation = this.sessionStorageService.temporaryVacationRequest;
    if (vacation?.id) this.router.navigateByUrl('/time-tracking/preselect-vr');
    else              this.router.navigateByUrl('/time-tracking/home');
  }

}
