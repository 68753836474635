import { Component, Input } from '@angular/core';
import { ActivityReportLocal, ActivityReportOverview } from '@shared/factories';

@Component({
  selector:      'reports-overview-item',
  templateUrl: './reports-overview-item.component.html',
  host: { class: 'height-min-fit-content bg-white shadow-header overflow b-radius' }
})
export class ReportsOverviewItemComponent {
  @Input() report: ActivityReportOverview | ActivityReportLocal;

  getLocalReport(): ActivityReportLocal {
    return this.report as ActivityReportLocal;
  }

  getOverviewReport(): ActivityReportOverview {
    return this.report as ActivityReportOverview;
  }

  getStateColor(): string {
    if (this.getOverviewReport().approvedState) return 'green';
    if (this.getOverviewReport().awaitingState) return 'orange';
    if (this.getOverviewReport().rejectedState) return 'red';
    return 'blue';
  }

  getBottomIcons(): string[] {
    let icons: string[] = [];
    if (this.getOverviewReport().confirmationEmails)                                          icons.push('envelope');
    if (this.getOverviewReport().reportCorrected && !this.getOverviewReport().attachmentPath) icons.push('edited');
    if (this.getOverviewReport().haveHolidays)                                                icons.push('holiday-sun');
    if (this.getOverviewReport().attachmentPath)                                              icons.push('camera');
    if (this.getOverviewReport().mileageMoneyReportId)                                        icons.push('mileage');
    if (this.getOverviewReport().splitParentId || this.getOverviewReport().splitChildId)      icons.push('split');
    return icons;
  }

}
