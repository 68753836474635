<div class="du-flex-column gap-xl color-white">
  <span class="font-xl color-white du-icon-arrow-big-left" [routerLink]="['/auth/start-screen']"></span>
  <h1 class="font-xl bold mt-80" translate="login"></h1>
  <div class="du-flex-column gap-l" [formGroup]="loginForm">
    <div class="du-flex-column gap-xs">
      <label for="email" translate="userId"></label>
      <input  id="email" type="email" formControlName="email">
    </div>

    <div class="du-flex-column gap-xs">
      <label for="password" translate="password"></label>
      <input  id="password" type="password" formControlName="password">
    </div>

    <span class="font-s underline mla" translate="forgotPassword" [routerLink]="['/auth/reset']"></span>
  </div>
</div>

<button class="btn-primary" [routerLink]="['/auth/login']" translate="login" (click)="login()"></button>
