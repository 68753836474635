import { Component, OnInit } from '@angular/core';
import { Router            } from '@angular/router';

import { EBS      } from '@shared/factories';
import { EBSModel } from '@shared/models';
import { DBService, SessionStorageService, NotificationService } from '@shared/services';

@Component({
  selector:      'ebs-overview',
  templateUrl: './ebs-overview.component.html',
  host: { class: 'height-full du-flex-column bg-grey' },
})
export class EbsOverviewComponent implements OnInit {
  ebsList:         EBS[] = [];
  archiveEbsList:  EBS[] = [];
  collapseArchive: boolean;
  constructor(
    private router:                Router,
    private dbService:             DBService,
    private sessionStorageService: SessionStorageService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.notificationService.wait();
    this.sessionStorageService.setHeaderTitle('ebs');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setProgressBar(null);
    this.dbService.loadMultipleFromDB('ebs')
    .then((ebsList: EBSModel[]) => this.mapEbs(ebsList.map(e => new EBS(e))))
    .then(() => this.notificationService.close())
    .catch(err => this.notificationService.alert(err));
  }

  private mapEbs(ebs: EBS[]): void {
    ebs.forEach(e => {
      if (e.archivedAt || e.assignmentEndsAt < new Date()) this.archiveEbsList.push(e);
      else this.ebsList.push(e);
    });
    this.ebsList        = this.ebsList.sort(       (a, b) => !a.confirmedAt ? -1 : !b.confirmedAt ? 0 : b.confirmedAt.getTime() - a.confirmedAt.getTime() );
    this.archiveEbsList = this.archiveEbsList.sort((a, b) => !a.archivedAt  ? -1 : !b.archivedAt  ? 0 : a.archivedAt.getTime()  - b.archivedAt.getTime()  );
  }

  private goBack(): void {
    this.router.navigateByUrl('time-tracking/home');
  }

}
