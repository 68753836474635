import { UserAddressModel, UserAvatarModel, UserLocationModel, UserModel, UserPushNotificationsTargetsModel, UserVacationInfoModel } from "@shared/models";

export class User {
  id:              number;
  email:           string;
  firstName:       string;
  lastName:        string;

  personalNumber:  number;
  phoneNumber:     string;
  avatar:          UserAvatarModel;
  avatarThumb:     string;

  signature:       string;
  acceptedPrivacy: boolean;

  location:        UserLocationModel;
  locationPhone:   string;

  address:         UserAddressModel;
  mileageConfig:   any;

  pnTargets:       UserPushNotificationsTargetsModel;
  vacationInfo:    UserVacationInfoModel;
  constructor(data: UserModel) {
    this.id              = data.id;
    this.email           = data.email;
    this.firstName       = data.first_name;
    this.lastName        = data.last_name;

    this.personalNumber  = data.personal_number;
    this.phoneNumber     = data.phone_number;
    this.avatar          = data.avatar;
    this.avatarThumb     = data.avatarThumb;

    this.signature       = data.signature;
    this.acceptedPrivacy = data.accepted_privacy_policy;

    this.location        = data.location ? data.location : null;
    this.locationPhone   = data.location && data.location.phone || data.locationPhone;
    this.address         = data.address  ? data.address  : null;

    this.mileageConfig   = data.license_plate ? {
      licensePlate:  data.license_plate.license_plate,
      country:       data.license_plate.country,
      signaturePath: data.license_plate.signature_path
    } : null;

    this.pnTargets    = data.push_notifications_targets;
    this.vacationInfo = data.vacation_info;
  }

  toJSON(): UserModel {
    return {
      id:                      this.id              ? this.id              : null,
      email:                   this.email           ? this.email           : null,
      first_name:              this.firstName       ? this.firstName       : null,
      last_name:               this.lastName        ? this.lastName        : null,

      personal_number:         this.personalNumber  ? this.personalNumber  : null,
      phone_number:            this.phoneNumber     ? this.phoneNumber     : null,
      avatar:                  this.avatar          ? this.avatar          : null,
      avatarThumb:             this.avatarThumb     ? this.avatarThumb     : null,

      signature:               this.signature       ? this.signature       : null,
      accepted_privacy_policy: this.acceptedPrivacy ? this.acceptedPrivacy : null,

      locationPhone:           this.locationPhone   ? this.locationPhone   : null,
      address:                 this.address         ? this.address         : null,

      license_plate: this.mileageConfig ? {
        license_plate:  this.mileageConfig.licensePlate,
        country:        this.mileageConfig.country,
        signature_path: this.mileageConfig.signaturePath,
      } : null,

      push_notifications_targets: this.pnTargets    ? this.pnTargets       : null,
      vacation_info:              this.vacationInfo ? this.vacationInfo    : null,
    };
  }

}
