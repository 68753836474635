import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { ActivityReportLocal, DailyLocal, MileageReportLocal, MileageWorkDayLocal } from '@shared/factories';
import { WeeksService } from '@shared/services';
import { fadeIn       } from '@shared/animations';

@Component({
  selector:      'day-selector',
  templateUrl: './day-selector.component.html',
  host: { class: 'du-flex-justify bg-white b-radius relative' },
  animations: [ fadeIn ]
})
export class DaySelectorComponent implements OnChanges {
  @Input() entry:            ActivityReportLocal | MileageReportLocal;
  @Input() activeEntryIndex: number;
  @Input() useCase:          string;

  @Output() daySelectCallback = new EventEmitter<number>;

  week:       Date[] = [];
  nodeWidth:  number = 0;
  nodeHeight: number = 0;
  constructor(
    private weeksService: WeeksService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.onChangeHandler(changes, 'entry')            && !this.week.length) this.prepareWeekDates();
    if (this.onChangeHandler(changes, 'activeEntryIndex') && !this.nodeWidth)   this.prepareButtonsSize();
  }

  private onChangeHandler(changes: SimpleChanges, field: string): boolean {
    return changes[field]?.firstChange !== null;
  }

  private prepareButtonsSize(): void {
    let activeDay  = document.getElementById('active-day');
    this.nodeWidth = activeDay.parentElement.clientWidth/7;
  }

  private prepareWeekDates(): void {
    this.week     = [];
    let weekStart = this.weeksService.getStartOfWeek(this.getEntryDate(this.entries[0]));
    for (let i = 0; i < 7; i++) {
      let date = new Date(weekStart);
      date.setDate(weekStart.getDate() + i);
      this.week.push(date);
    }
  }

  get entries(): (DailyLocal | MileageWorkDayLocal)[] {
    return (this.entry as ActivityReportLocal).dailyReports || (this.entry as MileageReportLocal).workDays || [];
  }

  getEntryDate(entry: DailyLocal | MileageWorkDayLocal): Date {
    return (entry as DailyLocal).startsAt || (entry as MileageWorkDayLocal).date;
  }

  dayIsDisabled(entry: DailyLocal | MileageWorkDayLocal): boolean {
    let day = new Date(this.getEntryDate(entry));
    day.setHours(0,0,0);
    return day < this.entry.startDate || day > this.entry.endDate || day > new Date();
  }

  getIcon(entry: DailyLocal | MileageWorkDayLocal): string {
    if (entry?.dateErrors?.size)     return 'du-icon-close';
    if (entry?.techErrorsAll?.size)  return 'du-icon-close';
    if (entry?.legalErrorsAll?.size) return 'du-icon-i';
    return this.useCase === 't' && entry.placeholder ? 'du-icon-plus' : 'du-icon-tick';
  }

  getStateColor(entry: DailyLocal | MileageWorkDayLocal): string {
    if (!entry || entry.placeholder) return 'blue';
    if ( entry.dateErrors.size)      return 'red';
    if ( entry.techErrorsAll.size)   return 'red';
    if ( entry.legalErrorsAll.size)  return 'orange';
    return 'green';
  }

  getTextColor(day: Date, index: number = null): string {
    return index !== null && this.activeEntryIndex === index ? 'white' : this.getStateColor(this.getDay(day));
  }

  getActiveStateColor(): string {
    let day = this.entries[this.activeEntryIndex];
    if (!day || day.placeholder)  return 'blue';
    if ( day.dateErrors.size)     return 'red';
    if ( day.techErrorsAll.size)  return 'red';
    if ( day.legalErrorsAll.size) return 'orange';
    return 'green';
  }

  getIconStyles(day: Date, index: number = null): string {
    return `${this.getIcon(this.getDay(day))} bg-${this.getTextColor(day, index)} color-${this.activeEntryIndex === index ? this.getStateColor(this.getDay(day)) : 'white'}`;
  }

  isPlaceholder(day: Date): boolean {
    return this.getDay(day) ? this.getDay(day).placeholder : true;
  }

  getDay(day: Date): DailyLocal | MileageWorkDayLocal {
    return this.entries.find(e => this.getEntryDate(e).getDate() === day.getDate());
  }

}
