<button class="du-flex-align gap-xs mt-xl p-s btn-round" [ngClass]="'btn-' + color + '-thin'" (click)="langOpen = !langOpen">
  <span class="du-flex-center font-xl du-icon-globe"></span>
  <span translate="langShort"></span>
  <span class="du-flex-center font-xl du-icon-arrow-drop-down animate-rotate" [class.rotate]="langOpen"></span>
</button>

<ul *ngIf="langOpen" class="du-flex-column width-170 absolute absolute-left-center bg-white color-black z-1 mt-m pt-xs pb-xs b-radius-30">
  <li class="du-flex-align gap-s pl-m pr-m pt-s pb-s hover-active" *ngFor="let lang of langList" (click)="changeLang(lang.code)">
    <img class="du-flex width-24" [src]="lang.flag">
    <span>{{ lang.label }}</span>
  </li>
</ul>