import { Component, OnInit } from '@angular/core';
import { Router   } from '@angular/router';
import { Location } from '@angular/common';

import { DailyLocalExtendedModel } from '@shared/models';
import { ActivityReportLocal, Assignment, DailyLocalExtended } from '@shared/factories';
import { SessionStorageService, DBService, NotificationService, WeeksService, ValidationService, UserService } from '@shared/services';
import { collapse } from '@shared/animations';

interface DailyGroupByAssignment {
  assignment:  Assignment;
  dailiesByWeek: DailyGroupByWeek[];
}

interface DailyGroupByWeek {
  weekStart:  Date;
  weekEnd:    Date;
  dailies:    DailyLocalExtendedCollapsable[];

  collapsed?: boolean;
}

interface DailyLocalExtendedCollapsable extends DailyLocalExtended {
  collapsed?: boolean;
}

@Component({
  selector:      'dailies-overview',
  templateUrl: './dailies-overview.component.html',
  host: { class: 'height-full du-flex-column du-flex-justify p-xl gap-l overflow-auto' },
  animations: [collapse]
})
export class DailiesOverviewComponent implements OnInit {
  dailies:     DailyLocalExtended[]     = [];
  dailyGroups: DailyGroupByAssignment[] = [];
  constructor(
    private location:              Location,
    private router:                Router,
    private dbService:             DBService,
    private weeksService:          WeeksService,
    private validationService:     ValidationService,
    private sessionStorageService: SessionStorageService,
    private userService:           UserService,
    private notificationService:   NotificationService
  ) {}

  ngOnInit(): void {
    this.sessionStorageService.setHeaderTitle('combineDailyReports');
    this.sessionStorageService.setHeaderControls({ leftGoBack: this.goBack.bind(this) });
    this.sessionStorageService.setProgressBar(null);
    this.reloadDailies();
    if (!this.userService.checkTutorial('do')) this.sessionStorageService.pushDynamicComponent({
      component: 'TutorialScreen',
      props: { useCase: 'do' }
    });
  }

  private reloadDailies(): void {
    this.notificationService.wait();
    this.dbService.loadMultipleFromDB('localDailies')
    .then((dailies: DailyLocalExtendedModel[]) => {
      this.dailies     = dailies.map(d => new DailyLocalExtended(d));
      this.dailies.forEach(d => this.validationService.validateDaily(d, this.dailies));
      this.dailyGroups = this.prepareDailyGroups();
      this.notificationService.close();
    });
  }

  private prepareDailyGroups(): DailyGroupByAssignment[] {
    let temp: any = {};
    this.dailies.forEach(d => {
      if (!temp[d.assignment.id]) temp[d.assignment.id] = {};
      if (!temp[d.assignment.id][this.weeksService.getWeekNumber(d.startsAt)]) temp[d.assignment.id][this.weeksService.getWeekNumber(d.startsAt)] = [];
      temp[d.assignment.id][this.weeksService.getWeekNumber(d.startsAt)].push(d);
    });

    let dailyGroups = [];
    for (const assignmentId in temp) {
      let weeks:      DailyGroupByWeek[] = [];
      let assignment: Assignment         = null;

      for (const weekNum in temp[assignmentId]) {
        assignment = temp[assignmentId][weekNum][0].assignment;

        let weekStart: Date = new Date(Math.max(this.weeksService.getWeekStartByWeekNumber(+weekNum).getTime(), assignment.startsAt.getTime()));
        let weekEnd:   Date = new Date(Math.min(this.weeksService.getEndOfWeek(weekStart).getTime(),            assignment.endsAt.getTime()));

        weeks.push({
          weekStart, weekEnd,
          dailies: (Object.values(temp[assignmentId][weekNum]) as DailyLocalExtended[]).sort((a, b) => a.startsAt.getTime() - b.startsAt.getTime())
        });
      }

      if (weeks.length) {
        dailyGroups.push({
          assignment,
          dailiesByWeek: weeks.sort((a, b) => a.weekStart.getTime() - b.weekStart.getTime())
        });
        dailyGroups.sort((a, b) => b.assignment.createdAt.getTime() - a.assignment.createdAt.getTime());
      }
    }

    return dailyGroups;
  }

  deleteDaily(dailyId: number): void {
    this.notificationService.wait();
    this.dbService.deleteOneFromDB('localDailies', dailyId)
    .then(() => {
      this.dailies = this.dailies.filter(d => d.id !== dailyId);
      this.updateView();
      this.notificationService.close();
    });
  }

  createReport(assignmentGroup: DailyGroupByAssignment, weekGroup: DailyGroupByWeek): void {
    let mileage = this.getMileageRange(assignmentGroup, weekGroup);
    this.sessionStorageService.setTemporaryValue(new ActivityReportLocal({
      start_date:   weekGroup.weekStart.toISOString(),
      end_date:     weekGroup.weekEnd.toISOString(),
      assignment:   assignmentGroup.assignment.toJSON(),
      dailyReports: weekGroup.dailies.map(d => d.toJSON()),
      mileageStart: mileage?.start,
      mileageEnd:   mileage?.end
    }));
    this.router.navigate(['time-tracking/constructor-dr']);
  }

  private getMileageRange(assignmentGroup: DailyGroupByAssignment, weekGroup: DailyGroupByWeek): any {
    let mileage = this.checkMileage(assignmentGroup, weekGroup);
    if (mileage) return {
      start: new Date(Math.max(mileage.startsAt.getTime(), weekGroup.weekStart.getTime())),
      end:   new Date(Math.min(mileage.endsAt.getTime(),   weekGroup.weekEnd.getTime()))
    };
    return null;
  }

  private checkMileage(assignmentGroup: DailyGroupByAssignment, weekGroup: DailyGroupByWeek): any {
    return assignmentGroup.assignment.mileageData?.find(md =>
      md.startsAt.getTime() <= weekGroup.weekStart.getTime() && md.endsAt.getTime() >= weekGroup.weekEnd.getTime() ||
      md.startsAt.getTime() >= weekGroup.weekStart.getTime() && md.endsAt.getTime() <= weekGroup.weekEnd.getTime() ||
      md.startsAt.getTime() >= weekGroup.weekStart.getTime() && md.endsAt.getTime() <= weekGroup.weekStart.getTime()
    );
  }

  goBack(): void {
    this.location.back();
  }

  openDailyConstructor(assignmentGroup: DailyGroupByAssignment = null): void {
    this.sessionStorageService.pushDynamicComponent({
      component: 'DailyReport',
      props: {
        daily: assignmentGroup ? this.prepareDefaultDaily(assignmentGroup) : null,
        callback: (daily: DailyLocalExtended) => {
          this.dailies = [...this.dailies, daily];
          this.updateView();
        }
      }
    });
  }

  private updateView() {
    let updatedDailyGroups = this.prepareDailyGroups();
    updatedDailyGroups.forEach(assignmentGroupNew => {
      let assignmentGroupOld = this.dailyGroups.find(dg => dg.assignment.id === assignmentGroupNew.assignment.id);
      if (assignmentGroupOld) {
        assignmentGroupNew.dailiesByWeek.forEach(weekGroupNew => {
          let weekGroupOld = assignmentGroupOld.dailiesByWeek.find(week => week.weekStart.getTime() === weekGroupNew.weekStart.getTime());
          if (weekGroupOld) {
            weekGroupNew.collapsed = weekGroupOld.collapsed;
            weekGroupNew.dailies.forEach(dailyNew => {
            let dailyOld = weekGroupOld.dailies.find(daily => daily.id === dailyNew.id);
              if (dailyOld) dailyNew.collapsed = dailyOld.collapsed;
            });
          }
        });
      }
    });
    this.dailyGroups = updatedDailyGroups;
  }

  private prepareDefaultDaily(assignmentGroup: DailyGroupByAssignment): DailyLocalExtended {
    let day = new Date();
    day.setHours(0,0,0,0);
    return new DailyLocalExtended({
      assignment: assignmentGroup.assignment.toJSON(),
      started_at: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 8,  0, 0),
      ended_at:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 16, 0, 0),
      pauses: [{
        id: 1,
        start: new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12,  0, 0),
        end:   new Date(day.getFullYear(), day.getMonth(), day.getDate(), 12, 30, 0),
      }]
    });
  }

  trackById(index: number, el:DailyLocalExtended): number {
    return el.id;
  }

  trackByAssignmentId(index: number, el: DailyGroupByAssignment): number {
    return el.assignment.id;
  }

  trackByWeekStart(index: number, el: DailyGroupByWeek): number {
    return el.weekStart.getDate();
  }

}
